import React, { Component } from "react";
import { Link } from "react-router-dom";
import t from "../../../helpers/t9n";
import { dateDiff, dateExpired } from "../../../helpers/utils";
import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import CounterSpawner from "../../element/counter-spawner/CounterSpawner";
import ProgressCircle from "../../element/progress-circle/ProgressCircle";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";
import { AppContext } from "../../../data/ContextProvider";

export class SalesMarathonRanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startNr: 0,
      reachedGoal: false,
      headerSlider: [],
    };
    this.timerHandle = null;
    this.loaderHandle = null;

    this.loaderIndex = 0;
    this.endingNumber = 0;
  }

  componentDidMount() {
    let loadingBarValues = [];

    if (this.percent) {
      if (Math.ceil(Math.log10(this.props.transactions.percent + 1)) >= 3) {

        //3 digits = over 100
        const hundrets = this.props.transactions.percent / 100;
        for (let i = 1; i <= hundrets; i++) {
          loadingBarValues.push(100);
        }
        loadingBarValues.push(
          Math.floor(
            this.props.transactions.percent - Math.floor(hundrets) * 100
          )
        );

        this.loadUp(loadingBarValues, 0);
      } else {
        this.loadUp([this.props.transactions.percent], 0);
      }
    }
  }

  loadUp = (values, count = 0) => {
    this.loader.style.width = count + "%";

    if (this.loaderIndex > 0) {
      this.percent.innerHTML = count + this.loaderIndex * 100 + "%";
    } else {
      this.percent.innerHTML = count + "%";
    }

    this.endingNumber = values[this.loaderIndex];

    if (count < this.endingNumber) {
      this.loaderHandle = setTimeout(() => this.loadUp(values, count + 1), 20);
    } else {
      if (typeof values[this.loaderIndex + 1] !== "undefined") {
        this.loader.style.width = 0 + "%";
        this.setState({
          reachedGoal: true,
        });
        this.loaderIndex += 1;
        this.loadUp(values, 0);
      }
    }
  };

  componentWillUnmount() {
    this.timerHandle && clearTimeout(this.timerHandle);
    this.loaderHandle && clearTimeout(this.loaderHandle);
  }

  countUp = (count) => {
    this.percent.innerHTML = count + "%";

    if (count < this.props.transactions.percent) {
      this.timerHandle = setTimeout(() => this.countUp(count + 1), 10);
    }
  };

  render() {

    const { currencyMode } = this.props;
    const projectCurrency = this.context.project.project_currency

    return (
      <>
        <div className="salespush-performance__section">
          <div className="salespush-performance__content">
            <div className="salespush-performance__box salespush-performance__box--percent">
              <h3>{t("app.your.target")}</h3>
              <div className={"salespush-performance__box-container"}>
                <div>
                  <div className={`salespush-performance__loader ${this.state.reachedGoal ? "salespush-performance__loader--second" : ""}`}>
                    <span ref={(loader) => (this.loader = loader)} className="salespush-performance__loader-indicator" />
                  </div>
                  <span className="salespush-performance__box-percent" ref={(percent) => (this.percent = percent)}>0%</span>
                  {this.props.transactions.percent >= 100 && (
                    <span className="neukunden-performance__congrats">
                      <UncontrolledLottie animationData={confetti} />
                    </span>
                  )}
                </div>
                {this.props.transactions.percent >= 100 ? (
                  <p>
                    <b>{t("app.your.target.reached")}</b>
                  </p>
                ) : (
                  <p>
                    <b>
                      {t("app.salesmarathon.lets.go")}
                    </b>
                  </p>
                )}
              </div>
            </div>

            <div className="salespush-performance__box">
              <h3>{t("app.goal")}</h3>
              <div className="salespush-performance__box-container">
                <span className="salespush-performance__box-wrapper">
                  <CounterSpawner
                    amount={this.props.transactions.target ? this.props.transactions.target : 0}
                    type="standard"
                    digits={currencyMode === "contracts" ? 4 : 7}
                  />
                  <span className="salespush-performance__box-type">
                    <p>
                      <b>{t("app.goals.target")}</b>
                      <br />
                      {currencyMode === "contracts"
                        ? t("app.contracts.number.previous")
                        : t("app.sales.previous")}
                    </p>
                  </span>
                </span>
                <span className="salespush-performance__box-wrapper">
                  <CounterSpawner
                    amount={Number(this.props.transactions.transactions)}
                    type="highlight"
                    digits={currencyMode === "contracts" ? 4 : 7}
                  />
                  <span className="salespush-performance__box-type">
                    <p>
                      <b>{t("app.you.achieved")}</b>
                      <br />
                      {currencyMode === "contracts"
                        ? t("app.contracts.number")
                        : t("app.sales")}
                    </p>
                  </span>
                </span>
              </div>
            </div>

            <div className="salespush-performance__box salespush-performance__box--timer salespush-performance__box--timer-full">
              <h3>{t("app.remaining.time")}</h3>
              <span>
                <ProgressCircle
                  value={dateDiff(this.props.end).days}
                  maxValue={dateDiff(this.props.end, new Date(this.props.start)).days}
                  isCountDown={true}
                  text={t("app.days")}
                  expired={dateExpired(this.props.end)}
                />
                <ProgressCircle
                  value={dateDiff(this.props.end).hours}
                  maxValue={24}
                  text={t("app.hours")}
                  expired={dateExpired(this.props.end)}
                />
                <ProgressCircle
                  value={dateDiff(this.props.end).minutes}
                  maxValue={60}
                  text={t("app.mins")}
                  expired={dateExpired(this.props.end)}
                />
              </span>
            </div>

            <div className="salespush-performance__box salespush-performance__box--list salespush-performance__box--full">
              <div className="salespush-performance__box-container salespush-performance__box-container--list salespush-performance__box-container--list">
                <span className="salespush-performance__box-header">
                  
                  <div>
                    <span>
                      <Icon icon="star" />
                    </span>
                    <h3>{t("app.salesmarathon.goals")}</h3>
                  </div>

                  <div>
                    <span>
                      <Icon icon="star" />
                    </span>
                    <h3>{t("app.salesmarathon.profit")}</h3>
                  </div>

                </span>
                <div className="salespush-performance__wrapper">
                    <div className="salespush-performance__item">
                      <span>≤ 100%</span>
                      <span>0,25% {t("app.salesmarathon.currency1")} {projectCurrency === "eur" ? " € " : " $ "} {t("app.salesmarathon.currency2")}</span>
                    </div>
                    <div className="salespush-performance__item">
                      <span>100% - 105%</span>
                      <span>5% {t("app.salesmarathon.currency1")} {projectCurrency === "eur" ? " € " : " $ "} {t("app.salesmarathon.currency2")}</span>
                    </div>
                    <div className="salespush-performance__item">
                      <span>105,01% - 115%</span>
                      <span>7,5% {t("app.salesmarathon.currency1")} {projectCurrency === "eur" ? " € " : " $ "} {t("app.salesmarathon.currency2")}</span>
                    </div>
                    <div className="salespush-performance__item">
                      <span>≥ 115,01%</span>
                      <span>10% {t("app.salesmarathon.currency1")} {projectCurrency === "eur" ? " € " : " $ "} {t("app.salesmarathon.currency2")}</span>
                    </div>
                </div>
              </div>
            </div>

            <span className="salespush-performance__actions">
              <Link to={`/${window.currentLang}/account`}>
                <Button
                  type="secondary"
                  text={t("global.points.account")}
                  textColor="white"
                />
              </Link>
            </span>
          </div>
        </div>
      </>
    );
  }
}

SalesMarathonRanking.contextType = AppContext;
export default SalesMarathonRanking;
