import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";
import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import ProgressCircle from "../../element/progress-circle/ProgressCircle";
import clap from "../../../assets/lotties/schaffe-ich/anim--schaffe-ich-clap.json";

import { bodyForLang } from "../../../lib/lib";

export class SchaffeIch extends Component {
  render() {
    const texts = this.props.text

    let subline = ""
    let body = ""
    let headline = ""

    texts.forEach(text => {
      if (text.key === 'p33_header_content_headline') {
        headline = text
      }
      if (text.key === 'p33_header_content_subline') {
        subline = text
      }
      if (text.key === 'p33_upper_content_text') {
        body = text
      }
    })

    if (!this.props.dateEndDiff) {
      return t("global.loading")
    }

    return (
      <>
        <div className="app-header__section schaffe-ich-header__section app-header__section--open">
          <div className="app-header__content">
            <div className="app-header__left">
              <span className="app-header__heading">
                <div>
                  <span>
                    <Icon icon="app-schaffe-ich" />
                  </span>
                  <h1>{bodyForLang(headline, window.currentLang)}</h1>
                </div>
                <h2>{bodyForLang(subline, window.currentLang)}</h2>
              </span>
              <p dangerouslySetInnerHTML={{ __html: bodyForLang(body, window.currentLang) }}></p>
              <span className="app-header__actions">
                <Button
                  type="secondary"
                  text={t("global.rules")}
                  textColor="black"
                  backgroundColor="bg-white"
                  onClick={() => {
                    this.props.history.push(`/${window.currentLang}/rules/${this.props.appID}`);
                  }}
                />
              </span>

              <div className="schaffe-ich-room__timer-wrapper">
                <h2>
                  {this.props.hasCampaignStarted
                    ? t("app.promotional.period.ends")
                    : t("app.time.choose.challenge")}
                </h2>
                <div className="salespush-performance__box salespush-performance__box--timer">
                  <span>
                    <ProgressCircle
                      value={this.props.dateEndDiff.days}
                      maxValue={this.props.dateStartToEndDiff.days}
                      isCountDown={true}
                      text={t("app.days")}
                      expired={this.props.dateExpired}
                    />
                    <ProgressCircle
                      value={this.props.dateEndDiff.hours}
                      maxValue={24}
                      text={t("app.hours")}
                      expired={this.props.dateExpired}
                    />
                    <ProgressCircle
                      value={this.props.dateEndDiff.minutes}
                      maxValue={60}
                      text={t("app.mins")}
                      expired={this.props.dateExpired}
                    />
                  </span>
                </div>
              </div>

            </div>
          </div>
          <span className="app-header__box">
            <UncontrolledLottie animationData={clap} />
          </span>
        </div>
      </>
    );
  }
}

SchaffeIch.contextType = AppContext;
export default withRouter(SchaffeIch);
