import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icons/Icon";
import { AppContext } from "../../../data/ContextProvider";

const ButtonWishlist = props => {

  const [isWished, setIsWished] = useState(props.isWished);
  const context = useContext(AppContext);

  const handleWish = (event) => {
    event.preventDefault();
    if (isWished) {
      context.handleRemoveFromWishlist(props.productId, props.variantId)
    } else {
      context.handleAddToWishlist(props.productId, props.variantId)
    }
    setIsWished(!isWished);

    if (props.isOnWishlistPage) {
      // context.setHasWishlistUpdated(true)
      // window.location.reload();
    }
  };

  return (
    <>
      {context.getAppIdOfUser() === "17" ? (
        <span
          className={`button-wishlist button-wishlist--like ${context.isClubApp ? "button-wishlist--club" : ""} ${isWished ? "button-wishlist--active" : ""}`}
          onClick={props.onClick ? props.onClick : (e) => handleWish(e)}>
            {isWished ? <Icon icon={`heart-active`} /> : <Icon icon={`heart`} />}
        </span>
      ) : (
        <span
          className={`button-wishlist button-wishlist--like ${context.isClubApp ? "button-wishlist--club" : ""} ${isWished ? "button-wishlist--active" : ""}`}
          onClick={props.onClick ? props.onClick : (e) => handleWish(e)}>
          <Icon icon={`heart-active`} />
        </span>
      )}
    </>
  )
};

ButtonWishlist.propTypes = {
  isWished: PropTypes.bool,
  productId: PropTypes.number,
  variantId: PropTypes.number,
  onClick: PropTypes.func
};
ButtonWishlist.defaultProps = {
  isWished: false,
  variantId: null
};

export default ButtonWishlist;
