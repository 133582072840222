import React, { Component } from "react";
import { withRouter } from "react-router-dom"
import { getProjectTexts } from "../data/api";
import { bodyForLang } from "../lib/lib";

import Header from "../components/element/header-section/HeaderSection";
import GloriaRewardsShopHeader from "./GloriaRewards/Components/GloriaRewardsShopHeader";
import Shop from "./Shop/Shop";

import { AppContext } from "../data/ContextProvider";
import {
  APP_GLORIAREWARDS,
  HEADLINE_SHOP,
  HEADLINE_SHOP_SUBHEADLINE,
} from "../data/constants";


class ShopPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headline: {
        body: "Prämienshop",
      },
      subheadeline: {
        body: "",
      },
      loading: true,
    };
  }

  async componentDidMount() {
    const headline     = await getProjectTexts(HEADLINE_SHOP);
    const subheadeline = await getProjectTexts(HEADLINE_SHOP_SUBHEADLINE);

    this.setState({
      headline: headline ?? this.state.headline,
      subheadeline: subheadeline ?? this.state.subheadeline,
      loading: false,
    });
  }

  render() {
    const headline     = bodyForLang(this.state.headline, window.currentLang);
    const subheadeline = bodyForLang( this.state.subheadeline, window.currentLang);

    return (
      <>
        {parseInt(this.context.getAppIdOfUser()) === APP_GLORIAREWARDS ? (
          <GloriaRewardsShopHeader title={headline} subtitle={subheadeline} />
        ) : (
          <Header heading={headline} subHeading={subheadeline} />
        )}
        <Shop
          lang={window.currentLang}
          category={this.props.match?.params?.category || null}
          subcategory={this.props.match?.params?.subcategory || null}
        />
      </>
    );
  }
}

ShopPage.contextType = AppContext;
export default withRouter(ShopPage);
