import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import Textfield from "../../atom/textfield/Textfield";
import t from "../../../helpers/t9n";

class SectionProfileLogin extends Component {
  render() {
    return (
      <div className="profile-login__section">
        <h2>{t("app.profile.login.heading")}</h2>
        <div className="profile-login__container">
          <form name="login-form" className="login-form" onSubmit={(e) => this.props.handleUpdatePassword(e)}>
            <Textfield
              label={t("app.profile.login.current.pw")}
              name="current_password"
              placeholder={t("app.profile.login.current.pw")}
              inputType="password"
              handleChange={(e) => this.props.handleChange(e)}
            />
            <Textfield
              label={t("app.profile.login.new.pw")}
              name="password"
              placeholder={t("app.profile.login.new.pw")}
              inputType="password"
              handleChange={(e) => this.props.handleChange(e)}
            />
            <Textfield
              label={t("app.profile.login.new.pw.same")}
              name="password_repeat"
              placeholder={t("app.profile.login.new.pw.same")}
              inputType="password"
              handleChange={(e) => this.props.handleChange(e)}
            />

            {this.props.error &&
              <p className="infotext error">
                {this.props.error}
              </p>
            }

            {this.props.success &&
              <p className="infotext success">
                {this.props.success}
              </p>
            }
            <span className="login__action">
              <Button type="primary" text={t("app.profile.login.new.pw.safe")} textColor="white" />
            </span>
        </form>
        </div>
      </div>
    );
  }
}

SectionProfileLogin.contextType = AppContext
export default SectionProfileLogin;