import React, { Component } from "react";
import Button from "../../../components/atom/button/Button";
import Icon from "../../../components/atom/icons/Icon";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";
import {getContracts, assignUser} from "../../../data/api/contracts";
import { formatDate } from "../../../lib/lib";
import SimplePagination from "../../../components/atom/pagination/SimplePagination";
import { withRouter } from "react-router-dom";
import ModalContractsSuccess from "./ModalContractsSuccess";

class OpenContractsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isDisabledActive: false,
      values: [],
      query: "",
      pagination: {
        currentPage: 1,
        prevPage: 1,
        nextPage: 1,
        itemsPerPage: 10
      },
      view_mode: "active",
      // status: "unassigned"
    };
  }

  onChange(id) {
    let selected = this.state.values;
    let find = selected.indexOf(id);

    if (find > -1) {
      selected.splice(find, 1);
    } else {
      selected.push(id);
    }

    this.setState({ selected });

    if (selected.length > 0) {
      this.setState({ isDisabledActive: true });
    } else {
      this.setState({ isDisabledActive: false });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const contractIds = this.state.values

    try {
      const response = await assignUser(contractIds)

      if (response) {
        await this.loadData()
        await this.context.getUserStats()

        this.context.toggleModalSuccessContract()

        this.setState({
          messages: {success: ["Erfolgreich gespeichert"]}
        })
      }
    } catch (error) {
      if (error) {
        console.log("error", error)
        this.setState({ messages: { errors:[error.data.error] }})
      }
    }
  }

  paginate = (pageNumber) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        currentPage: pageNumber
      },
      data: null
    },
    async () => {
      await this.loadData()
    })
  }

  loadData = async () => {
    // get contracts(active*|archived)
    const data = await getContracts(this.state.pagination.currentPage, this.state.view_mode, this.state.status, this.state.query)

    this.setState({
      ...this.state,
      loading: false,
      data: data,
      pagination: {
        ...this.state.pagination,
        prevPage: data.meta.prev_page,
        currentPage: data.meta.current_page,
        nextPage: data.meta.next_page,
        totalPages: data.meta.total_pages
      }
    })
  }

  async componentDidMount() {
    if (this.context.getUserData().contracts_enabled) {
      await this.loadData();
    }
  }

  render() {
    if (this.state.data === null || this.state.loading) return t("global.loading");

    const contracts = this.state.data.contracts;

    return (
      <>
        <div className="contracts">
          <div className="contracts__content">
            <h2>Offene Zuordnungen</h2>

            <div className="contracts__left">
              {/* Table Start */}
              <div className="contracts__table">
                {contracts.length > 0  ? (
                  <>
                    <div className="contracts__head">
                      <div className="contracts__row">
                        <div className="contracts__cell"></div>
                        <div className="contracts__cell">Zuordnung</div>
                        <div className="contracts__cell">Buchungstext</div>
                        <div className="contracts__cell">Punkte</div>
                        <div className="contracts__cell">Vorname</div>
                        <div className="contracts__cell">Nachname</div>
                      </div>
                    </div>

                    <div className="contracts__body">
                      {contracts.map((item, index) => {
                        if (item.user_id === 0) {
                          return (
                            <div
                              className={
                                this.state.values.includes(item.id)
                                  ? "contracts__row contracts__row--active"
                                  : "contracts__row"
                              }
                              key={item.id}
                              onClick={() => this.onChange(item.id)}
                            >
                              <div className="contracts__cell">
                                <span className="item-index">
                                  {this.state.values.includes(item.id) ? (
                                    <Icon icon="check" />
                                  ) : (
                                    <span className="num">
                                      {("0" + (index + 1)).slice(-2)}
                                    </span>
                                  )}
                                </span>
                              </div>
                              <div className="contracts__cell">{item.assigned_at ? formatDate(item.assigned_at, false) : ''}</div>
                              <div className="contracts__cell"><span className="shorten-txt">{item.description}</span></div>
                              <div className="contracts__cell">{Number(item.points).toLocaleString()} PKT</div>
                              <div className="contracts__cell">{(item.user) ? item.user.first_name : ''}</div>
                              <div className="contracts__cell">{(item.user) ? item.user.last_name : ''}</div>
                            </div>
                          )
                        } else {
                          return (
                            <div
                              className="contracts__row contracts__row--assigned"
                              key={item.id}
                            >
                              <div className="contracts__cell">
                                <span className="item-index">
                                  <Icon icon="check" />
                                </span>
                              </div>
                              <div className="contracts__cell">{item.assigned_at ? formatDate(item.assigned_at, false) : ''}</div>
                              <div className="contracts__cell"><span className="shorten-txt">{item.description}</span></div>
                              <div className="contracts__cell">{Number(item.points).toLocaleString()} PKT</div>
                              <div className="contracts__cell">{(item.user) ? item.user.first_name : ''}</div>
                              <div className="contracts__cell">{(item.user) ? item.user.last_name : ''}</div>
                            </div>
                          )
                        }
                      })}
                    </div>
                  </>
                ) : (
                  <div className="contracts__empty">
                    <h3>Keine Einträge vorhanden</h3>
                  </div>
                )}
              </div>
              {/* Table End */}

              {contracts.length > 0 ? (
                <div className="contracts__pagination">
                  <SimplePagination
                    currentPage={this.state.pagination.currentPage}
                    prevPage={this.state.pagination.prevPage}
                    nextPage={this.state.pagination.nextPage}
                    paginate={this.paginate}
                    totalPages={this.state.pagination.totalPages}
                  />
                </div>
              ) : (
                null
              )}

              <div className="contracts__archive">
                <h3>Hier gelangen Sie zum Vertragsarchiv</h3>
                <Button
                  type="secondary"
                  text="Zum Archiv"
                  textColor="white"
                  additionalClass="button--secondary-inverted"
                  onClick={() => {
                    this.props.history.push(`/${window.currentLang}/contracts/archived`);
                  }}
                />
              </div>
            </div>

            {contracts.length > 0 ? (
              <div className="contracts__right">
                <div className="contracts__box">
                  <h4>
                    Bitte wählen Sie die Verträge aus, die Sie abgeschlossen haben und bestätigen Sie diese anschließend.
                  </h4>
                  <Button
                    type="primary"
                    text="Verträge bestätigen"
                    textColor="black"
                    onClick={this.handleSubmit}
                    disabled={!this.state.isDisabledActive}
                  />
                </div>
              </div>
            ) : (
              null
            )}

          </div>
        </div>

        {this.context.isModalContractSuccessisible &&
          <ModalContractsSuccess />
        }
      </>
    );
  }
}

OpenContractsTable.contextType = AppContext;
export default withRouter(OpenContractsTable);
