import React, { Component } from "react";

import Icon from "../../../components/atom/icons/Icon";
import Select from "react-select";

import starBuben from "../../../assets/images/stern-buben.png";

import { AppContext } from "../../../data/ContextProvider";
import { formatDate, unixTimestamp } from "../../../lib/lib";
import { loadChat, createChat, deleteChat, updateReactionToChat, loadRecipients } from "../../../data/api";


export class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChatOpen: false,
      isEmojisOpen: false,
      isEmojisAllOpen: false,
      mentions: false,
      loading: true,
      text: "",
      recipient_id: 0,
      recipients: [],
      messages: [],
      reactions: {"emoji_thumbs_up": "👍", "emoji_heart": "❤️", "emoji_happy": "🙂", "emoji_sad": "🙁", "emoji_angry": "😡" },
      emojis: ["😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "🤑", "😎", "🤓", "🤯"]
    };

    this.messagesEndRef = React.createRef();
    this.interval = null;
  }

  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView(false)
  }

  scrollToClass = () => {
    const scrollClass = document.querySelector(".chat-support__message-newest");

    if (scrollClass) {
      scrollClass.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else {
      this.scrollToBottom();
    }
  }

  handleEmojiSelection = (id) => {
    this.setState({
      text: `${this.state.text} ${this.state.emojis[id]}`,
      isEmojisAllOpen: false
    });
  }

  handleEmojiReaction = async (message, emoji_type) => {
    const chat = this.state.chat
    const message_index = chat.findIndex(m => m.id === message.id)

    chat[message_index] = await updateReactionToChat(message.id, emoji_type)

    this.setState({ chat })
  }

  handleChange = (e) => {
    this.setState({ text: e.target.value });
  }

  onChannelSelect = async (e) => {
    this.setState({ recipient_id: e.value }, async () => {
      await this.loadData()
      await this.scrollToClass()
    });
  }

  handleChat = async () => {
    const isChatOpen = !this.state.isChatOpen;

    if (isChatOpen) {

      setTimeout(() => {
          this.scrollToClass();
        }, 100)

      this.interval = setInterval(this.loadData, 5000);
    } else {
      clearInterval(this.interval)
    }

    this.setState({ isChatOpen })
  }

  handleMessageEmojis = () => {
    this.setState({
      isEmojisOpen: !this.state.isEmojisOpen
    });

    const hover = document.querySelector(".chat-support__message");

    if (hover) {
      hover.addEventListener("mouseleave", () => {
        this.setState({
          isEmojisOpen: false
        });
      })
    }
  }

  handleAllEmojis = () => {
    this.setState({
      isEmojisAllOpen: !this.state.isEmojisAllOpen
    });
  }

  deletePost = async (message) => {
    await deleteChat(message.id)
    this.setState({
      chat: this.state.chat.filter(m => m.id !== message.id)
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.text.length === 0) {
      return;
    };

    const message = await createChat({message: this.state.text, recipient_id: this.state.recipient_id})

    const chat = this.state.chat
    chat.push(message)


    this.setState({
      chat: chat,
      text: ''
    });

    this.storeLastReadingDate()
    this.scrollToBottom()
  }

  handleScrollPosition = () => {
    const chatMessages = document.querySelector(".chat-support__body");
    if (chatMessages) {
      //Bottom
      if ((chatMessages.scrollTop + chatMessages.clientHeight) === chatMessages.scrollHeight) {
        this.storeLastReadingDate();
      }
    }
  }

  getLastReadingTimestamp = () => {
    let data = {}
    const tmp = localStorage.getItem("postCreatedAt")
    const recipient_id = this.state.recipient_id

    if (tmp) {
      data = JSON.parse(tmp)
      if (data[recipient_id]) {
        return data[recipient_id]
      }
    }

    return unixTimestamp(new Date())
  }

  storeLastReadingDate = () => {
    const timestamp = unixTimestamp(new Date())

    let data = {}
    const tmp = localStorage.getItem("postCreatedAt")
    const recipient_id = this.state.recipient_id

    if (tmp) {
      data = JSON.parse(tmp)
    }
    data[recipient_id] = timestamp

    localStorage.setItem("postCreatedAt", JSON.stringify(data))
  }

  getLastMessageTimestamp = () => {
    if (this.state.chat && this.state.chat.length) {
      const lastItem = this.state.chat[this.state.chat.length - 1];
      if (lastItem) {
        return lastItem.created_at_timestamp
      }
    }
    return 0
  }

  loadData = async () => {
    const chat       = await loadChat(this.state.recipient_id);
    const recipients = await loadRecipients();

    this.setState({
      chat: chat,
      recipients: recipients,
      loading: false,
    });
  }

  async componentDidMount() {
    this.loadData();
  }

  renderRecipientsOptions = () => {
    const out = [{ value: 0, label: 'Alle' }]

    this.state.recipients.forEach((recipient) => {
      out.push(
        { value: recipient.id, label: recipient.full_name }
      )
    })
    return out
  }

  renderCurrentUserClass = (message) => {
    if (this.context.getUserData().uid === message.user_id) {
      return "chat-support__message--user"
    }
    return ""
  }

  renderNewsMessageClass = (message) => {
    if (message.created_at_timestamp > this.getLastReadingTimestamp()) {
      return "chat-support__message-newest"
    }
    return ""
  }

  renderReadState = (message) => {
    if (message.created_at_timestamp > this.getLastReadingTimestamp()) {
      return "chat-support__status--read"
    }
    return ""
  }

  renderGlobalReadState = () => {
    if (this.getLastMessageTimestamp() > this.getLastReadingTimestamp()) {
      return <span className="chat-support__new-message"></span>
    }
    return ""
  }

  renderMessageReactions = (message) => {
    const reactions = []
    const keys = ["emoji_thumbs_up", "emoji_heart", "emoji_happy", "emoji_sad", "emoji_angry"]

    keys.forEach((key) => {
      if (message[key] > 0) {
        reactions.push(
          <div key={key}>{this.state.reactions[key]} <span className="chat-support__message-reaction-num">{message[key]}</span></div>
        )
      }
    })
    return reactions
  }

  render() {
    if (this.state.loading) {
      return ""
    }

    return (
      <div className="chat-support">

        {/* Bubble */}
        <div className="chat-support__bubble" onClick={this.handleChat}>

          {this.renderGlobalReadState()}

          <img src={starBuben} alt="" />
        </div>
        {/* Bubble */}

        {/* Window */}
        <div className={`chat-support__window ${this.state.isChatOpen ? "chat-support__window--active" : ""}`}>
          <div className="chat-support__head">Chat (Wähle, wem du schreiben möchtest)</div>

          <div className="chat-support__user-select">
            <Select
              placeholder="Alle"
              closeMenuOnSelect={true}
              defaultValue={0}
              onChange={this.onChannelSelect}
              options={this.renderRecipientsOptions()}
              width="10px"
            />
          </div>

          <div className="chat-support__body" onWheel={this.handleScrollPosition}>

            {/* Message */}
            {this.state.chat.map((message, index) => (

              <div key={index} className={`chat-support__message ${this.renderNewsMessageClass(message)} ${this.renderCurrentUserClass(message)}`}>
                <div className="chat-support__avatar">

                  {message.user && message.user.avatar_path !== null ? (
                    <img src={`${process.env.REACT_APP_API_HOST_LOGIN}` + message.user.avatar_path} alt="" />
                  ) : (
                    <img src={ `${process.env.PUBLIC_URL}/images/img--user-placeholder.jpg`} alt="" />
                  )}

                </div>

                <div className="chat-support__message-send">
                  <div className="chat-support__message-show">
                    {message.message}
                  </div>

                  <div className="chat-support__message-reaction">
                    {this.renderMessageReactions(message)}
                  </div>

                  <small>{formatDate(message.created_at, true)}</small>

                  <span className={`chat-support__status ${this.renderReadState(message)}`}></span>

                  <div className="chat-support__reactions">
                    <div className="chat-support__emojis" onClick={this.handleMessageEmojis}>
                      <Icon icon="confetti" />
                    </div>

                    { (this.context.getUserData().uid === message.user_id) &&
                      <div className="chat-support__settings" onClick={() => this.deletePost(message)}>
                        <Icon icon="garbage" />
                      </div>
                    }

                    <div className={`chat-support__emojis-selection ${this.state.isEmojisOpen ? "chat-support__emojis-selection--active" : ""}`}>
                      <ul>
                        {Object.entries(this.state.reactions).map((reaction, reaction_index) => (
                          <li onClick={() => this.handleEmojiReaction(message, reaction[0])} key={reaction_index}>{reaction[1]}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* Message */}

            <div className="bottom-scroll" ref={this.messagesEndRef}></div>

          </div>

          <div className="chat-support__footer">
            <div className="chat-support__footer-emojis">
              <span className="chat-support__emoji-button" onClick={this.handleAllEmojis}><Icon icon="confetti" /></span>
              <div className={`chat-support__emoji-list ${this.state.isEmojisAllOpen ? "chat-support__emoji-list--active" : ""}`}>
                <ul>
                  {this.state.emojis.map((emoji, index) => (
                    <li onClick={() => this.handleEmojiSelection(index)} key={index}>{emoji}</li>
                  ))}
                </ul>
              </div>
            </div>

            <form onSubmit={this.handleSubmit}>
              <input
                type="text"
                value={this.state.text}
                onChange={this.handleChange}
                className="chat-support__mention"
                placeholder="Bitte hier Ihre Nachricht eingeben"
              />
              <button className="btn btn-primary">
                <Icon icon="send-email-fly" />
              </button>
            </form>

          </div>
        </div>
        {/* Window */}

      </div>
    );
  }
}

Chat.contextType = AppContext;
export default Chat;
