import React, { Component } from "react";
import Selectfield from "../../components/atom/selectfield/Selectfield";
import Textfield from "../../components/atom/textfield/Textfield";
import Button from "../../components/atom/button/Button";
import Textarea from "../../components/atom/textarea/Textarea";
import UncontrolledLottie from "../../components/atom/lottie/UncontrolledLottie";
import contact from "../../assets/lotties/contact/anim--icon-contact";
import { sendContactMail } from "../../data/api";
import { AppContext } from "../../data/ContextProvider";
import t from "../../helpers/t9n";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isReasonSelected: false,
      formData: {},
      projectId: null,
    };
  }

  handleReason = (reason) => {
    const state = this.state;
    state.formData.reason = reason.target.value;
    state.formData.subject = this.contactRasons[reason.target.value];

    this.setState({
      ...state,
      isReasonSelected: true,
    });
  };

  handleReset = () => {
    const placeholder = document.querySelector(".contact__choose-reason .selectfield__selected");
    placeholder.textContent = t("contact.page.choose");
    this.setState({
      formData: {},
      isReasonSelected: false,
    });
  };

  handleContactMail = (e) => {
    e.preventDefault();
    const data = { ...this.state.formData };
    const isSupport = this.state.formData.reason === "help3";
    const res = sendContactMail(isSupport, data, this.state.projectId);
    res
      .then((res) => {
        this.context.toggleModalInfo(false, t("contact.page.success"));
        this.handleReset();
      })
      .catch((error) => {
        this.context.toggleModalInfo(true);
      });
  };

  handleChange = (e) => {
    // console.log(e.target.name, e.target.value)
    if (e.target) {
      const name = e.target.name;
      const value = e.target.value;
      const state = this.state;
      state.formData[name] = value;
      this.setState({ ...state });
    }
  };

  async componentDidMount() {
    setTimeout(() => {
      this.handleReset();
    }, 10);

    this.contactRasons = {
      help1: t("contact.page.help1"),
      help2: t("contact.page.help2"),
      help3: t("contact.page.help3"),
    };

    const p = this.context.getProjectData()

    if (p) {
      this.setState({ projectId: p.id, project: p });
    } else {
      this.contactRasons = {
        help3: t("contact.page.help3.special"),
      };
    }
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) {
      return "Loading...";
    }

    let contact_info = t("contact.page.info")

    if (this.state.project.contact_options && this.state.project.contact_options.length) {
      contact_info = this.state.project.contact_options
    }

    return (
      <>
        <div className="content contact">
          <span className="contact__header">
            <UncontrolledLottie animationData={contact} />
          </span>
          <div className="contact__content">
            <div className="contact__choose-reason">
              <h3>{t("contact.page.heading")}</h3>
              <Selectfield
                label={t("contact.page.reason")}
                options={this.contactRasons}
                onSelect={this.handleReason}
                isRequired={true}
                name="reason"
              />
            </div>

            {this.state.isReasonSelected && (
              <div className="contact__form">
                <form onSubmit={(e) => this.handleContactMail(e)}>
                  <div
                    className={
                      "login__columns--wrapper login__columns--wrapper--step2"
                    }
                  >
                    <div className={"login__row"}>
                      <div className={"login__col"}>
                        <Selectfield
                          label={t("app.salutation")}
                          options={{
                            herr: t("app.mr"),
                            frau: t("app.ms"),
                            dr: t("app.dr"),
                          }}
                          name={"salutation"}
                          onSelect={(e) => this.handleChange(e)}
                          isRequired={true}
                          placeholder={t("app.salutation")}
                        />
                      </div>
                    </div>
                    <div className={"login__row"}>
                      <div className={"login__col"}>
                        <Textfield
                          label={`${t("app.firstname")}*`}
                          placeholder={t("app.firstname.placeholder")}
                          isRequired={true}
                          name={"first_name"}
                          handleChange={(e) => this.handleChange(e)}
                        />
                      </div>
                      <div className={"login__col"}>
                        <Textfield
                          label={`${t("app.lastname")}*`}
                          placeholder={t("app.lastname.placeholder")}
                          name={"last_name"}
                          isRequired={true}
                          handleChange={(e) => this.handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className={"login__row"}>
                      <div className={"login__col"}>
                        <Textfield
                          label={t("app.email")}
                          isRequired={true}
                          placeholder={t("app.email.placeholder")}
                          name={"email"}
                          handleChange={(e) => this.handleChange(e)}
                        />
                      </div>
                      <div className={"login__col"}>
                        <Textfield
                          label={t("app.customer")}
                          // isRequired={true}
                          placeholder={t("app.customer.placeholder")}
                          name={"company"}
                          handleChange={(e) => this.handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className={"login__row"}>
                      <div className={"login__col"}>
                        <Textfield
                          label={`${t("app.phone")}*`}
                          placeholder={t("app.phone.placeholder")}
                          name={"phone"}
                          isRequired={true}
                          handleChange={(e) => this.handleChange(e)}
                        />
                      </div>
                      <div className={"login__col"}>
                        {this.state.formData.reason === "help3" && (
                          <Textfield
                            label={`${t("contact.page.order.nr")}*`}
                            inputType={"text"}
                            name={"order_number"}
                            isRequired={true}
                            handleChange={(e) => this.handleChange(e)}
                          />
                        )}
                      </div>
                    </div>
                    <div className={"login__row"}>
                      <Textarea
                        label={t("contact.page.message")}
                        placeholder={t("contact.page.message.placeholder")}
                        isRequired={true}
                        name={"message"}
                        handleChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <p style={{fontSize: "12px", marginTop: "20px", maxWidth: "100px", float: "left"}}>{t("global.mandatory.fields")}</p>
                    <Button
                      type={"primary"}
                      textColor={"white"}
                      text={t("contact.page.send")}
                      style={{ marginTop: "0" }}
                    />
                  </div>
                </form>
              </div>
            )}

            <div className={"contact__telephone"}>
              <h4>
                {contact_info}
              </h4>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Contact.contextType = AppContext;

export default Contact;
