import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/element/header-section/HeaderSection";
import t from "../helpers/t9n";
import OrdersDetail from "./Orders/OrdersDetail";

const OrdersDetailPage = () => {
  let { lang, orderid } = useParams();
  
  return (
    <>
      <Header heading={t("global.order.view")} hasImage={true} isRed={true} />
      <OrdersDetail lang={lang} orderid={orderid} />
    </>
  )
}

export default OrdersDetailPage;
