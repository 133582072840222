import React, { Component } from "react";
import Header from "../Components/Header";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";

class Complete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <div className="compl-tool">

          <Header headline="Herzlich Willkommen" />

          <div className="content padding-top">
            <h3>Die Registrierung ist abgeschlossen.</h3>
            <h4>Sie können keine weiteren Mitarbeiter zur Aktion anmelden.</h4>
          </div>
        </div>
      </>
    );
  }
}

Complete.contextType = AppContext;
export default Complete;
