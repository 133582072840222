import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/element/header-section/HeaderSection";
import ArchiveContractsTable from "../components/ArchiveContractsTable";

const ContractsArchived = () => {
  let { lang } = useParams();

  return (
    <>
      <Header heading={"Archiv"} hasImage={true} isRed={true} />
      <ArchiveContractsTable lang={lang} />
    </>
  )
}

export default ContractsArchived;