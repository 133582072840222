import React from "react";
import { useParams } from "react-router-dom";
import Home from "./Home/Home";

const ShopHomePage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <Home lang={lang} />
    </>
  )
}

export default ShopHomePage;
