import React, { Component } from "react";
import { Link } from "react-router-dom";

import Button from "../../atom/button/Button"

import { formatDate, shortText, bodyForLang } from "../../../lib/lib";
import { getLatestNews, getProjectTexts } from "../../../data/api";

import { AppContext } from "../../../data/ContextProvider";

import t from "../../../helpers/t9n";

import { API_HOST } from "../../../data/config";
import {
  HEADLINE_NEWS
} from "../../../data/constants";

export class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      loading: true,
      headline: {
        body: "News",
      },
    };
  }

  async componentDidMount() {
    const news = await getLatestNews();
    const headline = await getProjectTexts(HEADLINE_NEWS);

    this.setState({
      news: news.news,
      loading: false,
      headline: headline ?? this.state.headline,
    });
  }

  render() {
    const { news, loading } = this.state

    if (loading) return t("global.loading")

    const headline = bodyForLang(this.state.headline, window.currentLang);

    return (
      <>
        <div className="content-wrapper">
          <div className="content-wrapper-inner">
            <div className="news-content">
              <h2>{headline}</h2>
              <ul>
                {news.length > 0 ? (
                  <>
                    {news.map((obj) => (
                      <li key={obj.id}>
                        <div className={!obj.was_read ? "news-content__item news-content__item--new" : "news-content__item"}>
                          <div className={obj.teaserimage_id !== 0 ? "news-content__image" : "news-content__image news-content__image--empty"}>
                            {!obj.was_read && (
                              <div className="news-content__badge">{t("app.new")}</div>
                            )}

                            {(obj.teaserimage_id !== 0) &&
                              <figure>
                                <img src={`${API_HOST}/news/teaserimage/${obj.id}?domain=${document.domain}`} alt=""/>
                                <cite>{obj.subheadline}</cite>
                              </figure>
                            }
                          </div>

                          <div className={obj.teaserimage_id !== 0 ? "news-content__content" : "news-content__content news-content__content--full"}>
                              <small>{formatDate(obj.updated_at, false)}</small>
                              <h3><span>{obj.headline}</span></h3>
                            <div className="editor">
                              <p dangerouslySetInnerHTML={{__html: shortText(obj.body, 150) }}></p>
                            </div>

                            <div className="news-content__action">
                              <Link to={`/${window.currentLang}/news#${obj.id}`}>
                                <Button
                                  type="primary"
                                  text={t("app.read.on")}
                                  textColor="white"
                                  additionalClass="button--icon--right"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </>
                ) : (
                    <li className="news-content--empty">{t("app.news.no.heading")}</li>
                )}
              </ul>

              <div className="news-content__all-news">
                <Link to={`/${window.currentLang}/news`}>
                  <Button
                    type="secondary"
                    text={t("app.read.all.news")}
                    additionalClass="button--icon--right"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

News.contextType = AppContext
export default News
