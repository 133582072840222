import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/element/header-section/HeaderSection";
import Account from "./Account/Account";
import t from "../helpers/t9n";

const AccountPage = () => {
  let { lang } = useParams();

  return (
    <>
      <Header heading={t("global.points.account")} hasImage={true} isRed={true} />
      <Account lang={lang} />
    </>
  )
}

export default AccountPage;
