import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/element/header-section/HeaderSection";
import t from "../helpers/t9n";
import Profile from "./Profile/Profile";

const ProfilePage = () => {
  let { lang } = useParams();

  return (
    <>
      <Header heading={t("global.profile.page.heading")} hasImage={true} isRed={true} />
      <Profile lang={lang} />
    </>
  )
}

export default ProfilePage;
