import React, { Component } from "react";

import Button from "../../../components/atom/button/Button";


import { logoutManager } from "../../../lib/lib";
import { API_HOST } from "../../../data/config";

import { AppContext } from "../../../data/ContextProvider";
import { withRouter } from "react-router-dom";


class Navbar extends Component {

  handleLoginLogout() {
    logoutManager()
  }

  handleProfile = (path) => {
    this.props.history.push(`/${window.currentLang}/` + path)
  }

  render() {
    return (
      <>
        <nav className="navbar__container navbar__container--black navbar__container--compl">
          <div className={"navbar__header"}>
            <span className="navbar__logo">
              <a href={`/${window.currentLang}/compliance-tool/registration`} className="logo">
                <img src={`${API_HOST}/asset/logo/for-domain?domain=${window.location.host}`} alt="logo" />
              </a>
            </span>
          </div>

          <div className="navbar__user linked">
            <Button
              onClick={() => this.handleProfile("compliance-tool/profile")}
              type="noOutline"
              additionalClass="navbar__logout-btn button--icon--right"
              textColor="white"
              icon="single-neutral-actions"
              text={this.context.getNameOfUser()}
            />

            <Button
              onClick={() => this.handleLoginLogout()}
              type="noOutline"
              additionalClass="navbar__logout-btn"
              textColor="white"
              icon="logout"
            />
          </div>
        </nav>
      </>
    );
  }
}


Navbar.contextType = AppContext;
export default withRouter(Navbar);
