import React, { Component } from "react";
import CardSlider from "../../components/module/card-slider/CardSlider";
import SectionAccountHeader from "../../components/module/section-account-header/SectionAccountHeader";
import t from "../../helpers/t9n";

class Account extends Component {
  render() {
    return (
      <>
        <div className={"content private"}>
          <SectionAccountHeader />
          
          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} />
          </div>
        </div>
      </>
    );
  }
}

export default Account;
