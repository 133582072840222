import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import { getAppTexts } from "../../data/api"
import Header from "../../components/element/header-section/HeaderSection"

import t from "../../helpers/t9n"
import { AppContext } from "../../data/ContextProvider"
import { bodyForLang } from "../../lib/lib";

export class RulesPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      headline: "",
      subline: "",
      lower_content_text: "",
      upper_content_text: ""
    }
  }

  loadData = async () => {

    let headline = ""
    let subline = ""
    let lower_content_text = ""
    let upper_content_text = ""

    const texts = await getAppTexts(this.state.appId)

    texts.forEach(text => {
      if (text.key === 'p33_header_content_headline') {
        headline = text
      }
      if (text.key === 'p33_header_content_subline') {
        subline = text
      }
      if (text.key === 'p33_upper_content_text') {
        upper_content_text = text
      }
      if (text.key === 'p33_lower_content_text') {
        lower_content_text = text
      }
    })

    this.setState({
      headline: headline,
      subline: subline,
      upper_content_text: upper_content_text,
      lower_content_text: lower_content_text,
      loading: false
    })
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params) {
      this.setState({
        ...this.state,
        appId: this.props.match.params.appId,
      }, async () => {
        await this.loadData()
      })
    }
  }

  render() {
    if (this.state.loading) {
      return "Loading.."
    }

    return (
      <>
        <Header heading={t("global.rules.short")} hasImage={true} isRed={true} />
        <div className={"content"}>
          <div className={"content--rules"}>
            <h1>{bodyForLang(this.state.headline, window.currentLang)}</h1>
            <h2>{bodyForLang(this.state.subline, window.currentLang)}</h2>
            <p dangerouslySetInnerHTML={{ __html: bodyForLang(this.state.upper_content_text, window.currentLang) }}></p>
            <p dangerouslySetInnerHTML={{ __html: bodyForLang(this.state.lower_content_text, window.currentLang) }}></p>
          </div>
        </div>
      </>
    );
  }
}


RulesPage.contextType = AppContext;
export default withRouter(RulesPage);