import React, { Component } from 'react'

import Dropzone from "react-dropzone";
import { Link } from 'react-router-dom';

import Button from "../../../components/atom/button/Button";
import Icon from '../../../components/atom/icons/Icon';

import { uploadCtUserCsv, importCtUsers } from "../../../data/ct-api";

export default class UserImport extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      showSuccess: false,
      showError: false,
      showImportPreview: false,
      showDropzone: true,
      errors: []
    }
  }

  handleChange = (e) => {
    const user = this.state.user
    if (e.target) {
      user[e.target.name] = e.target.value
      this.setState({ user });
    }
  }

  doImport = async () => {
    try {
      const response = await importCtUsers(this.state.importId)
      if (response.report) {
        this.setState({ messages: response.report, showImportPreview: false, showImport: false, preview: []  }, ()=>{

          if (response.report && response.report.errors && response.report.errors.length > 0) {
            this.setState({ errors: response.report.errors, showError: true })
          } else {
            this.props.onSuccess(response.report)
            this.setState({ showSuccess: true, showDropzone: false })
          }
        })
      }
    } catch (error) {
      this.setState({ messages: error.errors, showError: true })
    }
  }

  onDrop = async (files) => {
    if (files && files.length && files.length > 0) {
      if (files[0]) {

        try {
          const response = await uploadCtUserCsv(files[0])
          if (response && response.data) {
            this.setState({
              preview: response.data.report,
              importId: response.data.import_id,
              showImportPreview: true,
              showImport: false,
              loading: false,
            })
          }

        } catch (err) {
          console.log("error onDrop", err)

          if (err.data && typeof err.data === 'string') {
            this.setState({ messages: err.data, hasError: true, loading: false, showError: true })
          } else if (err.data && err.data.report) {
            this.setState({ messages: err.data.report.join(), hasError: true, loading: false, showError: true })
          } else {
            this.setState({ loading: false, messages: "Unbekannnter Fehler", hasError: true, showError: true })
          }
        }
      }
    }
  }

  render() {
    // @TODO html row/col cleanup, layout see ManagersImport.js
    return (
      <div className="compl__upload__container compl__upload__container--open">
        <div className="profile__personal-data">
          <div className="content">
            <span className="button-close" onClick={this.props.onClose}>
              <Icon icon="close" />
            </span>

            <span className="compl-btn">
              <Link
                className="button button--active button--black button--secondary button--undefined button--has-icon button--icon--right"
                to="../../../../templates/Verkaeufer_Stammdaten_Import.csv"
                download
                target="_blank"
              >
                Import Vorlage herunterladen
                <Icon icon="download" />
              </Link>
            </span>

            {this.state.showDropzone &&
              <Dropzone
                onDrop={this.onDrop}
                multiple={false}
                accept=".csv, application/vnd.ms-excel, text/csv"
                minSize={10}
                maxSize={5242880}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <Icon icon={"upload"} />
                    <input {...getInputProps()} />

                    <p>Ziehen Sie die Datei einfach auf dieses Feld oder klicken Sie auf den Pfeil um die Datei auszuwählen.</p>
                  </div>
                )}
              </Dropzone>
            }
          </div>
        </div>

        {this.state.showSuccess &&
          <>
            <div className="content">
              <div className="success-message">
                <h5>Erfolgreicher Import!</h5>
                <p>{this.state.messages.success[0]}</p>
              </div>
            </div>
          </>
        }

        {this.state.showError &&
          <>
            <div className="content">
              <div className="error-message">
                <h5>Ein Fehler ist aufgetreten.</h5>
                {this.state.errors.map((error, index)=>{
                  return <p key={index}>{error}</p>
                })}
              </div>
            </div>
          </>
        }

        {this.state.showImportPreview &&
          <React.Fragment>
            <h3>Vorschau</h3>

            <div className="preview">
              <table>
                <thead>
                  <tr>
                    {this.state.preview[0].map((header, index)=>{
                      return <td key={index}>{header}</td>
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.state.preview.slice(1).map((row, index)=>{
                    return <tr key={index}>
                      {row.map((r, row_index) => {
                        return <td key={row_index}>{r}</td>
                      })}
                    </tr>
                  })}
                </tbody>
              </table>
            </div>

            <div className="button-send">
              <Button
                type="primary"
                text="Importieren"
                textColor="white"
                onClick={this.doImport}
                icon="upload"
                additionalClass="button--icon--right"
              />
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
}
