import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ContextProvider from "./data/ContextProvider";

// Detect IE 11
(function () {
  let query = matchMedia("(-ms-high-contrast: none), (-ms-high-contrast: active)");
  if (query.matches) {
    document.documentElement.classList.add('oldie');
  }
})();


ReactDOM.render(
  <ContextProvider>
  <App />
  </ContextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
