import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/element/header-section/HeaderSection";
import OpenContractsTable from "../components/OpenContractsTable";

const ContractsNew = () => {
  let { lang } = useParams();

  return (
    <>
      <Header heading={"Vertragszuordnung"} hasImage={true} isRed={true} />
      <OpenContractsTable lang={lang} />
    </>
  )
}

export default ContractsNew;