import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import TinySlider from "tiny-slider-react";
import { AppContext } from "../../../data/ContextProvider";
import { API_HOST } from "../../../data/config";
import { withRouter } from "react-router";


// import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
// import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";
// import t from "../../../helpers/t9n";
// import CounterSpawner from "../../element/counter-spawner/CounterSpawner";

class HeaderSlider extends Component {
  state = {
    settings: {
      lazyload: true,
      nav: true,
      mouseDrag: true,
      controls: false
    }
  }

  onGoTo = dir => {
    this.ts.slider.goTo(dir);
  };

  render() {
    return (
      <>
      <div
        className={`app-header__section ${
          this.props.isHeaderOpen ? "app-header__section--open" : ""
        }`}
      >
        <div className="app-header__slider">
          {this.props.headerSlider.length > 1 ? (
            <span
              onClick={() => this.onGoTo("next")}
              className={`app-header__arrow app-header__arrow--right`}
            >
              <Icon icon="arrow" />
            </span>
          ) : (
            null
          )}

          {this.props.headerSlider.length > 1 ? (
            <span
              onClick={() => this.onGoTo("prev")}
              className={`app-header__arrow app-header__arrow--left`}
            >
              <Icon icon="arrow" />
            </span>
          ) : (
             null
          )}

          <TinySlider
            settings={this.state.settings}
            ref={(ts) => (this.ts = ts)}
          >
            {this.props.headerSlider ? this.props.headerSlider.map((img, index) => (
                <div
                  className={`app-header__item app-header__item--${img.variant}`}
                  key={index}
                >
                {img.url !== "" ? (
                  <a href={img.url}>
                    <div
                      className="app-header__bg"
                      style={{
                        backgroundImage: `url("${API_HOST}/slider/img/${img.id}")`,
                      }}
                    ></div>
                  </a>
                ) : (
                  <div
                    className={`app-header__bg ${img.title ? '' : 'app-header__bg--nocontent'}`}
                    style={{
                      backgroundImage: `url("${API_HOST}/slider/img/${img.id}")`,
                    }}
                  ></div>
                )}

                <div className={`app-header__content ${img.title ? '' : 'app-header__content--nocontent'}`}>
                  <div className="app-header__left">
                    <span className="app-header__heading">

                      {window.currentLang === "de" ? (
                        <>
                          {img?.title && (
                            <h1>{img.title}</h1>
                          )}
                        </>
                      ) : null}

                      {window.currentLang === "en" ? (
                        <>
                          {img?.title_en && (
                            <h1>{img.title_en}</h1>
                          )}
                        </>
                      ) : null}

                      {window.currentLang === "fr" ? (
                        <>
                          {img?.title_fr && (
                            <h1>{img.title_fr}</h1>
                          )}
                        </>
                      ) : null}

                      {window.currentLang === "it" ? (
                        <>
                          {img?.title_it && (
                            <h1>{img.title_it}</h1>
                          )}
                        </>
                      ) : null}

                      {window.currentLang === "de" ? (
                        <>
                          {img?.subtitle && <h2>{img.subtitle}</h2>}
                        </>
                      ) : null}

                      {window.currentLang === "en" ? (
                        <>
                          {img?.subtitle_en && <h2>{img.subtitle_en}</h2>}
                        </>
                      ) : null}

                      {window.currentLang === "fr" ? (
                        <>
                          {img?.subtitle_fr && <h2>{img.subtitle_fr}</h2>}
                        </>
                      ) : null}

                      {window.currentLang === "it" ? (
                        <>
                          {img?.subtitle_it && <h2>{img.subtitle_it}</h2>}
                        </>
                      ) : null}
                      
                    </span>
                  </div>

                  {/* {(this.props.project.global_jackpot_active) &&
                    <div className="app-header__right">
                      <div className="jackpot-counter">
                        <span className="jackpot-counter__icon">
                          <UncontrolledLottie animationData={confetti} />
                        </span>
                        <h2>{t("app.jackpot.counter")}</h2>
                        <span className={"jackpot-counter__wrapper"}>
                          <CounterSpawner
                            amount={~~Number(this.props.project.global_jackpot_amount)}
                            type={"highlight"}
                          />
                          <h5>{t("app.points")}</h5>
                        </span>

                        <h5>{t("app.still.in.jackpot")}</h5>
                        <div className="jackpot-counter__bank">
                          <h5>{t("app.your.point.status")}</h5>
                          <h2>
                            <b>
                              {Number(this.context.getPointsOfUser()).toLocaleString()}{" "}
                              {t("app.points")}
                            </b>
                          </h2>
                        </div>
                      </div>
                    </div>
                  } */}

                </div>
              </div>
            )) : <div></div>}
          </TinySlider>
        </div>
      </div>
      </>
    );
  }
}

HeaderSlider.contextType = AppContext
export default withRouter(HeaderSlider);