import React, { Component } from "react";
import axios from "axios";

import Logo from "../../atom/logo/LogoComplTool";
import Button from "../../atom/button/Button";

import { AppContext } from "../../../data/ContextProvider";
import PropTypes from "prop-types";
import Icon from "../../atom/icons/Icon";
import PopupCart from "../../module/popup-cart/PopupCart";
import { Link, withRouter } from "react-router-dom";
import Image from "../../atom/image/Image";
import { logoutUser } from "../../../lib/lib";
import { getCategories, getHighlights } from "../../../data/api";
import { API_HOST } from "../../../data/config";
import t from "../../../helpers/t9n";
import { getProductImage, renderTitle } from "../../../helpers/utils";
import LanguageSwitcher from "../../module/language-switcher/LanguageSwitcher";
import CounterSpawner from "../counter-spawner/CounterSpawner";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isScrollingUp: true,
      isMicroMenuMobileOpen: false,
      loading: true,
      isLoggedOut: false,
      isClubWithoutShop: false,
      categories: [],
      news: [],
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.previousScroll = 0;
  }

  handleScroll() {
    if (this.navRef) {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (scrollTop >= 60) {
        if (!this.context.isNavbarScrolling) {
          this.context.toggleNavbarScroll();
        }
      } else {
        if (this.context.isNavbarScrolling) {
          if (scrollTop <= 30) this.context.toggleNavbarScroll();
        }
      }

      if (scrollTop < this.previousScroll) {
        if (!this.state.isScrollingUp) {
          this.setState({
            isScrollingUp: true,
          });
        }
      } else {
        if (this.state.isScrollingUp) {
          this.setState({
            isScrollingUp: false,
          });
        }
      }

      this.previousScroll = scrollTop;
    }
  }

  handleMobileMicroMenu() {
    this.context.togglePopupCart(false);
    this.setState({
      isMicroMenuMobileOpen: !this.state.isMicroMenuMobileOpen,
    });
  }

  getSubCategoryMenu() {
    const subButtonArray = [];

    this.context.categoryChildren.forEach((subcat) => {
      subButtonArray.push(
        <Button
          key={subcat.id}
          type={"secondary"}
          text={renderTitle(subcat)}
          textColor={"black"}
          onClick={() =>
            this.handleCategoryLink( `/${window.currentLang}/shop/` + this.context.selectedCategory + "/" + subcat.id)
          }
        />
      );
    });

    return subButtonArray;
  }

  handleCategoryLink = (path) => {
    this.context.handleCategoryMenu();
    this.props.history.push(path);
    window.location.reload();
  };


  componentDidMount() {

    if (
      !this.context.isUserLoggedIn ||
      !this.context.getUserData().hasAcceptedGdpr
    ) {
      this.setState({
        loading: false,
        isLoggedOut: true,
      });
    } else {
      let isClubWithoutShop = false;
      if (this.context.isClubApp && !this.context.hasShopAccess) {
        isClubWithoutShop = true;
      }

      axios.all([getCategories(window.currentLang), getHighlights()]).then(
        axios.spread((categories, highlights) => {
          this.setState({
            categories: categories,
            highlights: highlights.products,
            loading: false,
            isClubWithoutShop: isClubWithoutShop,
          });
        })
      );
    }

    if (navigator.userAgent.indexOf("Mac OS X") !== -1) {
      document.querySelector("body").classList.add("mac");
    } else {
      document.querySelector("body").classList.add("pc");
    }
  }

  handleLoginLogout() {
    logoutUser();
  }

  render() {
    if (this.state.loading) {
      return t("global.loading");
    }

    const project = this.context.getProjectData();
    const stats = this.context.stats;
    const checkUser = this.context.getUserData()

    const openContracts = this.context.hasOpenContracts();

    let balanceTooLongToFit = false;
    let userNameTooLongToFit = false;

    if (!this.state.isLoggedOut) {
      userNameTooLongToFit = this.context.getNameOfUser().length >= 18;

      if (!this.state.isClubWithoutShop) {
        balanceTooLongToFit =
          Number(this.context.getPointsOfUser()).toLocaleString().length >= 14;
      }
    }

    return (
      <>
        <nav
          className={`navbar__container
          ${
            this.props.bgColor ? "navbar__container--" + this.props.bgColor : ""
          }
          ${this.context.isCategoryMenuOpen ? "navbar__container--open" : ""}
          ${this.context.isNavbarScrolling ? "navbar__container--scroll" : ""}
          ${this.state.isScrollingUp ? "navbar__container--scrolling-up" : ""}
          ${
            this.state.isMicroMenuMobileOpen
              ? "navbar__container--mobile-open"
              : ""
          }
          ${this.state.isLoggedOut ? "navbar__container--loggedOut" : ""}
          ${
            this.props.isClub || this.context.isClubApp
              ? "navbar__container--club"
              : ""
          }
          `}
          ref={(elem) => (this.navRef = elem)}
        >
          <div className={"navbar__header"}>
            <Logo
              image={`${API_HOST}/asset/logo/for-domain?domain=${document.domain}`}
            />
          </div>

          {project.global_jackpot_active && checkUser.username !== "" && (
            <>
              <div
                className={
                  this.state.jackpotCounter
                    ? "jackpot-counter-navbar jackpot-counter-navbar--active"
                    : "jackpot-counter-navbar"
                }
              >
                <h6>{t("global.jackpotHeading")}: </h6>
                <CounterSpawner
                  amount={~~Number(project.global_jackpot_amount)}
                  type={"highlight"}
                />
              </div>
            </>
          )}

          {this.state.isLoggedOut && (
            <div className="back-to-login">
              <Link to={`/${window.currentLang}/compliance-tool`}>
                {t("global.back.to.login")}
              </Link>
            </div>
          )}

          <ul className={`navbar__user`}>
            {!this.state.isLoggedOut && (
              <>
                {this.context.hasShopAccess && (
                  <>
                    {this.context.project.shop_highlights_enabled ? (
                      <Button
                        onClick={() => this.context.handleCategoryMenu()}
                        text={t("menu.award.shop")}
                        type="noOutline"
                        additionalClass="navbar__categories-btn"
                        textColor="white"
                        icon="arrow"
                      />
                    ) : (
                      <Link to={`/${window.currentLang}/shop`}>
                        <Button
                          text={t("menu.award.shop")}
                          type="noOutline"
                          additionalClass="navbar__categories-btn no-shop-access-btn"
                          textColor="white"
                          icon=""
                        />
                      </Link>
                    )}
                  </>
                )}
                <Button
                  onClick={() => this.context.handleMainMenu()}
                  text={"Menu"}
                  type={"noOutline"}
                  additionalClass={"navbar__menu-btn"}
                  textColor={"white"}
                  icon={"navigation-menu"}
                >
                  {openContracts ? (
                    <span className="navbar__micro-menu__notification navbar__micro-menu__notification--menu" />
                  ) : null}
                </Button>
                <Button
                  onClick={() => {
                    this.context.handleMainMenu();
                    this.context.handleSearch();
                  }}
                  type={"noOutline"}
                  additionalClass={"navbar__search-btn"}
                  textColor={"white"}
                  icon={"search"}
                />

                {/* Language Switcher */}
                <LanguageSwitcher />

                <span
                  className={"navbar__user-bar"}
                  onClick={() => this.handleMobileMicroMenu()}
                >
                  <span className={"navbar__username"}>
                    <span
                      className={`navbar__username-inner ${
                        userNameTooLongToFit
                          ? "navbar__username-inner--scroll"
                          : ""
                      }`}
                    >
                      {this.context.getNameOfUser()}
                    </span>
                  </span>
                  <span className={"navbar__balance"}>
                    <span
                      className={`navbar__balance-inner ${
                        balanceTooLongToFit
                          ? "navbar__balance-inner--scroll"
                          : ""
                      }`}
                    >
                      {this.state.isClubWithoutShop ? (
                        <>
                          Ihr Rang:{" "}
                          {Number(this.context.currentPlace).toLocaleString()}
                        </>
                      ) : (
                        <>
                          {Number(
                            this.context.getPointsOfUser()
                          ).toLocaleString()}{" "}
                          {t("app.points")}
                        </>
                      )}
                    </span>
                  </span>
                  <Icon icon={"arrow"} />
                </span>
                <span
                  className={"navbar__menu-button--mobile"}
                  onClick={() => this.context.handleMainMenu()}
                >
                  <Icon icon={"navigation-menu"} />
                </span>
              </>
            )}
            <Button
              onClick={() => this.handleLoginLogout()}
              type={"noOutline"}
              additionalClass={"navbar__logout-btn"}
              textColor={"white"}
              icon={`${this.state.isLoggedOut ? "login" : "logout"}`}
            />
          </ul>
          {!this.state.isLoggedOut && (
            <div
              className={`navbar__micro-menu-collector
                          ${
                            this.state.isMicroMenuMobileOpen
                              ? "navbar__micro-menu-collector--mobile-open"
                              : ""
                          }
                          ${
                            this.context.isPopupCartVisible
                              ? "navbar__micro-menu-collector--cart-open"
                              : ""
                          }
                          `}
            >
              <div id="navbar__micro-user" className={"navbar__micro-user"}>
                <span className={"navbar__user-bar"}>
                  <span className={"navbar__username"}>
                    <span
                      className={`navbar__username-inner ${
                        userNameTooLongToFit
                          ? "navbar__username-inner--scroll"
                          : ""
                      }`}
                    >
                      {this.context.getNameOfUser()}
                    </span>
                  </span>
                  <span className={"navbar__balance"}>
                    <span
                      className={`navbar__balance-inner ${
                        balanceTooLongToFit
                          ? "navbar__balance-inner--scroll"
                          : ""
                      }`}
                    >
                      {this.state.isClubWithoutShop ? (
                        <>
                          Ihr Rang:{" "}
                          {Number(this.context.currentPlace).toLocaleString()}
                        </>
                      ) : (
                        <>
                          {Number(
                            this.context.getPointsOfUser()
                          ).toLocaleString()}{" "}
                          {t("app.points")}
                        </>
                      )}
                    </span>
                  </span>
                </span>
              </div>

              <div
                id="navbar__micro-menu"
                className={"navbar__micro-menu__wrapper"}
              >
                <div className={`navbar__micro-menu`}>
                  <ul>
                    {!this.context.hasShopAccess ? (
                      <>
                        <span className={"navbar__micro-menu__item"}>
                          <Link to={`/${window.currentLang}/ranking`}>
                            <Icon icon={"ranking"} />
                          </Link>
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className={"navbar__micro-menu__item"}
                          onClick={this.context.closeAllMenus}
                        >
                          {stats.wishlist > 0 && (
                            <span
                              className={"navbar__micro-menu__notification"}
                            />
                          )}
                          <Link to={`/${window.currentLang}/wishlist`}>
                            <Icon icon={"heart"} />
                          </Link>
                        </span>
                        <span
                          className={`navbar__micro-menu__item ${
                            this.context.isPopupCartVisible
                              ? "navbar__micro-menu__item--active"
                              : ""
                          }`}
                          onClick={() => {
                            this.context.togglePopupCart();
                            this.context.closeAllMenus();
                          }}
                        >
                          {stats.cart > 0 && (
                            <span
                              className={"navbar__micro-menu__notification"}
                            />
                          )}
                          <Icon icon={"shopping-cart-full"} />
                        </span>
                      </>
                    )}
                    <span
                      className={"navbar__micro-menu__item"}
                      onClick={this.context.closeAllMenus}
                    >
                      {this.state?.filteredNews?.length > 0 && (
                        <span className={"navbar__micro-menu__notification"} />
                      )}
                      <Link to={`/${window.currentLang}/news`}>
                        <Icon icon={"new-message"} />
                      </Link>
                    </span>
                  </ul>
                  {this.context.isPopupCartVisible && <PopupCart />}
                </div>
              </div>
            </div>
          )}
        </nav>
        {this.props.isShopPage && (
          <div
            className={"navbar__category-button"}
            onClick={() => this.context.handleCategoryMenu()}
          >
            <b>{t("menu.award.category")}</b>
            <Icon icon={"arrow"} />
          </div>
        )}
        <div
          className={`reward-categories__overlay ${
            this.context.isCategoryMenuOpen
              ? "reward-categories__overlay--visible"
              : ""
          }`}
        />
        <div
          className={`reward-categories ${
            this.context.isCategoryMenuOpen ? "reward-categories--visible" : ""
          }`}
        >
          <div className={"reward-categories__container"}>
            <div
              className={`reward-categories__left--below ${
                this.context.isSubCategoryMenuOpen
                  ? "reward-categories__left--below--visible"
                  : ""
              }`}
            >
              <span
                className={"reward-categories__left--below--back"}
                onClick={() => this.context.handleSubCategoryMenu(null, null)}
              >
                <Icon icon={"arrow"} />
              </span>
              <h2>{t("menu.sub.categories")}</h2>
              <div className={"reward-categories__subcategories"}>
                {this.context.isSubCategoryMenuOpen &&
                  this.getSubCategoryMenu()}
              </div>
            </div>
            <div className={"reward-categories__left"}>
              <h2>{t("menu.main.categories")}</h2>
              <div className={"reward-categories__categories"}>
                {this.state.categories &&
                  this.state.categories.map((c) => {
                    return (
                      <Button
                        type={"primary"}
                        text={renderTitle(c)}
                        key={c.id}
                        textColor={"white"}
                        onClick={() =>
                          this.context.handleSubCategoryMenu(
                            c.id,
                            c.children
                          )
                        }
                      />
                    );
                  })}
              </div>
            </div>
            {!this.state.isLoggedOut && (
              <>
                {this.context.project.shop_highlights_enabled && (
                  <div className={"reward-categories__right"}>
                    <span className={"reward-categories__header"}>
                      <h3>{t("menu.highlights")}</h3>
                    </span>
                    <div className={"reward-categories__highlights"}>
                      {this.state.highlights.map((elem) => {
                        return (
                          <span
                            key={elem.id}
                            onClick={() =>
                              this.handleCategoryLink(
                                `/${window.currentLang}/product/` + elem.id
                              )
                            }
                          >
                            <Image
                              type={"default"}
                              image={getProductImage(elem.images, false)}
                            />
                          </span>
                        );
                      })}
                      <span
                        onClick={() =>
                          this.handleCategoryLink(
                            `/${window.currentLang}/shop/`
                          )
                        }
                      >
                        <div className={"reward-categories__highlights-all"}>
                          <Icon icon={"gift-box"} />
                          <h4
                            className={
                              "reward-categories__highlights-all-label"
                            }
                          >
                            {t("menu.all.novelties")}
                          </h4>
                        </div>
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

Navbar.propTypes = {
  /** none is transparent **/
  bgColor: PropTypes.oneOf(["black", "white", "grey"]),
  isScrolling: PropTypes.bool,
};

Navbar.defaultProps = {
  isScrolling: false,
  isShopPage: false,
  bgColor: "black",
};

Navbar.contextType = AppContext;

export default withRouter(Navbar);
