import React from 'react'
import PropTypes from 'prop-types'

const Icon = props => (
  <span className={`icon icon--${props.icon} ${props.additionalClass ? props.additionalClass : ""}`} aria-hidden="true">
    <span className="icon__inner">
      <svg role="img">
        <use xlinkHref={`#icon--${props.icon}`} />
      </svg>
    </span>
  </span>
)

Icon.propTypes = {
  icon: PropTypes.string,
  additionalClass: PropTypes.string,
};
Icon.defaultProps = {
  icon: 'send'
};

export default Icon
