import React from "react";
import t from "../../../helpers/t9n";

const Footer = props => (
  <div className="footer">
    <span className="footer__links">
      <a href={`/${window.currentLang}/compliance-tool`}>{t("menu.home")}</a>
      <a href={`/${window.currentLang}/compliance-tool/pub/conditions`}>{t("menu.conditions.participation")}</a>
      <a href={`/${window.currentLang}/compliance-tool/pub/privacy`}>{t("login.data.protection")}</a>
      <a href={`/${window.currentLang}/compliance-tool/imprint`}>{t("login.imprint")}</a>
    </span>
  </div>
);
export default Footer;
