import React, { Component } from "react";

import Content from "../Components/Content";
import Header from "../Components/Header";
import Stepper from "../Components/Stepper";

import { getTextForKey } from "../../../data/ct-api";
import t from "../../../helpers/t9n";
import { AppContext } from "../../../data/ContextProvider";

class Rules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  async componentDidMount() {
    try {
      const pageText = await getTextForKey("rules")

      this.setState({
        pageText: pageText,
        loading: false,
      })
    } catch (error) {
      this.setState({
        pageText: "",
        loading: false,
      })
      console.log(error)
    }
  }

  render() {
    if (this.state.loading) return t("global.loading");

    const nextButton = (!this.props.footer_mode) ? "Weiter zu den Teilnamebedingungen" : null
    const prevButton = (!this.props.footer_mode) ? "Zurück" : null

    return (
      <div className="compl-tool">

        <Header headline="" />

        {!this.props.footer_mode &&
          <>
            <Stepper activeStep="2" />
            <div className="spacer"></div>
          </>
        }

        {this.props.footer_mode &&
          <div className="spacer"></div>
        }

        <Content
          title={this.state.pageText.headline}
          text={this.state.pageText.body}
          nextButton={nextButton}
          nextButtonPath={`/${window.currentLang}/compliance-tool/conditions`}
          prevButton={prevButton}
          prevButtonPath={`/${window.currentLang}/compliance-tool/welcome`}
          showCheckbox={false}
        />
      </div>
    )
  }
}

Rules.contextType = AppContext;
export default Rules;
