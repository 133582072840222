import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/element/header-section/HeaderSection";
import Cart from "./Cart/Cart";
import t from "../helpers/t9n";

const CartPage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <Header heading={t("global.your.cart")} hasImage={false} />
      <Cart lang={lang} />
    </>
  )
}

export default CartPage;
