// APP_ID NAME Mapping
export const APP_SHOP = 1
export const APP_JACKPOT = 2
export const APP_HEROSPLACE = 3
export const APP_SALESPUSH = 4
export const APP_SALESATTACK = 5
export const APP_NEUKUNDENJAEGER = 6
export const APP_CASINO = 9
export const APP_SCHAFFEICH = 10
export const APP_SHOPCLUB = 11
export const APP_TEAMTROPHY = 15
export const APP_SALESTRIATHLON = 16
export const APP_GLORIAREWARDS = 17
export const APP_SALESMARATHON = 18

// CONTENT_ID NAME Mapping
export const CONTENT_START_SEITE = 0
export const CONTENT_INHALTS_SEITE = 1
export const CONTENT_IMPRESSUM = 20
export const CONTENT_AGB = 21
export const CONTENT_TEILNAHMEBEDINGUNGEN = 22
export const CONTENT_LIEFERBEDINGUNGEN = 23
export const CONTENT_DATENSCHUTZ = 24
export const CONTENT_SPIELREGELN = 33
export const CONTENT_FOOTER_LINKBOXES = 40
export const CONTENT_LOGIN = 41
export const CONTENT_WELLCOME_HEADLINE = "wellcome_headline";
export const CONTENT_WELLCOME_INTRO_TEXT = "wellcome_intro_text";
export const HEADLINE_SHOP = "shop_headline";
export const HEADLINE_SHOP_SUBHEADLINE = "shop_subheadline";
export const HEADLINE_NEWS = "news_headline";
export const HEADLINE_APPLICATION = "news_application";


export const SHOP_HIGHLIGHTS = 1


