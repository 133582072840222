import React, { Component } from 'react'

import Selectfield from "../../../components/atom/selectfield/Selectfield";
import Textfield from "../../../components/atom/textfield/Textfield";
import Button from "../../../components/atom/button/Button";
import Icon from '../../../components/atom/icons/Icon';

import { createCtUser } from "../../../data/ct-api";
import { AppContext } from "../../../data/ContextProvider";

export default class RegisterForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      showSuccess:false,
      showError: false,
      errors: [],
      user: {salutation: "herr"}
    }
  }

  handleChange = (e) => {
    const user = this.state.user
    if (e.target) {
      user[e.target.name] = e.target.value
      this.setState({ user });
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    try {
      await createCtUser(this.state.user)
      this.setState({ showSuccess: true, showError: false}, ()=>{
        this.props.onSuccess()
        this.myFormRef.reset()
        this.userReset()
      })
    } catch (error) {
      this.setState({ errors: [...error.errors], showError: true })
    }
  }

  userReset = () => {
    const user = {}
    user.salutation = "Herr"
    user.company = this.context.user.company
    user.company_number = this.context.user.company_number
    user.region = this.context.user.region
    user.area = this.context.user.area
    user.department = this.context.user.department
    user.group = (this.props.isCEO) ? "Geschäftsführung" : "Mitarbeitende"
    user.is_ceo = (this.props.isCEO) ? 1 : 0

    this.setState({ user })
  }

  async componentDidMount() {
    this.userReset()
  }

  render() {

    return (
      <div className="compl__user__container__form">

        {this.state.showSuccess &&
          <div className="success-message">
            <h5>Mitarbeitender wurde erfolgreich registriert!</h5>
            <p>Der Mitarbeitende wurde erfolgreich der Liste hinzugefügt.</p>
          </div>
        }

        {this.state.showError &&
          <div className="error-message">
            <h5>Ein Fehler ist aufgetreten.</h5>
            {this.state.errors.map((error, index) => {
              return <p key={index}>{error}</p>
            })}
          </div>
        }

        <form name="registration-form" onSubmit={(e) => this.onSubmit(e)} id="register-form" ref={(el) => this.myFormRef = el}>
          <div className="profile__personal-data">
            <div className="login__columns--wrapper login__columns--wrapper--step2">

              <span className="button-close" onClick={this.props.onClose}>
                <Icon icon="close" />
              </span>

              <div className="login__row">
                <div className="login__col">
                  <Selectfield
                    label="Anrede"
                    options={{
                      'Herr': "Herr",
                      'Frau': "Frau",
                      'Dr': "Dr",
                    }}
                    name="salutation"
                    isRequired={true}
                    defaultValue={(this.state.user.salutation) ? this.state.user.salutation : "Herr"}
                    onSelect={(e) => this.handleChange(e)}
                    placeholder="Anrede"
                  />
                </div>
              </div>

              <div className="login__row">
                <div className="login__col">
                  <Textfield
                    label="Vorname *"
                    placeholder="Max"
                    value={this.state.user.first_name}
                    isRequired={true}
                    name="first_name"
                    handleChange={(e) => this.handleChange(e)}
                  />
                </div>

                <div className="login__col">
                  <Textfield
                    label="Nachname *"
                    isRequired={true}
                    placeholder="Mustermann"
                    name="last_name"
                    defaultValue={this.state.user.last_name}
                    handleChange={(e) => this.handleChange(e)}
                  />
                </div>
              </div>

              <div className="login__row">
                <div className="login__col">
                    <Textfield
                      label="E-Mail Adresse *"
                      placeholder="m.mustermann@muster.de"
                      name="email"
                      inputType="email"
                      isRequired={true}
                      value={this.state.user.email}
                      handleChange={(e) => this.handleChange(e)}
                    />
                </div>

                <div className="login__col">
                  <Textfield
                      label="Rolle/Position"
                      name="group"
                      value={this.state.user.group}
                      isDisabled={true}
                    />
                </div>
              </div>

              <div className="login__row">
                <div className="login__col">
                  <Textfield
                    label="Firmen-Nr"
                    placeholder=""
                    value={this.state.user.company_number}
                    isRequired={true}
                    isDisabled={true}
                    name="company_number"
                  />
                </div>

                <div className="login__col">
                  <Textfield
                    label="Autohaus"
                    isRequired={true}
                    isDisabled={true}
                    placeholder=""
                    name="company"
                    value={this.state.user.company}
                  />
                </div>
              </div>
            </div>
          </div>
          <span className="profile-header__actions">
          {/*
          <Button
              type="secondary"
              text="Weitere Verkäufer anlegen"
              textColor="black"
          />*/}

            <Button
              type="primary"
              text="Daten speichern"
              textColor="white"
            />
          </span>
        </form>
      </div>
    )
  }
}
RegisterForm.contextType = AppContext;
