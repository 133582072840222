import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Textfield from "../../../components/atom/textfield/Textfield";
import Button from "../../../components/atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import Selectfield from "../../../components/atom/selectfield/Selectfield";
import TextfieldGroup from "../../../components/atom/textfield-group/TextfieldGroup";
import t from "../../../helpers/t9n";

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  render() {

    const user = this.props.data

    return (
      <div className="login__container--right">
          <div className="login__columns">
            <form name="registration-form" onSubmit={(e) => this.props.submitFunc(e, 2)}>
              <h2>{t("login.userdata.headline")}</h2>
              <div className="login__columns--wrapper login__columns--wrapper--step2">

                <div className="login__row">
                  <div className="login__col">
                    <Textfield
                      label={`${t("app.company")}*`}
                      placeholder="Firma XY"
                      name="company"
                      value={user.company}
                      isRequired={true}
                      isDisabled={true}
                      handleChange={(e) => this.props.handleChange(e)}
                    />
                  </div>
                  <div className="login__col">
                    <Selectfield
                      label={t("app.salutation")}
                      options={{
                        'herr': "Herr",
                        'frau': "Frau",
                      }}
                      name="salutation"
                      isRequired={true}
                      placeholder={t("app.salutation")}
                      defaultValue={user.salutation.toLowerCase()}
                      onSelect={(e) => this.props.handleChange(e)}
                    />
                  </div>
                </div>
                <div className="login__row">
                  <div className="login__col">
                    <Textfield
                      label={`${t("app.firstname")}*`}
                      placeholder="Max"
                      value={user.first_name}
                      isDisabled={true}
                      isRequired={true}
                      name="first_name"
                      handleChange={(e) => this.props.handleChange(e)}
                    />
                  </div>
                  <div className="login__col">
                    <Textfield
                      label={`${t("app.lastname")}*`}
                      placeholder="Mustermann"
                      value={user.last_name}
                      isRequired={true}
                      isDisabled={true}
                      name="last_name"
                      handleChange={(e) => this.props.handleChange(e)}
                      />
                  </div>
                </div>
                <div className="login__row">
                  <div className="login__col">
                    <Textfield
                      label={`${t("app.street")}*`}
                      placeholder="Musterstraße"
                      name="street"
                      value={user.street}
                      isRequired={true}
                      handleChange={(e) => this.props.handleChange(e)} />
                  </div>
                  <div className="login__col">
                    <TextfieldGroup>
                      <Textfield
                        label={t("app.plz")}
                        placeholder="12345"
                        name="zip"
                        isRequired={true}
                        value={user.zip}
                        handleChange={(e) => this.props.handleChange(e)} />
                      <Textfield
                        label={t("app.city")}
                        placeholder="Ort"
                        name="city"
                        value={user.city}
                        isRequired={true}
                        handleChange={(e) => this.props.handleChange(e)} />
                    </TextfieldGroup>
                  </div>
                </div>
                <div className="login__row">
                  <div className="login__col">
                    <Textfield
                      label={t("app.birthday")}
                      placeholder="01.03.1960"
                      handleChange={(e) => this.props.handleChange(e)}
                      value={user.dob}
                      name="dob"
                      inputType="date"
                    />
                  </div>
                  <div className="login__col">
                    <Textfield
                      label={t("app.email")}
                      placeholder="m.mustermann@muster.de"
                      handleChange={(e) => this.props.handleChange(e)}
                      isRequired={true}
                      value={user.email}
                      isDisabled={true}
                      name={"email"}
                      />
                  </div>
                </div>
                <div className="login__row">
                  <div className="login__col">
                    <Textfield
                      label={t("app.phone")}
                      placeholder="1234 567890"
                      handleChange={(e) => this.props.handleChange(e)}
                      name={"phone"}
                      value={user.phone}
                    />
                  </div>
                </div>
                <div className="login__row">
                  <div className="login__col">
                    <Textfield
                      label={`${t("app.new.password_short")}*`}
                      handleChange={(e) => this.props.handlePasswordChange(e)}
                      inputType="password"
                      isRequired={true}
                      name={"password"}
                    />
                  </div>
                  <div className="login__col">
                    <Textfield
                      label={`${t("app.new.password.repeat")}*`}
                      handleChange={(e) => this.props.handlePasswordChange(e)}
                      inputType="password"
                      isRequired={true}
                      name="password_repeat"
                    />
                  </div>
                </div>

                { this.props.error ?
                  <>
                    <div className={"login__row"}>
                      <p className={"login__error-text"}>
                          {this.props.error}
                      </p>
                    </div>
                  </>
                  : ""
                }

                <div className={"login__row"}>
                  <div className="login__col">
                    <p style={{fontSize: "12px"}}>{t("global.mandatory.fields")}</p>
                  </div>
                  <div className={"login__col login__col--action"}>
                  <Button type={"primary"} textColor={"white"} text={t("global.next")} />
                  </div>
                </div>
              </div>

              <span className={"login__links"}>
                <a href={`/${window.currentLang}`}>{t("menu.home")}</a>
                <a href={`/${window.currentLang}/tos`}>{t("menu.conditions.participation")}</a>
                <a href={`/${window.currentLang}/privacy`}>{t("login.data.protection")}</a>
                <a href={`/${window.currentLang}/imprint`}>{t("login.imprint")}</a>
                <a href={`/${window.currentLang}/contact`}>{t("login.contact")}</a>
              </span>
            </form>
          </div>
        </div>
    );
  }
}

Step2.contextType = AppContext;

export default withRouter(Step2);
