import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/element/header-section/HeaderSection";
import t from "../helpers/t9n";
import ContactPublic from "./Contact/ContactPublic";

const ContactPagePublic = () => {
  let { lang } = useParams();

  return (
    <>
      <Header heading={t("menu.contact")} hasImage={true} isRed={true} />
      <ContactPublic lang={lang} />
    </>
  )
}

export default ContactPagePublic;
