import React, { Component } from "react";
import PropTypes from 'prop-types'
import Counter from "../../atom/counter/Counter";
import Icon from "../../atom/icons/Icon";

class CounterSpawner extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isAnimationStarted: false,
      animStartTime: 2
    }

    this.timerHandle = null
    this.seconds = 0
    this.jackpotCountdown = 1
  }

  createTimer() {
    return setInterval(() => this.countdown(), 100);
  }

  countdown() {
    if (this.seconds >= this.state.animStartTime) {
      this.seconds = 1;
      clearInterval(this.seconds);
      this.setState({
        isAnimationStarted: true
      })
    } else {
      this.seconds++
    }
  }

  componentDidMount() {
    this.jackpotCountdown = this.createTimer()
  }

  resetAndRestart() {
    clearInterval(this.jackpotCountdown)
    this.timerHandle = setTimeout(() =>
      this.setState({
        isAnimationStarted: !this.state.isAnimationStarted
      }), 200)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.amount !== this.props.amount) {
      this.resetAndRestart()
    }
  }

  componentWillUnmount() {
    clearInterval(this.jackpotCountdown)
    this.timerHandle && clearTimeout(this.timerHandle)
  }

  createCounters(amount, type) {
    const counterArray = [];
    const digits = amount.toString().split('');
    const realDigits = digits.map(Number);
    const realLength = realDigits.length
    const fillDigits = this.props.digits

    const flippedDigits = realDigits.reverse();

    if (fillDigits > 0 && fillDigits > realLength) {
      const lefOverDigits = fillDigits - realLength

      for (let i = 1; i <= lefOverDigits; i++) {
        flippedDigits.push(null)
      }
    }

    flippedDigits.forEach((num, i) => {
      if ((flippedDigits.length > 3 && i > 0) && i % 3 === 0) {
        counterArray.push(<Icon icon={"counter-dot"} key={"icon_" + i} />)
      }
      counterArray.push(<Counter isAnimationStarted={this.state.isAnimationStarted} type={type} value={num}
                                 key={"counter_" + num + i} />)
    });

    return counterArray.reverse();
  }

  render() {
    return (
      <div
        className={`counter-spawner ${this.props.isSmall ? "counter-spawner--small" : ""} counter--${this.props.type}`}>

      {this.createCounters(this.props.amount, this.props.type)}

    </div>
    )
  }
};

CounterSpawner.propTypes = {
  type: PropTypes.oneOf(["standard", "highlight", "light"]),
  isSmall: PropTypes.bool,
  digits: PropTypes.number
  // amount: PropTypes.number
};
CounterSpawner.defaultProps = {
  type: "standard",
  isSmall: false,
  amount: 0,
  digits: 0, //Default for no filling
};

export default CounterSpawner;
