import React, { Component } from "react";
import { AppContext } from "../../data/ContextProvider";

import { CONTENT_TEILNAHMEBEDINGUNGEN } from "../../data/constants";
import { getContentPage } from "../../data/api";
import t from "../../helpers/t9n";
import { bodyForLang } from "../../lib/lib";

class Tos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      headline: "",
      body: "",
      loading: true
    }
  }

  async componentDidMount() {

    let headline = ""
    let body = ""

    const page = await getContentPage(CONTENT_TEILNAHMEBEDINGUNGEN, this.context.getProjectData().id, this.context.getProjectData().app_id)

    page.text.forEach(text => {
      if (text.key === 'p22_header_content_headline') {
        headline = text
      }
      if (text.key === 'p22_upper_content_text') {
        body = text
      }
    })

    this.setState({
      headline: headline,
      body: body,
      loading: false
    })
  }

  render() {
    if (this.loading) {
      return t("global.loading")
    }

    return (
      <>
        <div className={"content privacy tos"}>
          <h1>{bodyForLang(this.state.headline, window.currentLang)}</h1>

          <p
            dangerouslySetInnerHTML={{
              __html: bodyForLang(this.state.body, window.currentLang)
            }}>
          </p>

        </div>
      </>
    )
  }
}

Tos.contextType = AppContext
export default Tos
