import React, { Component } from "react";
import PropTypes from 'prop-types'

class ProgressCircle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expired: false,
      percent: 0,
      startNr: 0,
      endNr: 10
    }
    this.timerHandle = null
  }

  componentDidMount() {
    let p = (!this.props.isCountDown) ? Math.floor((this.props.value / this.props.maxValue) * 100) : 100 - Math.floor((this.props.value / this.props.maxValue) * 100)
    let expired = false
    let endNr = this.props.value !== null ? this.props.value : 10

    if (this.props.expired) {
      expired = this.props.expired
      p = 100
      endNr = 0
    }

    this.setState({
      percent: p,
      expired,
      endNr
    }, () => {
      this.countUp(this.state.startNr)
    })
  }

  componentWillUnmount() {
    this.timerHandle && clearTimeout(this.timerHandle)
  }

  countUp = (count) => {

    this.value.innerHTML = count;

    if (count < this.state.endNr && this.state.endNr !== 0) {
      this.timerHandle = setTimeout(() => this.countUp(count + 1), 60)
    }
  }

  render() {
    const value = this.props.expired ? "0" : this.props.value
    return (
      <div className={`progress-circle c100 p${this.state.percent}`}>
          <span ref={value => (this.value = value)}>
            {value}
          </span>
          <span>{this.props.text}</span>
          <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
          </div>
      </div>
    )
  }
}

ProgressCircle.propTypes = {
  value: PropTypes.number,
  maxValue: PropTypes.number,
  isCountDown: PropTypes.bool,
  text: PropTypes.string,
};
ProgressCircle.defaultProps = {
  value: 0,
  maxValue: 10,
  isCountDown: false,
  text: "",
};

export default ProgressCircle;
