import React, { useContext } from "react";

import "../sass/styles.sass";
import Navbar from "../components/element/navbar/Navbar";
import Footer from "../components/element/footer/Footer";
import { AppContext } from "../data/ContextProvider";
import CardSlider from "../components/module/card-slider/CardSlider";
import t from "../helpers/t9n";

function ShopLayout({ children }) {

  const context = useContext(AppContext);

  return (
    <React.Fragment>
      <Navbar bgColor={"black"} isScrolling={true} />

      <div className={`container container--cart ${context.getProjectData().global_jackpot_active ? "container--jackpot-counter" : ""}`}>

        {children}

      </div>
      <div className={"section__card-slider"}>
        <CardSlider sliderLabel={t("app.award.news")} />
      </div>
      <div className={`popup-cart__overlay ${context.isPopupCartVisible ? "popup-cart__overlay--visible" : ""}`}>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ShopLayout;
