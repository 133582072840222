import {
  login,
  logout,
  reloginUser,
  impersonate
} from "../data/api";

import {
  managerLogout, reloginManager
} from "../data/ct-api";

import { addZeroToNumber } from '../helpers/utils'

const BUM_JWT_TOKEN = 'bum_token'
const BUM_UID = 'bum_uid'

export async function tryToLogInUser(email, password, project_id=0) {
  try {
    const response = await login(email, password, project_id)

    saveToken(response.authorization)
    saveUserData(response.user)

    return response.user

  } catch (error) {
    throw (error)
  }
}

export async function tryToImpersonateUser(token) {
  try {
    const response = await impersonate(token)

    saveToken(response.authorization)
    saveUserData(response.user)

    return response.user
  } catch (error) {
    throw (error)
  }
}

export async function relogintUser(token) {
  try {
    const response = await reloginUser(token)
    saveUserData(response.user)
    return response.user
  } catch (error) {
    throw (error)
  }
}

export async function logoutUser() {
  await logout()
  localStorage.clear()
  window.location = `${window.location.origin}/${window.currentLang}/login`
}

export async function logoutManager() {
  await managerLogout()
  localStorage.clear()
  window.location = `${window.location.origin}/${window.currentLang}/compliance-tool/login`
}

export async function checkToken() {
  try {
    const token = getToken()
    if (!token) return false //kein Token => Login-Maske
    //token liegt vor, User nicht eingeloggt => versuchen einzuloggen
    return await relogintUser(token)
  } catch (error) {
    throw (error)
  }
}

export async function checkManagerToken() {
  try {
    const token = getToken()
    if (!token) return false //kein Token => Login-Maske
    //token liegt vor, User nicht eingeloggt => versuchen einzuloggen
    return await reloginManager(token)
  } catch (error) {
    throw (error)
  }
}


export function getToken() {
  return localStorage.getItem(BUM_JWT_TOKEN);
}


export function getUserId() {
  return localStorage.getItem(BUM_UID);
}

export function getUserAvatar() {
  const avatar = localStorage.getItem('avatar_path');
  if (avatar === "null" || avatar === "" || avatar === "undefined")
    return null

  return avatar
}

export function saveToken(token) {
  localStorage.setItem(BUM_JWT_TOKEN, token);
}

function saveUserData(user) {
  localStorage.setItem(BUM_UID, user.id);
  localStorage.setItem('avatar_path', user.avatar_path);
  localStorage.setItem('app_id', user.project.app_id);
  localStorage.setItem('project_id', user.project_id);
  localStorage.setItem('company', user.company);
  localStorage.setItem('name', user.first_name + " " + user.last_name);
  localStorage.setItem('points', (user.points) ? user.points : 0);
}

export function saveManagerData(user) {
  localStorage.setItem(BUM_UID, user.id);
  localStorage.setItem('project_id', user.project_id);
  localStorage.setItem('company', user.company);
  localStorage.setItem('name', user.first_name + " " + user.last_name);
}

export function dateStringToTimestamp(dateString) {
  const timestamp = Date.parse(dateString)
  return unixTimestamp(timestamp)
}

export function unixTimestamp(DateObject) {
  return Math.round(DateObject.getTime()/1000)
}


export function formatDate(dateString, time = true) {
  const timestamp = Date.parse(dateString)
  const date = new Date(timestamp)

  let hours, minutes, seconds

  if (date) {
    const yyyy = date.getFullYear().toString()
    const mm = addZeroToNumber((date.getMonth() + 1).toString())
    const dd = addZeroToNumber(date.getDate().toString())

    if (time) {
      hours = addZeroToNumber(date.getHours().toString())
      minutes = addZeroToNumber(date.getMinutes().toString())
      seconds = addZeroToNumber(date.getSeconds().toString())
      return dd + '.' + mm + '.' + yyyy + ' ' + hours + ':' + minutes + ':' + seconds
    }

    return dd + '.' + mm + '.' + yyyy + ' '
  }
  return ''
}

export function formatDateEn(dateString, time = true) {
  const timestamp = Date.parse(dateString)
  const date = new Date(timestamp)

  let hours, minutes, seconds

  if (date) {
    const yyyy = date.getFullYear().toString()
    const mm = addZeroToNumber((date.getMonth() + 1).toString())
    const dd = addZeroToNumber(date.getDate().toString())

    if (time) {
      hours = addZeroToNumber(date.getHours().toString())
      minutes = addZeroToNumber(date.getMinutes().toString())
      seconds = addZeroToNumber(date.getSeconds().toString())
      return mm + '/' + dd + '/' + yyyy + ' ' + hours + ':' + minutes + ':' + seconds
    }

    return mm + '/' + dd + '/' + yyyy + ' '
  }
  return ''
}

/**
 *
 * @param {Object} Date
 * @returns {String} YYYY-MM-DD
 */
export function formatDateToIsoDate(d) {
  return `${d.getFullYear()}-${addZeroToNumber((d.getMonth() + 1).toString())}-${addZeroToNumber(d.getDate().toString())}`
}

export function dangerousHTMLText(html) {
  if (html) {
    return html.replace(/(?:\r\n|\r|\n)/g, '<br />')
  }
  return html

}

/**
 *
 * @param {Object} hash of key value pairs
 * @return {String} query-string "key=value&..."
 */
export function toQueryString(hash) {
  let params = ""
  Object.entries(hash).map((val) => {
    params += `${val[0]}=${encodeURIComponent(val[1])}&`
    return params
  })
  return params;
}

export function checkPasswordPolicy(password) {

  if (password.length < 8) {
    return false
  }

  if (!password.match(/[A-Z]+/)) {
    return false
  }

  if (!password.match(/[0-9]+/)) {
    return false
  }

  // if (!password.match(/[$@#&!]+/)) {
  //   return false
  // }

  return true
}

export function roundTo(n, digits=0) {
  const multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  const tmp = (Math.round(n) / multiplicator);
  return +(tmp.toFixed(digits));
}

export function shortText(string, n) {
  if (!string || string.length === 0 ) {
    return string
  }
  return string.length > n ? string.substr(0, n - 1) + '...' : string;
}

export function labelForLang(lang) {
  let label = "body"
  if (lang !== 'de') {
    label = "body_" + lang
  }
  return label
}

export function bodyForLang(body, lang) {
  const label = labelForLang(lang)

  if (body[label]) {
    return body[label]
  }
  return ""
}
