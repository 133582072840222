import React from 'react';
import Icon from "../icons/Icon";

const PaginationFixed = (props) => {

  let limit = 0

  if (props.startWithOne) {
    limit = 1
  }

  return (
    <nav>
      <ul className={`pagination pagination--${props.color}`}>
        {(props.currentPage > limit) &&
          <span
            className={`pagination__action pagination__action--left ${(props.currentPage > limit) ? "" : "pagination__action--hidden"}`}
            onClick={() => props.paginate(props.currentPage-1)}>
            <Icon icon={"arrow"} />
          </span>
        }

        <span
          className={`pagination__action pagination__action--right ${props.nextPage ? "" : "pagination__action--hidden"}`}
          onClick={() => props.paginate(props.currentPage+1)}>
          <Icon icon={"arrow"} />
        </span>
      </ul>
    </nav>
  );
};

PaginationFixed.defaultProps = {
  color: "black",
};

export default PaginationFixed;