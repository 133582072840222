import React, { Component } from "react";
import PropTypes from "prop-types";
import TinySlider from "tiny-slider-react";
import Button from "../../atom/button/Button";
import { Link } from "react-router-dom";
import Image from "../../atom/image/Image";

class HeroSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [
        {
          title: "Genießen Sie den perfekten Sound",
          path: `/${window.currentLang}/shop`,
          image: process.env.PUBLIC_URL + "/images/BDM-placeholder-02.jpg",
        },
        {
          title: "Genießen Sie den perfekten Sound",
          path: `/${window.currentLang}/shop`,
          image: process.env.PUBLIC_URL + "/images/BDM-placeholder-01.jpg",
        },
        {
          title: "Genießen Sie den perfekten Sound",
          path: `/${window.currentLang}/shop`,
          image: process.env.PUBLIC_URL + "/images/BDM-placeholder-03.jpg",
        },

      ],
      settings: {
        loop: true,
        lazyload: false,
        nav: true,
        gutter: 12,
        controls: false,
        slideBy: 1,
        autoHeight: false,
        arrowKeys: false,
        startIndex: 0,
        items: 1,
        autoplay: true
      },
      loading: true,
    };
  }

  onGoTo = dir => {
    this.ts.slider.goTo(dir);
  };

  render() {
    let { cards } = this.state;

    return (
      <div
        className={`hero-slider__wrapper`}>
        <div className="hero-slider__container">
          <div className="hero-slider">
            <TinySlider
              settings={this.state.settings}
              ref={ts => (this.ts = ts)}
            >
              {cards ? cards.map((elem, i) => (
                <div key={i} style={{ position: "relative" }}>
                  <div className={"hero-slider__slide"}>
                    <Image image={elem.image} alt={"slider_image"} type={"default"} />
                    <span className={"hero-slider__slide-heading"}>
                      {elem.title}
                    </span>
                    <span className="hero-slider__slide-actions">
                      <Link to={elem.path}>
                        <Button type={"secondary"} textColor={"black"} backgroundColor={"bg-white"}
                                text={"Infos anzeigen"} />
                      </Link>
                    </span>
                  </div>
                </div>
              )) : <div></div>}
            </TinySlider>
          </div>
        </div>
      </div>
    );
  }
}

HeroSlider.propTypes = {
  sliderLabel: PropTypes.string,
};

HeroSlider.defaultProps = {
  sliderLabel: "Placeholder Label"
};

export default HeroSlider;
