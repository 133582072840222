import React, { Component } from 'react'
import Button from '../../components/atom/button/Button';
import UncontrolledLottie from '../../components/atom/lottie/UncontrolledLottie';
import error from "../../assets/lotties/error/icon--anim-error-default";
import { AppContext } from '../../data/ContextProvider';

class ModalCheckoutBox extends Component {
  render() {
    return (
      <>
        <div className={`modal-information modal-information--error`}>
          <div className={"modal-information__upper"}>
            <div className={"modal-information__center"}>
              <UncontrolledLottie animationData={error} />
            </div>
            <span className={"modal-information__row"}>
              <h3 dangerouslySetInnerHTML={{__html: this.context.project.opt_in_checkout_box_text}} />
            </span>
            <Button 
              type={"primary"} 
              onClick={() => this.props.handleAction()}
              text={"Okay"}
              textColor={"white"} 
            />
          </div>
        </div>

        <div className={`main-menu__overlay main-menu__overlay--visible`}></div>
      </>
    )
  }
}

ModalCheckoutBox.contextType = AppContext
export default ModalCheckoutBox;
