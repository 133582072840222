import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import { AppContext } from "../../../data/ContextProvider";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";
import error from "../../../assets/lotties/error/icon--anim-error-default";
import Button from "../../atom/button/Button";
import { withRouter } from "react-router-dom";
import t from "../../../helpers/t9n";

class ModalInformation extends Component {

  constructor(props) {
    super(props)
    this.state = {}

    this.countdownTimer = null
    this.currentSeconds = 0
    this.timeToKill = 3
  }

  componentDidMount() {
    // this.countdownTimer = this.createTimer()
  }

  componentWillUnmount() {
    // clearInterval(this.countdownTimer);
    // this.countdownTimer = null
  }

  createTimer = () => {
    return setInterval(() => this.countdown(), 1000);
  }

  countdown = () => {
    if (this.currentSeconds >= this.timeToKill) {
      this.currentSeconds = 1;
      clearInterval(this.countdownTimer);
      this.context.toggleModalInfo(false);
    } else {
      this.currentSeconds++
    }
  }

  render() {
    return (
      <>
        <div className={`modal-information ${this.context.hasModalInfoError ? "modal-information--error" : ""}`}>
          <div className={"modal-information__upper"}>
            <span className={"modal-information__close"} onClick={() => this.context.toggleModalInfo(false)}>
              <Icon icon={"close"} />
            </span>
            <div className={"modal-information__center"}>
              <UncontrolledLottie animationData={this.context.hasModalInfoError ? error : confetti} />
            </div>
            <span className={"modal-information__row"}>
              {this.context.modalInfoMessage ?
                <h3>{this.context.modalInfoMessage}</h3>
                :
                <h3>{this.context.hasModalInfoError ? "Ups, es ist etwas schief gelaufen." : "Ihre Daten wurden erfolgreich gespeichert."}</h3>
              }
              {(this.context.hasModalInfoError && !this.context.modalInfoMessage) &&
              <h4>{t("cart.no.orders.error.hint")}</h4>
              }
            </span>
            {this.context.hasModalInfoError &&
            <Button type={"danger"} onClick={() => {
              this.context.toggleModalInfo(false)
              this.props.history.push(`/${window.currentLang}/contact`)
            }
            }
                    text={t("cart.no.orders.button")}
                    textColor={"black"} />
            }
          </div>
        </div>

        <div className={`main-menu__overlay ${this.context.isModalInfoVisible ? "main-menu__overlay--visible" : ""}`}>
        </div>
      </>
    )
  }
}

ModalInformation.contextType = AppContext

export default withRouter(ModalInformation);
