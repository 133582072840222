import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = props => (
  <label className={`checkbox__container ${props.disabled ? 'checkbox__container--disabled' : ''}`}>
    <input id={props.id} className={`checkbox`} type={`checkbox`} disabled={props.disabled}
           defaultChecked={props.selected}
           value={props.id}
           name={props.name}
           onChange={props.onChange ? props.onChange : null} />
    <span className={`checkbox__checkmark`}></span>
    {props.label}
  </label>
)

Checkbox.propTypes = {
  /** Checked Zustand der Checkbox Möglichkeiten : true/false */
  selected: PropTypes.bool,
  /** default Enabled/Disabled Zustand der Checkbox Möglichkeiten : true/false */
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
Checkbox.defaultProps = {
  disabled: false,
  selected: false
};

export default Checkbox
