import React, { Component } from "react";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";
import { approvePaypalOrder } from "../../../data/api";
import t from "../../../helpers/t9n";

class ModalMorePoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      orderID: false,
    };
  }

  approveOrder = async (data, actions) => {
    let addressID = 0; // default user address

    //ein Hack, mit personnel_number wird Adresse aus dem Useroject von einer zesaetzlichen aus addresses Tabelle unterschieden.
    if (!this.props.selectedAddress.personnel_number) {
      addressID = this.props.selectedAddress.id;
    }

    try {
      const order = await approvePaypalOrder(
        data.orderID,
        this.props.userPoints,
        this.props.cartAmount,
        this.props.delta,
        addressID
      );
      this.props.onCloseOrderTransaction(order.order);
    } catch (error) {
      console.log(error);
    }
  };

  createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              // currency_code: "EUR",
              value: this.props.deltaInCurrency,
            },
          },
        ],
      })
      .then((orderID) => {
        this.setState({ orderID });
        return orderID;
      });
  };

  handleNextStep = () => {
    this.setState({
      step: this.state.step + 1,
    });
  };

  onClose = () => {
    window.location.reload();
  };

  getInfoContent() {
    return (
      <div className="modal-points__lower">
        <span className="modal-points__row center-content">
          <span className="modal-points__missing-label">
            {t("checkout.modal.no.points")}:
          </span>
          <span className="modal-points__missing">
            {this.props.delta} {t("app.points")}
          </span>
        </span>
        <span className="modal-points__row center-content">
          <span className="modal-points__price">
            {this.props.deltaInCurrency} {t("checkout.modal.currency")}
          </span>
        </span>
        <span className="modal-points__row">
          <Button
            text={t("checkout.modal.payment.check")}
            type="secondary"
            textColor="white"
            onClick={() => this.handleNextStep()}
          />
        </span>
      </div>
    );
  }

  getPaymentContent = () => {
    if (this.state.orderApproved) {
      return (
        <div className="modal-points__lower--payment">
          <span className="modal-points__row center-content">
            {t("checkout.modal.transaction.success")}: {this.state.orderID}
          </span>
        </div>
      );
    } else
      return (
        <div className="modal-points__lower--payment">
          <span className="modal-points__row center-content">
            <div className="paypal-buttons-all">
            <PayPalScriptProvider
              options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                currency: t("checkout.modal.currency.long"),
              }}
            >
              <PayPalButtons
                style={{ layout: "vertical" }}
                createOrder={this.createOrder}
                onApprove={(data, actions) => {
                  this.approveOrder(data, actions);
                }}
                forceReRender={this.props.deltaInCurrency} // changes paypal when state.amount would change
              />
            </PayPalScriptProvider>
            </div>
          </span>
        </div>
      );
  };

  successPopup = () => {
    return (
      <div className="modal-points__lower">
        <span className="modal-points__row">
          <Button
            text={t("checkout.modal.close.button")}
            type="secondary"
            textColor="white"
            onClick={() => this.onClose()}
          />
        </span>
      </div>
    );
  };

  getCurrentContent = (step) => {
    switch (step) {
      case 0:
        return this.getInfoContent();
      case 1:
        return this.getPaymentContent();
      case 2:
        return this.successPopup();
      default:
        return this.getInfoContent();
    }
  };

  render() {
    return (
      <>
        <div className="modal-points">
          <div className="modal-points__upper">
            {this.state.step !== 2 && (
              <span
                className="modal-points__close"
                onClick={() => this.props.onClose()}
              >
                <Icon icon="close" />
              </span>
            )}

            <span className="modal-points__row">
              <span className="modal-points__title center-content">
                <h2>
                  {this.state.step === 0 && t("checkout.modal.step1")}
                  {this.state.step === 1 && t("checkout.modal.step2")}
                  {this.state.step === 2 && t("checkout.modal.step3")}
                </h2>
              </span>
            </span>
          </div>
          {this.getCurrentContent(this.state.step)}
        </div>
        <div className="main-menu__overlay main-menu__overlay--visible"></div>
      </>
    );
  }
}

export default ModalMorePoints;
