import React from "react";
import LanguageSwitcher from "../components/module/language-switcher/LanguageSwitcher";

import "../sass/styles.sass";

function Front({ children }) {
  return (
    <React.Fragment>
      
      <div className={"login__wrapper"}>
        <LanguageSwitcher />
        {children}
      </div>
    
    </React.Fragment>
  );
}

export default Front;
