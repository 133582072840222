import React from "react";
import { Redirect, useParams } from "react-router-dom";
import ProductView from "./Shop/ProductView";

const ProductPage = () => {
  let { lang, id } = useParams();
  return (
    <>
      {id ?
        <ProductView lang={lang} id={id} />
        :
        <Redirect to={`/${window.currentLang}/shop`} />
      }
    </>
  )
}

export default ProductPage;
