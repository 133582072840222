import React, { Component } from "react";
import PropTypes from 'prop-types'

class Counter extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isAnimationStarted !== this.props.isAnimationStarted) {
      const margin = this.props.value * 5;
      this.ct.style.transform = `translateY(-${this.props.value}00%) translateY(-${margin}px)`
    }
  }

  getNumbers() {
    const nums = []
    if (this.props.value === null) {
      nums.push(<span className={"counter__value"} key={"null"} />)
    } else {
      for (let i = 0; i < this.props.value + 1; i++) {
        nums.push(<span className={"counter__value"} key={i}>{i}</span>)
      }
    }
    return nums
  }

  render() {
    return (
      <div className={`counter counter--${this.props.type}`} ref={ct => (this.ct = ct)}>
      {
        this.getNumbers()
      }
    </div>
    );
  }
}

Counter.propTypes = {
  type: PropTypes.oneOf(["standard", "highlight", "light"]),
  value: PropTypes.number
};
Counter.defaultProps = {
  type: "standard",
  value: 0
};

export default Counter;
