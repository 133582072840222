import React from "react";
import t from "../../../helpers/t9n";

const Footer = props => (
  <div className="footer">
    <span className="footer__links">
      <a href={`/${window.currentLang}`}>{t("menu.home")}</a>
      <a href={`/${window.currentLang}/tos`}>{t("menu.conditions.participation")}</a>
      <a href={`/${window.currentLang}/privacy`}>{t("login.data.protection")}</a>
      <a href={`/${window.currentLang}/imprint`}>{t("login.imprint")}</a>
      <a href={`/${window.currentLang}/contact`}>{t("login.contact")}</a>
    </span>
  </div>
);
export default Footer;
