import React, { Component } from "react";
import Icon from "../../../components/atom/icons/Icon";
import SimplePagination from "../../../components/atom/pagination/SimplePagination";

export default class TableSeller extends Component {
  render() {
    return (
      <>
        <div className="table-seller">
          <table className="table">
            <thead>
              <tr>
                <td>Anrede</td>
                <td>Vorname</td>
                <td>Nachname</td>
                <td>Rolle</td>
                <td>E-Mail-Adresse</td>
                <td>Autohaus</td>
                {this.props.actionsEnabled &&
                  <td></td>
                }
              </tr>
            </thead>
            <tbody>
            {this.props.users.map((user, index)=> {
              return <tr key={index}>
                <td>{user.salutation}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.group}</td>
                <td>{user.email}</td>
                <td>{user.company}</td>
                {this.props.actionsEnabled &&
                  <td>
                    <button className="btn btn-icon btn-success" onClick={(e)=>this.props.handleEditUser(user)}>
                      <Icon icon="pencil" />
                    </button>
                    <button className="btn btn-icon btn-danger" onClick={
                    ()=> {
                      if (window.confirm('wirklich deaktivieren?')) {
                        this.props.handleHideUser(user)
                      }
                    }
                    }>
                      <Icon icon={"garbage"} />
                    </button>
                  </td>
                }
              </tr>
            })}
            </tbody>
          </table>

        </div>
        <div className="compl__pagination">
          <SimplePagination
            currentPage={this.props.pager.current_page}
            totalPages={this.props.pager.total_pages}
            paginate={this.props.paginate}
          />
        </div>
      </>
    );
  }
}
