import React, { Component } from "react";
import Tachometer from "../../../components/element/tachometer/Tachometer";

import t from "../../../helpers/t9n";
import Icon from "../../../components/atom/icons/Icon";
import ProgressCircle from "../../../components/element/progress-circle/ProgressCircle";

import { dateDiff, dateExpired, isCurrentMonth, monthBetweenTowDates } from "../../../helpers/utils";

class SalesTriathlonGoals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      startAnimation: true
    };
  }

  animateValue = (elem, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }

      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      if (start === 0 && end === 0) {
        elem.innerHTML = Math.floor(progress * (end - start) + start) + "%";
      } else {
        elem.innerHTML = Math.floor(progress * (start / end) * 100) + "%";
      }
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);

    this.setState({
      startAnimation: false
    })
  }

  animateNeedle = (elem, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      if (Math.floor(progress * (start / end) * 100 * 1.8) > 180) {
        elem.style.transform = `rotate(180deg)`;
      } else {
        elem.style.transform = `rotate(${Math.floor(progress * (start / end) * 100 * 1.8)}deg)`;
      }
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);

    this.setState({
      startAnimation: false
    })
  }

  isInViewport = () => {

    const animationDuration = 2500

    if (this.divRefTop) {
      const top = this.divRefTop.getBoundingClientRect().top;
      if (top <= 800){
        if (this.state.startAnimation) {

          const first_month_achievement  = this.userAchievementForMonth(0)
          const second_month_achievement = this.userAchievementForMonth(1)
          const third_month_achievement  = this.userAchievementForMonth(2)

          const firstMonthVal = document.querySelector('#first-month .salestriathlon-goals__percent h3');
          this.animateValue(firstMonthVal, first_month_achievement, 100, animationDuration);

          const firstMonthLine = document.querySelector('#first-month .tachometer .line');
          this.animateNeedle(firstMonthLine, ((first_month_achievement / 100) * 180), 180, animationDuration);

          const secondMonthVal = document.querySelector('#second-month .salestriathlon-goals__percent h3');
          this.animateValue(secondMonthVal, second_month_achievement, 100, animationDuration);

          const secondMonthLine = document.querySelector('#second-month .tachometer .line');
          this.animateNeedle(secondMonthLine, ((second_month_achievement / 100) * 180), 180, animationDuration);

          const thirdMonthVal = document.querySelector('#third-month .salestriathlon-goals__percent h3');
          this.animateValue(thirdMonthVal, third_month_achievement, 100, animationDuration);

          const thirdMonthLine = document.querySelector('#third-month .tachometer .line');
          this.animateNeedle(thirdMonthLine, ((third_month_achievement / 100) * 180), 180, animationDuration);



          const allMonthsVal = document.querySelector('#all-months .salestriathlon-goals__percent h3');
          this.animateValue(allMonthsVal, this.userTotalAchievement(), 100, animationDuration);

          const allMonthsLine = document.querySelector('#all-months .tachometer .line');
          this.animateNeedle(allMonthsLine, ((this.userTotalAchievement() / 100) * 180), 180, animationDuration);


        }
      }
    }
  }

  userTransactionsForMonth = (monthNumber) => {
    if ( this.props.transactions &&
         this.props.transactions.transactions &&
         this.props.transactions.transactions[monthNumber]) {
      return this.props.transactions.transactions[monthNumber]
    }
    return 0
  }

  userTransactionsSum = () => {
    if ( this.props.transactions && this.props.transactions.transactions_sum) {
      return this.props.transactions.transactions_sum
    }
    return 0
  }

  userTarget = () => {
    if ( this.props.transactions && this.props.transactions.target) {
      return this.props.transactions.target
    }
    return 0
  }

  userTargetPoints = () => {
    if (this.props.transactions && this.props.transactions.total_points) {
      return this.props.transactions.total_points
    }
    return 0
  }

  userAchievementForMonth = (monthNumber) => {
    if ( this.props.transactions &&
        this.props.transactions.target_achievement &&
        this.props.transactions.target_achievement[monthNumber]) {
      return this.props.transactions.target_achievement[monthNumber]
    }
    return 0
  }

  userTotalAchievement = () => {
    if ( this.props.transactions && this.props.transactions.total_target_achievement) {
      return this.props.transactions.total_target_achievement
    }
    return 0
  }

  renderMonthState = (dateString) => {
    if (this.props.start !== null || this.props.end !== null) {
      if (isCurrentMonth(dateString) === -1) {
        return "salestriathlon-goals__single--finished"
      } else if (isCurrentMonth(dateString) === 0) {
        return "salestriathlon-goals__single--current"
      } else {
        return ""
      }
    }
  }

  renderMonthsSum = () => {
    const first_record  = (this.props.ranking_levels && this.props.ranking_levels[0]) ? this.props.ranking_levels[0] : {}
    const second_record = (this.props.ranking_levels && this.props.ranking_levels[1]) ? this.props.ranking_levels[1] : {}
    const third_record  = (this.props.ranking_levels && this.props.ranking_levels[2]) ? this.props.ranking_levels[2] : {}
    const sum = first_record.from + second_record.from + third_record.from

    return sum;
  }

  async componentDidMount() {
    document.addEventListener("scroll", this.isInViewport);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.isInViewport);
  }

  render() {
    const first_record  = (this.props.ranking_levels && this.props.ranking_levels[0]) ? this.props.ranking_levels[0] : {}
    const second_record = (this.props.ranking_levels && this.props.ranking_levels[1]) ? this.props.ranking_levels[1] : {}
    const third_record  = (this.props.ranking_levels && this.props.ranking_levels[2]) ? this.props.ranking_levels[2] : {}

    const months = monthBetweenTowDates(this.props.start, this.props.end)

    const first_month  = (months && months[0]) ? months[0] : ""
    const second_month = (months && months[1]) ? months[1] : ""
    const third_month  = (months && months[2]) ? months[2] : ""

    const firstMonthNotFinished   = (this.userAchievementForMonth(0) < 100) ? "salestriathlon-goals__single--not-accomplished" : "";
    const secondMonthNotFinished  = (this.userAchievementForMonth(1) < 100) ? "salestriathlon-goals__single--not-accomplished" : "";
    const thirdMonthNotFinished   = (this.userAchievementForMonth(3) < 100) ? "salestriathlon-goals__single--not-accomplished" : "";

    const firstMonthAccomplished  = (this.userAchievementForMonth(0) >= 100) ? "salestriathlon-goals__single--accomplished" : "";
    const secondMonthAccomplished = (this.userAchievementForMonth(1) >= 100) ? "salestriathlon-goals__single--accomplished" : "";
    const thirdMonthAccomplished  = (this.userAchievementForMonth(3) >= 100) ? "salestriathlon-goals__single--accomplished" : "";

    const totalAccomplished = this.userTotalAchievement() >= 100 ? "salestriathlon-goals__performance-top--accomplished" : ""

    return (
      <div className="salestriathlon-goals__section">
        {/* Monatsziele */}
        <div className="salestriathlon-goals__content">
          <div className="salestriathlon-goals__inner salestriathlon-goals__inner--blue">
            <h2 className="salestriathlon-goals__heading">{t("app.salestriathlon.month.heading")}</h2>

            <div className="salestriathlon-goals__container">
              <div className={`salestriathlon-goals__single ${this.renderMonthState(first_month)} ${firstMonthNotFinished} ${firstMonthAccomplished}`}>
                <div className="salestriathlon-goals__head">
                  <h3>{t("app.salestriathlon.first.month")}</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  {this.props.currencyMode === "contracts" ?
                    <p>{t("app.salestriathlon.goals.contracts1")}</p>
                    :
                    <p>{t("app.salestriathlon.goals.sales1")}</p>
                  }
                  <h2>{first_record.from}</h2>
                  {this.props.currencyMode === "contracts" ?
                    <p>{t("app.salestriathlon.goals.contracts2")}</p>
                    :
                    <p>{t("app.salestriathlon.goals.sales2")}</p>
                  }
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
                <div className="salestriathlon-goals__center">
                  <h3>{t("app.salestriathlon.your.reward")}</h3>
                </div>
                <div className="salestriathlon-goals__lower">
                  <h2>{first_record.amount} <span>{t("app.points")}</span></h2>
                  <Icon icon="wusch" />
                </div>
              </div>

              <div className={`salestriathlon-goals__single ${this.renderMonthState(second_month)} ${secondMonthNotFinished} ${secondMonthAccomplished}`}>
                <div className="salestriathlon-goals__head">
                  <h3>{t("app.salestriathlon.second.month")}</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  {this.props.currencyMode === "contracts" ?
                    <p>{t("app.salestriathlon.goals.contracts1")}</p>
                    :
                    <p>{t("app.salestriathlon.goals.sales1")}</p>
                  }
                  <h2>{second_record.from}</h2>
                  {this.props.currencyMode === "contracts" ?
                    <p>{t("app.salestriathlon.goals.contracts2")}</p>
                    :
                    <p>{t("app.salestriathlon.goals.sales2")}</p>
                  }
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
                <div className="salestriathlon-goals__center">
                  <h3>{t("app.salestriathlon.your.reward")}</h3>
                </div>
                <div className="salestriathlon-goals__lower">
                  <h2>{second_record.amount} <span>{t("app.points")}</span></h2>
                  <Icon icon="wusch" />
                </div>
              </div>

              <div className={`salestriathlon-goals__single ${this.renderMonthState(third_month)} ${thirdMonthNotFinished} ${thirdMonthAccomplished}`}>
                <div className="salestriathlon-goals__head">
                  <h3>{t("app.salestriathlon.third.month")}</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  {this.props.currencyMode === "contracts" ?
                    <p>{t("app.salestriathlon.goals.contracts1")}</p>
                    :
                    <p>{t("app.salestriathlon.goals.sales1")}</p>
                  }
                  <h2>{third_record.from}</h2>
                  {this.props.currencyMode === "contracts" ?
                    <p>{t("app.salestriathlon.goals.contracts2")}</p>
                    :
                    <p>{t("app.salestriathlon.goals.sales2")}</p>
                  }
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
                <div className="salestriathlon-goals__center">
                  <h3>{t("app.salestriathlon.your.reward")}</h3>
                </div>
                <div className="salestriathlon-goals__lower">
                  <h2>{third_record.amount} <span>{t("app.points")}</span></h2>
                  <Icon icon="wusch" />
                </div>
              </div>
            </div>
          </div>
          {/* Monatsziele */}

          {/* Zielerreichung */}
          <div ref={(elem) => this.divRefTop = elem} className="salestriathlon-goals__inner salestriathlon-goals__inner--blue salestriathlon-goals__inner--accomplished">
            <h2 className="salestriathlon-goals__heading">{t("app.salestriathlon.goals.heading")}</h2>

            <div className="salestriathlon-goals__container">
              <div className={`salestriathlon-goals__single ${this.renderMonthState(first_month)} ${firstMonthNotFinished} ${firstMonthAccomplished}`}>
                <div className="salestriathlon-goals__head">
                  <h3>{t("app.salestriathlon.first.month")}</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  <h2>{this.userTransactionsForMonth(0)}</h2>
                  {this.props.currencyMode === "contracts" ?
                    <p>{t("app.contracts")}</p>
                    :
                    <p>{t("app.salestriathlon.goals.sales2")}</p>
                  }
                </div>
                <div id="first-month" className="salestriathlon-goals__center">
                  <Tachometer />
                  <div className="salestriathlon-goals__percent">
                    <h3>100 %</h3>
                  </div>
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
              </div>

              <div className={`salestriathlon-goals__single ${this.renderMonthState(second_month)} ${secondMonthNotFinished} ${secondMonthAccomplished}`}>
                <div className="salestriathlon-goals__head">
                  <h3>{t("app.salestriathlon.second.month")}</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  <h2>{this.userTransactionsForMonth(1)}</h2>
                  {this.props.currencyMode === "contracts" ?
                    <p>{t("app.contracts")}</p>
                    :
                    <p>{t("app.salestriathlon.goals.sales2")}</p>
                  }
                </div>
                <div id="second-month" className="salestriathlon-goals__center">
                  <Tachometer />
                  <div className="salestriathlon-goals__percent">
                    <h3>100 %</h3>
                  </div>
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
              </div>

              <div className={`salestriathlon-goals__single ${this.renderMonthState(third_month)} ${thirdMonthNotFinished} ${thirdMonthAccomplished}`}>
                <div className="salestriathlon-goals__head">
                  <h3>{t("app.salestriathlon.third.month")}</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  <h2>{this.userTransactionsForMonth(2)}</h2>
                  {this.props.currencyMode === "contracts" ?
                    <p>{t("app.contracts")}</p>
                    :
                    <p>{t("app.salestriathlon.goals.sales2")}</p>
                  }
                </div>
                <div id="third-month" className="salestriathlon-goals__center">
                  <Tachometer />
                  <div className="salestriathlon-goals__percent">
                    <h3>100 %</h3>
                  </div>
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
              </div>
            </div>
          </div>
          {/* Zielerreichung */}

          {/* Übersicht */}
          <div className="salestriathlon-goals__inner salestriathlon-goals__inner--grey">
            <h2 className="salestriathlon-goals__heading">{t("app.salestriathlon.all.goals.heading")}</h2>

            <div className="salestriathlon-goals__info">

              <div className="salestriathlon-goals__sum">
                <div className="salestriathlon-goals__left">
                  <div className="salestriathlon-goals__box">
                    <div className="salestriathlon-goals__top">
                      <h2>
                        {this.renderMonthsSum()}
                        <span>
                          {this.props.currencyMode === "contracts" ?
                          t("app.contracts")
                          :
                          t("app.salestriathlon.goals.sales2")
                        }
                        </span>
                      </h2>
                    </div>
                    <div className="salestriathlon-goals__bottom">
                      <h2>{this.userTargetPoints()} <span>{t("app.points")}</span></h2>
                    </div>
                  </div>
                </div>

                <div className="salestriathlon-goals__right">
                  {t("app.salestriathlon.all.goals.text")}
                </div>
              </div>

            </div>
          </div>
          {/* Übersicht */}

          {/* Restzeit */}
          <div className="salestriathlon-goals__inner salestriathlon-goals__inner--blue salestriathlon-goals__inner--accomplished">
            <h2 className="salestriathlon-goals__heading">{t("app.salestriathlon.finish.goals.heading")}</h2>

            <div className="salestriathlon-goals__performance">

              <div className="salestriathlon-goals__performance-left">
                <div className={`salestriathlon-goals__performance-top ${totalAccomplished}`}>
                  <div className="salestriathlon-goals__contracts">
                    <h2>{this.userTransactionsSum()}</h2>
                    {this.props.currencyMode === "contracts" ?
                      <p>{t("app.contracts")}</p>
                      :
                      <p>{t("app.salestriathlon.goals.sales2")}</p>
                    }
                  </div>
                </div>

                <div id="all-months" className="salestriathlon-goals__performance-bottom">
                  <Tachometer />
                  <div className="salestriathlon-goals__percent">
                    <h3>0 %</h3>
                  </div>
                </div>
              </div>

              <div className="salestriathlon-goals__performance-right">
                <div className="salestriathlon-goals__bigbox">
                <h3>{t("app.remaining.time")}</h3>
                <div className="salestriathlon-goals__circles">
                  <ProgressCircle
                    value={this.props.end !== null ? dateDiff(this.props.end).days : null}
                    maxValue={this.props.end !== null || this.props.start !== null ? dateDiff(this.props.end, new Date(this.props.start)).days : null}
                    isCountDown={true}
                    text={t("app.days")}
                    expired={this.props.end !== null ? dateExpired(this.props.end) : null}
                  />
                  <ProgressCircle
                    value={this.props.end !== null ? dateDiff(this.props.end).hours : null}
                    maxValue={24}
                    text={t("app.hours")}
                    expired={this.props.end !== null ? dateExpired(this.props.end) : null}
                  />
                  <ProgressCircle
                    value={this.props.end !== null ? dateDiff(this.props.end).minutes : null}
                    maxValue={60}
                    text={t("app.mins")}
                    expired={this.props.end !== null ? dateExpired(this.props.end) : null}
                  />
                </div>
                </div>
              </div>

            </div>
          </div>
          {/* Restzeit */}

        </div>
      </div>
    );
  }
}

export default SalesTriathlonGoals;