import React from "react";
// import PropTypes from 'prop-types'

const Tachometer = props => {

  // const [] = useState(props.data)

  return (
    <div className="tachometer__container">

      <div className="tachometer">
        <div className="ii">
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
          <div><b></b></div>
        </div>
        <div id="redline"></div>
        <div className="line" style={ props.rotate }></div>
        <div className="pin"><div className="inner"></div></div>
      </div>
    </div>
  );
};

// Tachometer.propTypes = {};
// Tachometer.defaultProps = {};

export default Tachometer;
