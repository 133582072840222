import React from "react";
import PropTypes from "prop-types";
import Icon from "../icons/Icon";

const TextLink = props => (
  <span className={"textlink__wrapper"}>
    <a href={props.url} className={`textlink textlink--${props.color} textlink--${props.iconPlacement}`}>
      {props.iconPlacement === "before" &&
      <Icon icon={"arrow"} />
      }
      {props.text}
      {props.iconPlacement === "after" &&
      <Icon icon={"arrow"} />
      }
    </a>
  </span>
);

TextLink.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["black", "white", "primary"]),
  iconPlacement: PropTypes.oneOf(["before", "after"])
};

TextLink.defaultProps = {
  color: "black",
  iconPlacement: "before"
};

export default TextLink;
