import React, { Component } from 'react'

import { getProjectTexts } from "../../../data/api";
import {
  CONTENT_WELLCOME_HEADLINE,
  CONTENT_WELLCOME_INTRO_TEXT,
} from "../../../data/constants";
import { AppContext } from '../../../data/ContextProvider'
import t from '../../../helpers/t9n'
import { bodyForLang } from "../../../lib/lib";

class WelcomeIntro extends Component {
  constructor(props) {
    super(props)
    this.state = {
      headline: {
        body: "",
      },
      intro: {
        body: "",
      },
      loading: true,
    };
  }

  async componentDidMount() {
    const headline = await getProjectTexts(CONTENT_WELLCOME_HEADLINE);
    const intro    = await getProjectTexts(CONTENT_WELLCOME_INTRO_TEXT);

    this.setState({
      headline: headline ?? this.state.headline,
      intro: intro ?? this.state.intro,
      loading: false,
    });
  }

  render() {
    if (this.state.loading) {return t("global.loading")}

    return (
      <div className="welcome-intro">
        <h2>
          {t("login.h1")} {this.context.getNameOfUser()}
        </h2>
        <h3>{bodyForLang(this.state.headline, window.currentLang)}</h3>
        <div className="welcome-intro--text">
          <p
            dangerouslySetInnerHTML={{
              __html: bodyForLang(this.state.intro, window.currentLang),
            }}
          ></p>
        </div>
      </div>
    );
  }
}

WelcomeIntro.contextType = AppContext;
export default WelcomeIntro
