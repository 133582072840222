import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/element/header-section/HeaderSection";
import Checkout from "./Checkout/Checkout";
import t from "../helpers/t9n";

const CheckoutPage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <Header heading={t("global.your.order")} hasImage={false} />
      <Checkout lang={lang} />
    </>
  )
}

export default CheckoutPage;
