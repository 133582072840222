import React, { Component } from "react";

import HeroSlider from "../hero-slider/HeroSlider";

class SectionHeroSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  
  render() {
    
    return (
      <div className={"hero-slider__section"}>
        <HeroSlider />
      </div>
    );
  }
}

export default SectionHeroSlider;
