import React, { Component } from "react"

import Button from "../../atom/button/Button"


import NeukundenjaegerBanner from "./NeukundenjaegerBanner";
import NeukundenjaegerRanking from "./NeukundenjaegerRanking";

import { APP_NEUKUNDENJAEGER } from "../../../data/constants";
import { AppContext } from "../../../data/ContextProvider";
import { getNewcontractsRankings, getAppPrefs, getAppTexts} from "../../../data/api";

import t from "../../../helpers/t9n";

export class Neukundenjaeger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null
    }
  }

  async componentDidMount() {
    try {

      const appPrefs = await getAppPrefs(APP_NEUKUNDENJAEGER)
      const texts    = await getAppTexts(APP_NEUKUNDENJAEGER)
      const rankings = await getNewcontractsRankings()

      this.setState({
        data: rankings.performance,
        appPrefs: appPrefs,
        pageText: texts,
        loading: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <div className="banner-section" id={this.props.id}>
          <div className={this.props.isHeaderOpen ? "banner-section-inner banner-section-inner--open" : "banner-section-inner"}>
            <NeukundenjaegerBanner
              text={this.state.pageText}
              headline={this.state.appPrefs.name}
              appID={APP_NEUKUNDENJAEGER}
            />

            <NeukundenjaegerRanking
              performance={this.state.data}
              start={this.state.appPrefs.start}
              end={this.state.appPrefs.end}
            />
          </div>

          {this.props.isHeaderOpen ? (
            <Button
              type="secondary"
              text={this.props.buttonOpen}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_NEUKUNDENJAEGER)}
            />
          ) : (
            <Button
              type="secondary"
              text={this.props.buttonClose}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_NEUKUNDENJAEGER)}
            />
          )}
        </div>
      </>
    );
  }
}

Neukundenjaeger.contextType = AppContext;
export default Neukundenjaeger;
