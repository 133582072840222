import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import ControlledLottie from "../../atom/lottie/ControlledLottie";

import confirm from "../../../assets/lotties/confirm-order/anim--icon-confirm-order";
import home from "../../../assets/lotties/order-home/anim--icon-home";
import overview from "../../../assets/lotties/order-overview-list/anim--icon-overview-list";
import Icon from "../../atom/icons/Icon";
import t from "../../../helpers/t9n";

const OrderBreadcrumb = (props) => {

  const [currentStep, setCurrentStep] = useState(props.step);

  useEffect(() => {
    setCurrentStep(props.step);

  }, [props.step]);

  return (
    <div className={`breadcrumb`}>
      <div className="breadcrumb__bubble">
        <div className={`breadcrumb__circle lottie--home ${currentStep >= 1 ? "" : "breadcrumb__circle--inactive"}`}>
          <ControlledLottie animationData={home} isPaused={false} isStopped={false} />
        </div>
        <span className={`breadcrumb__label ${currentStep >= 1 ? "breadcrumb__label--active" : ""}`}>
          {t("checkout.breadcrumb.button1")}
        </span>
      </div>

      <span className={`breadcrumb__loader ${currentStep >= 2 ? "breadcrumb__loader--done" : ""}`}>
        <span className={"breadcrumb__loader--inner"} />
      </span>

      <div className="breadcrumb__bubble">
        <div
          className={`breadcrumb__circle lottie--overview ${currentStep >= 2 ? "" : "breadcrumb__circle--inactive"}`}>
          {currentStep >= 2 ?
            <ControlledLottie animationData={overview} isStopped={!(currentStep >= 2)} isPaused={!(currentStep >= 2)} />
            :
            <Icon icon={"overview-grey"} />
          }
        </div>
        <span className={`breadcrumb__label ${currentStep >= 2 ? "breadcrumb__label--active" : ""}`}>
          {t("checkout.breadcrumb.button2")}
        </span>
      </div>

      <span className={`breadcrumb__loader ${currentStep >= 3 ? "breadcrumb__loader--done" : ""}`}>
        <span className={"breadcrumb__loader--inner"} />
      </span>

      <div className="breadcrumb__bubble">
        <div className={`breadcrumb__circle lottie--confirm ${currentStep >= 3 ? "" : "breadcrumb__circle--inactive"}`}>
          {currentStep >= 3 && !props.confirmationError ?
            <ControlledLottie animationData={confirm} isStopped={!(currentStep >= 3)} isPaused={!(currentStep >= 3)} />
            :
            <Icon icon={"confirm-order-grey"} />
          }
        </div>
        <span className={`breadcrumb__label ${currentStep >= 3 ? "breadcrumb__label--active" : ""}`}>
          {t("checkout.breadcrumb.button3")}
        </span>
      </div>
    </div>
  );
}

OrderBreadcrumb.propTypes = {
  step: PropTypes.number
};
OrderBreadcrumb.defaultProps = {};

export default OrderBreadcrumb;
