import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from "../icons/Icon";

const RadioButton = props => {

  const [selected, setSelected] = useState(null)

  useEffect(() => {
    setSelected(props.selected)
  }, [props.selected])

  return (
    <label
      className={`radio-button__container ${props.disabled ? 'radio-button__container--disabled' : ''} radio-button__container--${props.type}`}>{props.label}
      <input name={props.name}
             className={`radio-button`}
             type={`radio`}
             disabled={props.disabled}
             onChange={props.onChange ? props.onChange : null}
             defaultChecked={selected} />
    <span className={`radio-button__checkmark`}><Icon icon={"counter-dot"} /></span>
  </label>
  );
}

RadioButton.propTypes = {
  /** Label für die RadioButton */
  label: PropTypes.array,
  /** Checked Zustand der RadioButton Möglichkeiten : true/false */
  selected: PropTypes.bool,
  /** default Enabled/Disabled Zustand der RadioButton Möglichkeiten : true/false */
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.oneOf(["small", "big"]),
};
RadioButton.defaultProps = {
  disabled: false,
  selected: false,
  type: "big"
};

export default RadioButton
