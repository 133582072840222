import React, { useContext } from "react";

import "../sass/styles.sass";
import Navbar from "../components/element/navbar/Navbar";
import Footer from "../components/element/footer/Footer";
import { AppContext } from "../data/ContextProvider";

function ProductLayout({ children }) {
  
  const context = useContext(AppContext);
  
  return (
    <React.Fragment>
      <Navbar isScrolling={true} bgColor={"black"} isShopPage={true} />
      
      <div className={`container ${context.getProjectData().global_jackpot_active ? "container--jackpot-counter" : ""}`}>
        {children}
      </div>
      <Footer />
      <div className={`popup-cart__overlay ${context.isPopupCartVisible ? "popup-cart__overlay--visible" : ""}`}>
      </div>
    </React.Fragment>
  );
}

export default ProductLayout;
