import React, { Component } from "react";

import Content from "../Components/Content";
import Header from "../Components/Header";
import Stepper from "../Components/Stepper";

import { AppContext } from "../../../data/ContextProvider";

import { getTextForKey } from "../../../data/ct-api";
import t from "../../../helpers/t9n";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  async componentDidMount() {
    try {
      const pageText = await getTextForKey("welcome")

      this.setState({
        pageText: pageText,
        loading: false,
      })
    } catch (error) {
      this.setState({
        pageText: "",
        loading: false,
      })
      console.log(error)
    }
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <div className="compl-tool">

        <Header headline="" />

        <Stepper activeStep="1" />

        <Content
          title={this.state.pageText.headline}
          text={this.state.pageText.body}
          nextButton="Weiter zu den Spielregeln"
          nextButtonPath={`/${window.currentLang}/compliance-tool/rules`}
          showCheckbox={false}
        />
      </div>
    )
  }
}

Welcome.contextType = AppContext;
export default Welcome;
