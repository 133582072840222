import React, { Component } from "react";
import Textfield from "../../../components/atom/textfield/Textfield";
import Button from "../../../components/atom/button/Button";
import t from "../../../helpers/t9n";

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="login__container--right">
        <h2>{t("login.password_reset.headline")}</h2>
        <form name="reset-form" className="reset-form" onSubmit={(e) => this.props.submitFunc(e, 5)}>
          <Textfield
            label={t("app.email_your")}
            placeholder="m.mustermann@muster.de"
            name="reset_email"
            handleChange={(e) => this.props.handleChange(e)}
            inputType="email"
            isRequired={true}
          />

          <Button type="primary" textColor="white" text={t("login.password_reset.headline")}/>

          <Button type="secondary" textColor="black" text={t("global.back_to_login")}
                  onClick={(e) => this.props.submitFunc(e, 0)} />
        </form>

        <p className="login__help-text">
          {t("login.password_reset.description")}
        </p>

        <span className="login__links">
          <a href={`/${window.currentLang}`}>{t("menu.home")}</a>
          <a href={`/${window.currentLang}/tos`}>{t("menu.conditions.participation")}</a>
          <a href={`/${window.currentLang}/privacy`}>{t("login.data.protection")}</a>
          <a href={`/${window.currentLang}/imprint`}>{t("login.imprint")}</a>
          <a href={`/${window.currentLang}/contact`}>{t("login.contact")}</a>
        </span>
      </div>
    );
  }
}

export default PasswordReset;
