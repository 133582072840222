import React, { Component } from "react";

import { AppContext } from "../../../data/ContextProvider";

import SchaffeIchBanner from "./SchaffeIchBanner";
import SchaffeIchRanking from "./SchaffeIchRanking";
import Button from "../../atom/button/Button";

import {
  getAppPrefs,
  getBet,
  getBetDefaultsForUser,
  getAppTexts,
  selectBet,
} from "../../../data/api";

import { APP_SCHAFFEICH } from "../../../data/constants";
import t from "../../../helpers/t9n";
import { dateDiff, dateExpired } from "../../../helpers/utils";

export class SchaffeIch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      prefs: {},
      selectedBet: null,
      isBetChoiceTime: false,
      isActionTime: false,
    }
  }

  loadData = async () => {

    const bet = await getBet()
    const texts = await getAppTexts(APP_SCHAFFEICH)
    const appPrefs = await getAppPrefs(APP_SCHAFFEICH)


    if (bet.bet && bet.is_action_time) {
      this.setState({
        data: bet.bet,
        prefs: bet.prefs,
        performance: bet.betrankings,
        isBetChoiceTime: false,
        isActionTime: true,
        loading: false,
        pageText: texts,
        appPrefs: appPrefs,
        dateEndDiff: dateDiff(bet.prefs.end),
        dateStartToEndDiff: dateDiff(bet.prefs.end, new Date(bet.prefs.start)),
        dateExpired: dateExpired(bet.prefs.end),
        dateActionExpired: dateExpired(bet.prefs.end)
      })
    } else if (bet.is_betchoice_time) {
      const betDefaults = await getBetDefaultsForUser()

      this.setState({
        data: betDefaults.bet_defaults,
        prefs: bet.prefs,
        selectedBet: (bet.bet) ? bet.bet.challengename : "",
        isBetChoiceTime: true,
        isActionTime: false,
        loading: false,
        pageText: texts,
        appPrefs: appPrefs,
        dateEndDiff: dateDiff(bet.prefs.betchoice_end),
        dateStartToEndDiff: dateDiff(bet.prefs.betchoice_end, new Date(bet.prefs.betchoice_start)),
        dateExpired: dateExpired(bet.prefs.betchoice_end),
        dateActionExpired: dateExpired(bet.prefs.end)
      })
    } else {
      this.setState({
        data: [],
        prefs: bet.prefs,
        selectedBet: (bet.bet) ? bet.bet.challengename : "",
        isBetChoiceTime: true,
        isActionTime: false,
        loading: false,
        pageText: texts,
        appPrefs: appPrefs,
        dateEndDiff: dateDiff(bet.prefs.end),
        dateStartToEndDiff: dateDiff(bet.prefs.end, new Date(bet.prefs.start)),
        dateExpired: dateExpired(bet.prefs.end),
        dateActionExpired: dateExpired(bet.prefs.end)
      })
    }

    this.setState({
      pageText: texts,
      appPrefs: appPrefs,
      loading: false
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  handleSelectBet = async (challengeName) => {
    const selectedBet = this.state.data[challengeName - 1]

    let betData = {
      "win": selectedBet.betwin,
      "target": selectedBet.target,
      "challengename": challengeName
    }

    this.setState({
      selectedBet: challengeName
    }, async () => {
      await selectBet(betData)
      await this.loadData()
    })
  }

  render() {

    if (this.state.loading) {
      return t("global.loading")
    }
    
    return (
      <>
        <div className="banner-section" id={this.props.id}>
          <div className={this.props.isHeaderOpen ? "banner-section-inner banner-section-inner--open" : "banner-section-inner"}>
            <SchaffeIchBanner
              hasCampaignStarted={this.state.isActionTime}
              dateEndDiff={this.state.dateEndDiff}
              dateStartToEndDiff={this.state.dateStartToEndDiff}
              text={this.state.pageText}
              headline={this.state.appPrefs.name}
              dateExpired={(this.state.dateExpired || this.state.dateActionExpired)}
              appID={APP_SCHAFFEICH}
            />

            <SchaffeIchRanking
              hasBetStarted={this.state.isBetChoiceTime}
              hasCampaignStarted={this.state.isActionTime}
              bets={this.state.data}
              prefs={this.state.prefs}
              pageText={this.state.text}
              performance={this.state.performance}
              selectedBet={this.state.selectedBet}
              handleSelectBet={this.handleSelectBet}
              dateEndDiff={this.state.dateEndDiff}
              dateStartToEndDiff={this.state.dateStartToEndDiff}
              dateExpired={this.state.dateActionExpired}
              currencyMode={this.state.appPrefs.currency_mode}
            />

          </div>

          {this.props.isHeaderOpen ? (
            <Button
              type="secondary"
              text={this.props.buttonOpen}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_SCHAFFEICH)}
            />
          ) : (
            <Button
              type="secondary"
              text={this.props.buttonClose}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_SCHAFFEICH)}
            />
          )}
        </div>
      </>
    );
  }
}

SchaffeIch.contextType = AppContext;
export default SchaffeIch;
