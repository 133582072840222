import React from "react";
import PropTypes from "prop-types";

class Textfield extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value : "",
      isDisabled: this.props.isDisabled ? true : null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (prevProps.isDisabled !== this.props.isDisabled) {
      this.setState({
        isDisabled: this.props.isDisabled
      })
    }

    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      })
    }
  }

  render() {

    return (
      <div
        className={`textfield ${this.props.validationFunction && this.props.validationFunction(this.state.value) ? "textfield--error" : ""}
        ${this.props.errorMsg ? "textfield--error" : ""}
        ${this.state.isDisabled ? "textfield--disabled" : ""}`}>
        {this.props.label.length > 0 && (
          <label className="textfield__label">{this.props.label}{this.props.isRequired ? "" : ""}</label>
        )}

        {this.props.isControlled ?
          <input
            className="textfield__input"
            type={this.props.inputType}
            name={this.props.name ? this.props.name : this.props.placeholder}
            required={this.props.isRequired ? true : null}
            disabled={this.state.isDisabled}
            placeholder={this.props.placeholder}
            value={this.state.value ? this.state.value : ""}
            ref={this.props.innerRef ? this.props.innerRef : null}
            onChange={this.props.handleChange ? (e) => this.props.handleChange(e) : this.handleChange}
            onKeyDown={this.props.onKeyDown}
          />
          :
          <input
            className="textfield__input"
            type={this.props.inputType}
            name={this.props.name ? this.props.name : this.props.placeholder}
            required={this.props.isRequired ? true : null}
            disabled={this.state.isDisabled}
            placeholder={this.props.placeholder}
            defaultValue={this.state.value ? this.state.value : null}
            ref={this.props.innerRef ? this.props.innerRef : null}
            onChange={this.props.handleChange ? (e) => this.props.handleChange(e) : this.handleChange}
            onKeyDown={this.props.onKeyDown}
          />
        }


        {this.props.errorMsg && this.props.errorMsg.length > 0 && (
          <p className="textfield__errormsg">
            {this.props.errorMsg}
          </p>
        )}
      </div>
    );
  }
}

Textfield.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  validationFunction: PropTypes.func,
  handleChange: PropTypes.func,
  errorMsg: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isControlled: PropTypes.bool,
  inputType: PropTypes.oneOf(["text", "number", "email", "password", "date"]),
};

Textfield.defaultProps = {
  label: "",
  placeholder: "",
  validationFunction: null,
  errorMsg: null,
  isRequired: false,
  isDisabled: false,
  isControlled: false,
  inputType: "text"
};

export default Textfield;
