import React, { Component } from "react";
import { getContentPage } from "../../../data/api";
import { CONTENT_SPIELREGELN } from "../../../data/constants";
import t from "../../../helpers/t9n";
import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import CounterSpawner from "../../element/counter-spawner/CounterSpawner";
import safe from "../../../assets/lotties/safe/anim--icon-safe.json";
import diamond from "../../../assets/lotties/diamond/anim--icon-diamond.json";
import ControlledLottie from "../../atom/lottie/ControlledLottie";
import bandit from "../../../assets/lotties/bandit/anim--bandit-arm.json";
import { AppContext } from "../../../data/ContextProvider";

import { bodyForLang } from "../../../lib/lib";

export class PraemiencasinoRanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      openAccordion: "",
      body: "",
      loading: true,
    };
  }

  loadData = async () => {
    let body = t("app.have.fun");
    const textRecord = await getContentPage(
      CONTENT_SPIELREGELN,
      this.context.getUserData().project_id,
      this.context.getUserData().app_id
    );

    if (textRecord) {
      const text = textRecord.text;

      text.forEach(text => {
        if (text.key === 'p33_upper_content_text') {
          body = text
        }
      })
    }

    this.setState({
      body: body,
      loading: false,
    });
  };

  async componentDidMount() {
    await this.loadData();
  }

  handleAccordion(which) {
    if (this.state.openAccordion === which) {
      this.setState({
        openAccordion: "",
      });
    } else {
      this.setState({
        openAccordion: which,
      });
    }
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <div className="casino-room__section">
        <div className="casino-room__content">
          <div className="casino-room__wrapper">
            <div className="casino-room__information">
              <p dangerouslySetInnerHTML={{ __html: bodyForLang(this.state.body, window.currentLang) }}></p>
              <Button
                type="secondary"
                text={t("global.rules")}
                textColor="black"
                backgroundColor="bg-white"
                onClick={() => {
                  this.props.history.push(`/${window.currentLang}/rules/${this.props.appID}`);
                }}
              />
            </div>

            <div className="casino-room__slots-wrapper">
              <div className="casino-room__slots-available">
                <svg
                  className="casino-room__dotted-circle"
                  viewBox="0 0 100 100"
                >
                  <circle
                    cx="50"
                    cy="50"
                    strokeDasharray=".001, 8"
                    strokeWidth="4"
                    fill="none"
                    strokeLinecap="round"
                    stroke="white"
                    r="48"
                  />
                </svg>
                <div className="casino-room__slots">
                  <h1>{this.props.slots}</h1>
                </div>
              </div>
              <div className="casino-room__slots-lower">
                <div className="casino-room__slots-headline">
                  <h2>{t("app.available.games")}</h2>
                </div>
                <div className="casino-room__slots-subline">
                  <p>{t("app.equals.closed.contracts")}</p>
                </div>
              </div>
            </div>

            <div className="casino-room__boxes">
              <div className="casino-room__box--wrapper">
                <h2>{t("app.points.bandit")}</h2>
                <div className="casino-room__box casino-room__box--bandit">
                  <div className="casino-room__bandit-container">
                    <div className="dotted-circle">
                      <svg
                        className="casino-room__dotted-circle"
                        viewBox="0 0 100 100"
                      >
                        <circle
                          cx="50"
                          cy="50"
                          strokeDasharray=".001, 8"
                          strokeWidth="4"
                          fill="none"
                          strokeLinecap="round"
                          stroke="white"
                          r="48"
                        />
                      </svg>
                      <span className="jackpot-counter__icon">
                        <UncontrolledLottie animationData={diamond} />
                      </span>
                    </div>
                    <div className="casino-room__bandit-wrapper">
                      <div className="casino-room__bandit-wrapper--inner">
                        <CounterSpawner
                          amount={
                            this.props.banditValue
                              ? this.props.banditValue
                              : "000"
                          }
                          type="highlight"
                        />
                      </div>
                    </div>
                    <div
                      className="casino-room__bandit-handle"
                      onClick={() => this.props.handleSpinBandit()}
                    >
                      <div className="casino-room__bandit-handle--knob"></div>
                      <ControlledLottie
                        animationData={bandit}
                        loop={false}
                        isPaused={false}
                        isStopped={!this.props.isSpinning}
                      />
                    </div>
                  </div>
                  <div
                    className="casino-room__box--action"
                    onClick={() => this.handleAccordion("bandit")}
                  >
                    <Icon
                      icon={`${
                        this.state.openAccordion === "bandit"
                          ? "close"
                          : "information-circle"
                      }`}
                    />
                  </div>
                </div>
                <div
                  className={`casino-room__box-accordion ${
                    this.state.openAccordion === "bandit"
                      ? "casino-room__box-accordion--open"
                      : ""
                  }`}
                >
                  <p>
                    {t("app.bandit.info.klick")}
                    <br />
                    {t("app.bandit.info.game")}
                  </p>
                </div>
              </div>

              <div className="casino-room__box--wrapper">
                <h2>{t("app.points.safe")}</h2>
                <div className="casino-room__box casino-room__box--safe">
                  <div className="casino-room__box-container">
                    <div className="jackpot-counter">
                      <div className="dotted-circle">
                        <span className="jackpot-counter__icon">
                          <UncontrolledLottie animationData={safe} />
                        </span>
                      </div>
                      <span className="jackpot-counter__wrapper">
                        <CounterSpawner
                          amount={this.props.safe ? this.props.safe : "000000"}
                          type="highlight"
                        />
                        <h5>{t("app.points")}</h5>
                        <p>{t("app.point")}</p>
                      </span>
                    </div>
                  </div>
                  <div
                    className="casino-room__box--action"
                    onClick={() => this.handleAccordion("safe")}
                  >
                    <Icon
                      icon={`${
                        this.state.openAccordion === "safe"
                          ? "close"
                          : "information-circle"
                      }`}
                    />
                  </div>
                </div>
                <div
                  className={`casino-room__box-accordion ${
                    this.state.openAccordion === "safe"
                      ? "casino-room__box-accordion--open"
                      : ""
                  }`}
                >
                  <p>{t("app.points.safe.info")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PraemiencasinoRanking.contextType = AppContext
export default PraemiencasinoRanking;
