import React, { Component } from "react";
import { AppContext } from "../../../data/ContextProvider";
import {
  getAppPrefs,
  getAppTexts,
  getRankingLevels,
  getTransactionsForSalesAttack,
  getUserRankingForSalesAttack,
} from "../../../data/api";
import { APP_SALESATTACK } from "../../../data/constants";
import SalesAttackBanner from "./SalesAttackBanner";
import SalesAttackRanking from "./SalesAttackRanking";
import Button from "../../atom/button/Button";
import t from "../../../helpers/t9n";

export class SalesAttack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const appPrefs = await getAppPrefs(APP_SALESATTACK);
    const texts = await getAppTexts(APP_SALESATTACK);
    const rankinglevels = await getRankingLevels(APP_SALESATTACK);
    const user_rank = await getUserRankingForSalesAttack();
    const transactions = await getTransactionsForSalesAttack();

    this.setState({
      transactions: transactions.transactions,
      rankingLevels: rankinglevels.rankinglevels,
      user_rank: user_rank.user_rank,
      appPrefs: appPrefs,
      pageText: texts,
      loading: false,
    });
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <div className="banner-section" id={this.props.id}>
          <div className={this.props.isHeaderOpen ? "banner-section-inner banner-section-inner--open" : "banner-section-inner"}>
            <SalesAttackBanner
              text={this.state.pageText}
              headline={this.state.appPrefs.name}
              appID={APP_SALESATTACK}
            />

            <SalesAttackRanking
              ranking_levels={this.state.rankingLevels}
              transactions={this.state.transactions}
              currencyMode={this.state.appPrefs.currency_mode}
              current_points={this.state.user_rank.current_rank_level_points}
              remaining_target={this.state.user_rank.remaining_transactions_amount}
              start={this.state.appPrefs.start}
              end={this.state.appPrefs.end}
            />
          </div>

          {this.props.isHeaderOpen ? (
            <Button
              type="secondary"
              text={this.props.buttonOpen}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_SALESATTACK)}
            />
          ) : (
            <Button
              type="secondary"
              text={this.props.buttonClose}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_SALESATTACK)}
            />
          )}
        </div>
      </>
    );
  }
}

SalesAttack.contextType = AppContext;
export default SalesAttack;
