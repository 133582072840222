import React, { Component } from "react"
import Button from "../../../components/atom/button/Button";
import { Link } from "react-router-dom";

import { AppContext } from "../../../data/ContextProvider";

import t from "../../../helpers/t9n";

class Confirmation extends Component {

  render() {

    const project = this.context.getProjectData();

    let thank_you_text = t("global.confirmation.heading")

    if (project && project.thank_you_text && project.thank_you_text.length) {
      thank_you_text = project.thank_you_text
    }

    if (this.props.error) {
      return (
        <div className={`checkout__center checkout__center--error`}>
        <h3>{t("global.something.went.wrong")}</h3>
        <p>
          <b>{this.props.error}</b>
        </p>
        <span className="checkout__center-actions">
          <Link to={`/${window.currentLang}/`}>
            <Button text={t("global.back.to.homepage")} type={"primary"} textColor={"white"} />
          </Link>
          <Link to={`/${window.currentLang}/orders`}>
            <Button text={t("global.order.button.view")} type={"secondary"} textColor={"black"} />
          </Link>
        </span>
      </div>
      );
    }

    return (
      <div className={`checkout__center`}>
        <h3>{t("global.confirmation.heading")}</h3>
        <p>{thank_you_text}</p>
        <span className="checkout__center-actions">
          <Link to={`/${window.currentLang}/`}>
            <Button text={t("global.back.to.homepage")} type={"primary"} textColor={"white"} />
          </Link>
          <Link to={`/${window.currentLang}/orders`}>
            <Button text={t("global.order.button.view")} type={"secondary"} textColor={"black"} />
          </Link>
        </span>
      </div>
    );
  }
}

Confirmation.contextType = AppContext;
export default Confirmation;
