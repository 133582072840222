import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/element/header-section/HeaderSection";
import News from "./News";

const NewsPage = () => {
  let { lang } = useParams();

  return (
    <>
      <Header heading={"News"} hasImage={true} isRed={true} />
      <News lang={lang} />
    </>
  )
}

export default NewsPage;
