import React, { Component } from "react";

import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";

import { getAppPrefs, getAppTexts } from "../../../data/api";
import { APP_TEAMTROPHY } from "../../../data/constants";
import TeamTrophyBanner from "./TeamTrophyBanner";
import TeamTrophyCountdown from "./TeamTrophyCountdown";


import t from "../../../helpers/t9n";

export class TeamTrophy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  async componentDidMount() {
    const appPrefs = await getAppPrefs(APP_TEAMTROPHY)
    const texts    = await getAppTexts(APP_TEAMTROPHY)

    this.setState({
      appPrefs: appPrefs,
      pageText: texts,
      loading: false
    })
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <div className="banner-section" id={this.props.id}>
          <div className={this.props.isHeaderOpen ? "banner-section-inner banner-section-inner--open" : "banner-section-inner"}>
            <TeamTrophyBanner
              text={this.state.pageText}
              headline={this.state.appPrefs.name}
              appPrefs={this.state.appPrefs}
              appID={APP_TEAMTROPHY}
            />

            <TeamTrophyCountdown
              start={this.state.appPrefs.start}
              end={this.state.appPrefs.end}
            />
          </div>

          {this.props.isHeaderOpen ? (
            <Button
              type="secondary"
              text={this.props.buttonOpen}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_TEAMTROPHY)}
            />
          ) : (
            <Button
              type="secondary"
              text={this.props.buttonClose}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_TEAMTROPHY)}
            />
          )}
        </div>
      </>
    );
  }
}

TeamTrophy.contextType = AppContext;
export default TeamTrophy;
