import React, { Component } from 'react'
import { AppContext } from '../../../data/ContextProvider'
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";
import Icon from '../../../components/atom/icons/Icon';
import UncontrolledLottie from '../../../components/atom/lottie/UncontrolledLottie';

class ModalContractsSuccess extends Component {

  constructor(props) {
    super(props)
    this.state = {}

    this.countdownTimer = null
    this.currentSeconds = 0
    this.timeToKill = 3
  }

  componentDidMount() {
    this.countdownTimer = this.createTimer()
  }

  componentWillUnmount() {
    clearInterval(this.countdownTimer);
    this.countdownTimer = null
  }

  createTimer = () => {
    return setInterval(() => this.countdown(), 1000);
  }

  countdown = () => {
    if (this.currentSeconds >= this.timeToKill) {
      this.currentSeconds = 1;
      clearInterval(this.countdownTimer);
      this.context.toggleModalSuccessContract();
    } else {
      this.currentSeconds++
    }
  }

  render() {
    return (
      <>
        <div className={`modal-information modal-information--contracts`}>
          <div className={"modal-information__upper"}>
            <span className={"modal-information__close"} onClick={() => this.context.toggleModalSuccessContract()}>
              <Icon icon={"close"} />
            </span>
            <div className={"modal-information__center"}>
              <UncontrolledLottie animationData={confetti} />
            </div>
            <span className={"modal-information__row"}>
              <h3>Die Punkte wurden Ihrem Konto gutgeschrieben.</h3>
            </span>
          </div>
        </div>

        <div className={`main-menu__overlay main-menu__overlay--visible`}>
        </div>
      </>
    )
  }
}

ModalContractsSuccess.contextType = AppContext
export default ModalContractsSuccess;
