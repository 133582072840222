import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import Button from "../../../components/atom/button/Button";
import Checkbox from "../../../components/atom/checkbox/Checkbox";

class Content extends Component {
  render() {
    return (
      <div className="content">

        {this.props.title ? (
          <h3>{this.props.title}</h3>
        ) : null}

        {this.props.text ? (
          <p
            dangerouslySetInnerHTML={{
              __html: this.props.text ? this.props.text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
            }}
          ></p>
        ) : null}

        {this.props.showCheckbox ?
          <span className="compl-check">
            <Checkbox
              onChange={(e) => this.props.handleCheckbox(e)}
              label={this.props.checkboxLabel}
            />
          </span>
        : null }

        <div className="compl__buttons">
          {this.props.prevButton && this.props.prevButtonPath ? (
            <span className="compl-btn">
              <Button
                type="secondary"
                text={this.props.prevButton}
                textColor="black"
                onClick={() => {
                  this.props.history.push(`${this.props.prevButtonPath}`);
                }}
                icon="arrow"
                additionalClass="button--icon--left"
              />
            </span>
          ): null}

          {this.props.nextButton && this.props.nextButtonPath ? (
            <span className="compl-btn">
              <Button
                type="primary"
                text={this.props.nextButton}
                textColor="white"
                onClick={() => {
                  this.props.history.push(`${this.props.nextButtonPath}`);
                }}
                icon="arrow"
                disabled={this.props.isDisabledActive}
                additionalClass="button--icon--right"
              />
            </span>
          ): null}
        </div>
      </div>
    );
  }
}


export default withRouter(Content)

