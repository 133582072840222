import React, { Component } from "react";
import PropTypes from 'prop-types'
import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";
import { AppContext } from "../../../data/ContextProvider";
import CartProduct from "../../element/cart-product/CartProduct";
import { Link } from "react-router-dom";
import { getCart, getUserStats, removeFromCart } from "../../../data/api";
import { getProductImage, getProductPrice, renderAvailabilityState } from "../../../helpers/utils";
import t from "../../../helpers/t9n";


class PopupCart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cart: null,
      loading: true,
    }
  }

  handleGoToCart() {
    this.context.togglePopupCart();
  }

  componentDidMount() {
    const cart = getCart()

    cart.then(cart => {
      this.setState({
        cart: cart,
        loading: false
      })
      return true
    })
  }

  removeFromCart = (vid) => {
    const response = removeFromCart(vid)

    response.then(cart => {
      getUserStats().then((stats) => {
        this.setState({
          cart: cart,
        })
        this.context.setStatsOfUser(stats.stats)
      })
    })
  }

  render() {
    if (this.state.loading)
      return (
        <>
        <div className={`popup-cart`}>
          <div className={"popup-cart__upper"}>
          <span className={"popup-cart__close"} onClick={() => this.context.togglePopupCart()}>
            <Icon icon={"close"} />
          </span>
            <span className={"popup-cart__row"}>
            <span
              className={"popup-cart__title"}>{t("menu.shopping.cart")}</span>
          </span>
            <div className={"popup-cart__products-container"}>
              <div className="popup-cart__products">
                <span>{t("global.loading")}</span>
              </div>
            </div>
          </div>

          <div className={"popup-cart__lower"}>
          <span className={"popup-cart__row"}>
            <span className={"popup-cart__price-label"}>{t("cart.popup.sum")}</span>
            <span className={"popup-cart__price"}></span>
          </span>
            <span className={"popup-cart__row"}>
            <span className={"popup-cart__delivery"}>{t("cart.popup.delivery")}</span>
          </span>
            <span className={"popup-cart__row"}>
            <Link to={`/${window.currentLang}/cart`}>
            <Button text={t("global.wishlist.page.button.cart")} type={"primary"} textColor={"white"}
                    onClick={() => this.handleGoToCart()} />
            </Link>
          </span>
          </div>
        </div>

      </>
      );

    const cart = this.state.cart.cart
    const project_code = this.context.getUserData().project_code

    return (
      <>
        <div className={`popup-cart`}>
          <div className={"popup-cart__upper"}>
          <span className={"popup-cart__close"} onClick={() => this.context.togglePopupCart()}>
            <Icon icon={"close"} />
          </span>
            <span className={"popup-cart__row"}>
            <span
              className={"popup-cart__title"}>{t("cart.popup.first")} ({cart.length} {t("cart.popup.second")})</span>
          </span>
            <div className={"popup-cart__products-container"}>
              <div
                className={`popup-cart__products ${cart.length > 2 ? "popup-cart__products--scroll" : ""}`}>
                {
                  cart.map((elem) => {
                    return (<CartProduct
                      product={elem.product}
                      title={elem.product.title}
                      price={getProductPrice(elem.option, project_code)}
                      variant={elem.option.article_number}
                      pid={elem.product.id}
                      id={elem.product.number}
                      variantId={elem.option.id}
                      image={getProductImage(elem.images)}
                      quantity={elem.item.quantity}
                      isAvailable={elem.option.state === "available"}
                      shippingDays={renderAvailabilityState(elem.option.availability_state)}
                      removeFromCartCallback={this.removeFromCart}
                      key={elem.product.id} />)
                  })
                }
              </div>
            </div>
          </div>

          <div className={"popup-cart__lower"}>
            <span className={"popup-cart__row"}>
              <span className={"popup-cart__price-label"}>{t("cart.popup.sum")}</span>
              <span className={"popup-cart__price"}>{Number(this.state.cart.total_price).toLocaleString()} {t("app.points")}</span>
            </span>
            <span className={"popup-cart__row"}>
              <Link to={`/${window.currentLang}/cart`}>
                <Button text={t("global.wishlist.page.button.cart")} type={"primary"} textColor={"white"}
                        onClick={() => this.handleGoToCart()} />
              </Link>
            </span>
          </div>
        </div>

      </>
    );
  }
}

PopupCart.propTypes = {
  sum: PropTypes.string,
  delivery: PropTypes.string,
  isVisible: PropTypes.bool,
};
PopupCart.defaultProps = {
  sum: "1.500 PKT",
  delivery: "7 - 10 Werktage",
  isVisible: true
};

PopupCart.contextType = AppContext;
export default PopupCart;
