import React from "react";
import { useParams } from "react-router-dom";
import NotFound from "./404/NotFound";

const NotFoundPage = (props) => {
  let { lang } = useParams();

  return (
    <NotFound lang={lang} isSubDomainError={props.isSubDomainError} />
  )
}

export default NotFoundPage;
