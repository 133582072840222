import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from "../../atom/icons/Icon";

const Accordion = props => {
  
  const [isActive, setIsActive] = useState(props.isOpen);
  
  function toggleAccordion() {
    if (props.handleClick)
      props.handleClick();
    
    setIsActive(!isActive);
  }
  
  useEffect(() => {
    setIsActive(props.isOpen)
  }, [props.isOpen, setIsActive]);
  
  return (
    <div
      className={`accordion ${isActive ? "accordion--active" : ""} ${props.additionalClass ? props.additionalClass : ""}`}>
      <span className={"accordion__header"} onClick={() => toggleAccordion()}>
        {props.heading}
        <Icon icon={`${isActive ? "minus" : "adding"}`} />
      </span>
      <div className={"accordion__items"}>
        {props.children}
      </div>
    </div>
  );
}

Accordion.propTypes = {
  heading: PropTypes.string,
  handleClick: PropTypes.func,
  isOpen: PropTypes.bool,
};
Accordion.defaultProps = {
  heading: "Accordion",
  isOpen: false,
};

export default Accordion
