Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Carousel = _interopRequireDefault(require("./Carousel.js"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var _default = _Carousel["default"];
exports["default"] = _default;
