import React, { Component } from "react";

import Button from "../../atom/button/Button";
import Textfield from "../../atom/textfield/Textfield";
import TextfieldGroup from "../../atom/textfield-group/TextfieldGroup";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import searchPraemie from "../../../assets/lotties/search-praemie/anim--icon-search-praemie";
import { withRouter } from "react-router-dom";
import t from "../../../helpers/t9n";

class SectionProductSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: "",
      p_from: 0,
      p_to: 0,
    };
    
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
  }
  
  handleSearchSubmit(e) {
    e.preventDefault()
    
    const data = {
      search_term: this.state.search_term,
      p_from: this.state.p_from,
      p_to: this.state.p_to,
    }
    
    this.props.history.push({
      pathname: `/${window.currentLang}/shop`,
      data: data
    })
    
  }
  
  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const state = this.state
    state[name] = value
    this.setState({ ...state });
  }
  
  render() {
    return (
      <div className={"product-search__section"}>
        <div className={"product-search"}>
          <h3>{t("app.award.search")}</h3>
          <span className={"product-search__circle"}>
            {/*<Icon icon={"shipment"} />*/}
            <UncontrolledLottie animationData={searchPraemie} />
          </span>
          <span className={"product-search__form"}>
            <form onSubmit={(e) => this.handleSearchSubmit(e)}>
              <Textfield placeholder={t("app.award.search.keyword")} name={"search_term"}
                         handleChange={this.handleChange} />
              <TextfieldGroup>
                <Textfield placeholder={t("app.award.search.points.from")} name={"p_from"}
                           handleChange={this.handleChange} />
                -
                <Textfield placeholder={t("app.award.search.points.to")} name={"p_to"}
                           handleChange={this.handleChange} />
              </TextfieldGroup>
              <Button type={"primary"} textColor={"white"} text={t("app.award.search.start")} />
            </form>
          </span>
        </div>
      </div>
    );
  }
}

export default withRouter(SectionProductSearch);
