import React, { Component } from 'react'

import Selectfield from "../../../components/atom/selectfield/Selectfield";
import Textfield from "../../../components/atom/textfield/Textfield";
import Button from "../../../components/atom/button/Button";
import Icon from '../../../components/atom/icons/Icon';

import { updateCtUser } from "../../../data/ct-api";
import { AppContext } from "../../../data/ContextProvider";

export default class RegisterEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      showSuccess:false,
      showError: false,
      errors: [],
      user: {salutation: "herr"}
    }
  }

  handleChange = (e) => {
    const user = this.state.user
    if (e.target) {
      user[e.target.name] = e.target.value
      this.setState({ user });
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    try {
      await updateCtUser(this.state.user)
      this.setState({ showSuccess: true, showError: false}, ()=>{
        this.props.onSuccess()
      })
    } catch (error) {
      this.setState({ errors: [...error.errors], showError: true })
    }
  }

  async componentDidMount() {
    this.setState({
      user: this.props.user,
    })
  }

  render() {
    return (
      <div className="compl__user__container__form">

        {this.state.showSuccess &&
          <div className="success-message">
            <h5>Stammdaten wurden erfolgreich aktualisiert!</h5>
          </div>
        }

        {this.state.showError &&
          <div className="error-message">
            <h5>Ein Fehler ist aufgetreten.</h5>
            {this.state.errors.map((error, index) => {
              return <p key={index}>{error}</p>
            })}
          </div>
        }

        <form name="registration-form">
          <div className="profile__personal-data">
            <div className="login__columns--wrapper login__columns--wrapper--step2">

              <span className="button-close" onClick={this.props.toggleEditFormVisibility}>
                <Icon icon="close" />
              </span>

              <div className="login__row">
                <div className="login__col">
                  <Selectfield
                    label="Anrede"
                    options={{
                      'Herr': "Herr",
                      'Frau': "Frau",
                      'Dr': "Dr",
                    }}
                    name="salutation"
                    isRequired={true}
                    defaultValue={(this.state.user.salutation) ? this.state.user.salutation : "Herr"}
                    onSelect={(e) => this.handleChange(e)}
                    placeholder="Anrede"
                  />
                </div>
              </div>

              <div className="login__row">
                <div className="login__col">
                  <Textfield
                    label="Vorname *"
                    placeholder="Max"
                    value={this.state.user.first_name}
                    isRequired={true}
                    name="first_name"
                    handleChange={(e) => this.handleChange(e)}
                  />
                </div>

                <div className="login__col">
                  <Textfield
                    label="Nachname *"
                    isRequired={true}
                    placeholder="Mustermann"
                    name="last_name"
                    value={this.state.user.last_name}
                    handleChange={(e) => this.handleChange(e)}
                  />
                </div>
              </div>

              <div className="login__row">
                <div className="login__col">
                    <Textfield
                      label="E-Mail Adresse *"
                      placeholder="m.mustermann@muster.de"
                      name="email"
                      inputType="email"
                      isRequired={true}
                      value={this.state.user.email}
                      handleChange={(e) => this.handleChange(e)}
                    />
                </div>

                <div className="login__col">
                  <Textfield
                    label="Rolle/Position"
                    placeholder="Mitarbeitender"
                    name="group"
                    value={this.state.user.group}
                    isDisabled={true}
                  />
                </div>
              </div>

              <div className="login__row">
                <div className="login__col">
                  <Textfield
                    label="Firmen-Nr"
                    placeholder=""
                    value={this.state.user.company_number}
                    isRequired={true}
                    isDisabled={true}
                    name="company_number"
                  />
                </div>

                <div className="login__col">
                  <Textfield
                    label="Autohaus"
                    isRequired={true}
                    isDisabled={true}
                    placeholder=""
                    name="company"
                    value={this.state.user.company}
                  />
                </div>
              </div>
            </div>
          </div>
          <span className="profile-header__actions">
            <Button
              type="primary"
              text="Daten aktualisieren"
              textColor="white"
              onClick={(e)=>this.onSubmit(e)}
            />
          </span>
        </form>
      </div>
    )
  }
}
RegisterEdit.contextType = AppContext;
