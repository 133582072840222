import React, { Component } from "react";
import SectionProfileHeader from "../../components/module/section-profile-header/SectionProfileHeader";
import { checkPasswordPolicy, logoutUser } from "../../lib/lib";
import {
  createAddress,
  deleteAddress,
  getAddresses,
  updateAddress,
  updatePasswordProfile,
  updateUser,
  loadCurrentUser,
  deleteAvatar,
  updateAvatar,
} from "../../data/api";

import SectionProfileAddress from "../../components/module/section-profile-address/SectionProfileAddress";
import SectionProfileLogin from "../../components/module/section-profile-login/SectionProfileLogin";
import { AppContext } from "../../data/ContextProvider";
import t from "../../helpers/t9n";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      passwordError: "",
      passwordSuccess: "",
      newAddress: {},
    };
  }

  handleUserChange = (e) => {
    const user = this.state.user

    if (e.target) {
      user[e.target.name] = e.target.value;
      this.setState({ user });
    }
  };

  handlePasswordChange = (e) => {
    const state = this.state;
    if (e.target) {
      state[e.target.name] = e.target.value;
      this.setState({ state });
    }
  };

  handleNewAddressChange = (e) => {
    const newAddress = this.state.newAddress;
    if (e.target) {
      newAddress[e.target.name] = e.target.value;
      this.setState({ newAddress });
    }
  };

  handleAddresspdate = (e, address_id) => {
    const index = this.state.addresses.findIndex((s) => s.id === address_id);

    if (index >= 0) {
      const addresses = [...this.state.addresses];
      addresses[index][e.target.name] = e.target.value;
      this.setState({ addresses }, () => {});
    }
  };

  handleAddressChange = (e, address_id, isEditMode) => {
    if (isEditMode) {
      this.handleAddresspdate(e, address_id);
    } else {
      this.handleNewAddressChange(e);
    }
  };

  handleSubscribeInfos = () => {
    const user = { ...this.state.user };
    user.subscribed_infos = !this.state.user.subscribed_infos;
    this.setState({ user });
  };

  handleUpdateProfile = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      this.context.toggleModalInfo(false);
      await updateUser(this.state.user);
      this.loadData();
    } catch (error) {
      this.context.toggleModalInfo(true);
    }
  };

  handleUpdateAddress = async (address_id) => {

    try {
      this.context.toggleModalInfo(false);
      const index = this.state.addresses.findIndex((s) => s.id === address_id);
      const addresses = [...this.state.addresses];
      await updateAddress(address_id, addresses[index]);
    } catch (error) {
      this.context.toggleModalInfo(true);
    }
  };

  handleCreateAddress = async (e) => {
    try {
      this.context.toggleModalInfo(false);
      await createAddress(this.state.newAddress);
      this.loadData();
    } catch (error) {
      this.context.toggleModalInfo(true);
    }
  };

  handleDeleteAddress = async (addressId) => {
    try {
      await deleteAddress(addressId);
      this.loadData();
    } catch (error) {
      console.log(error);
    }
  };

  onAvatarChange = async (file) => {
    if (file) {
      try {
        await updateAvatar(this.state.user.id, file);
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  deleteAvatar = async () => {
    try {
      await deleteAvatar(this.state.user.id);
      this.loadData();
    } catch (error) {
      console.log(error);
    }
  };

  handleUpdatePassword = async (e) => {
    e.preventDefault();

    const data = {
      current_password: this.state.current_password,
      password: this.state.password,
      password_repeat: this.state.password_repeat,
    };

    if (
      !this.state.current_password ||
      this.state.current_password.length === 0
    ) {
      this.setState({ passwordError: t("profile.page.pw.now") });
      return;
    }

    if (
      !this.state.password ||
      !this.state.password_repeat ||
      this.state.password.length === 0 ||
      this.state.password_repeat.length === 0
    ) {
      this.setState({ passwordError: t("profile.page.pw.no.empty") });
      return;
    }

    if (this.state.password !== this.state.password_repeat) {
      this.setState({ passwordError: t("profile.page.pw.not.same") });
      return;
    }

    if (!checkPasswordPolicy(this.state.password)) {
      this.setState({ passwordError: t("profile.page.pw.min.txt") });
      return;
    }

    try {
      //this.context.toggleModalInfo(false)
      await updatePasswordProfile(data);
      logoutUser();
      this.setState({ passwordSuccess: "", passwordError: "" });
    } catch (error) {
      this.context.toggleModalInfo(true);
      this.setState({ passwordError: error.data.error });
    }
  };

  loadData = async () => {
    const user = await loadCurrentUser();
    const addressesRecord = await getAddresses();
    const addresses = addressesRecord.addresses

    this.setState({
      addresses: addresses,
      user: user.user,
      shipping_countries: addressesRecord.shipping_countries,
      loading: false,
    });
  };

  async componentDidMount() {
    this.loadData();
  }

  render() {
    if (this.state.loading) {
      return "loading..";
    }

    return (
      <>
        <div className="content private">
          <SectionProfileHeader
            user={this.state.user}
            handleChange={this.handleUserChange}
            handleSubscribeInfos={this.handleSubscribeInfos}
            handleUpdateProfile={this.handleUpdateProfile}
            onAvatarChange={this.onAvatarChange}
            deleteAvatar={this.deleteAvatar}
            showAvatarUpload={true}
            showSubscriberInfos={true}
            subHeadline={""}
            shipping_countries={this.state.shipping_countries}
          />
          {!this.state.user.order_delivery_method &&
            <SectionProfileAddress
              data={this.state.addresses}
              handleChange={this.handleAddressChange}
              handleCreateAddress={this.handleCreateAddress}
              handleDeleteAddress={this.handleDeleteAddress}
              handleUpdateAddress={this.handleUpdateAddress}
            />
          }

          <SectionProfileLogin
            handleChange={this.handlePasswordChange}
            error={this.state.passwordError}
            success={this.state.passwordSuccess}
            handleUpdatePassword={this.handleUpdatePassword}
          />
        </div>
      </>
    );
  }
}

Profile.contextType = AppContext;
export default Profile;
