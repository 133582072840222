import React, { useState } from 'react'

import Icon from "../../atom/icons/Icon";

import { shortText } from "../../../lib/lib"
import t from '../../../helpers/t9n'
import { API_HOST } from "../../../data/config";

const NewsItem = props => {
  const [isActive, setIsActive] = useState(false)

  function toggleNewsItem() {
    props.onRead(props.id)
    setIsActive(!isActive)
  }

  return (
    <>
      <div
        className=
        {`
          ${(props.newsOpen ? !isActive : isActive) ? "news-content-detail__item news-content-detail__item--active" : "news-content-detail__item"}
          ${props.isRead ? "" : "news-content-detail__item news-content-detail__item--new"}
          ${props.additionalClass ? props.additionalClass : ""}
        `}
        onClick={() => toggleNewsItem()}
      >
        <div className={props.teaserimage !== 0 ? "news-content-detail__image" : "news-content__image news-content-detail__image--empty"}>
          {props.isRead ? null : <div className="news-content-detail__badge">{t("app.new")}</div>}
          {(props.teaserimage !== 0) &&
            <figure>
              <img src={`${API_HOST}/news/teaserimage/${props.id}?domain=${document.domain}`} alt=""/>
              <cite>{props.subheadline}</cite>
            </figure>
          }
        </div>

        <div className={props.teaserimage !== 0 ? "news-content-detail__content" : "news-content-detail__content news-content-detail__content--fullwidth"}>
          <small>{props.date}</small>
          <h3><span>{props.heading}</span></h3>
          <div className="editor">
            <p dangerouslySetInnerHTML={{__html: shortText(props.text, 150) }}></p>
          </div>
          <Icon icon={"arrow"} />
        </div>

        <div className="news-content-detail__content--full">
          <div className="editor">
            <p dangerouslySetInnerHTML={{__html: props.text }}></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsItem
