import React, { useContext } from "react";

import Navbar from "../components/element/navbar/NavbarPublicComplTool";
import Footer from "../components/element/footer/PublicFooterComplTool";
import { AppContext } from "../data/ContextProvider";

function EmptyLayoutComplTool(props) {
  const context = useContext(AppContext);

  return (
    <React.Fragment>
      <Navbar bgColor={"black"} isScrolling={true} />
      <div className={`container ${props.fullHeight ? "container--full-height" : ""} ${context.getProjectData().global_jackpot_active ? "container--jackpot-counter" : ""}`}>
        {props.children}
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default EmptyLayoutComplTool;
