import React, { Component } from "react";

export default class ETracker extends Component {

  async componentDidMount() {

    if (this.props.vars) {
      const var_script = document.createElement("script");
      var_script.type = 'text/javascript'
      var_script.innerHTML = this.props.vars
      document.body.appendChild(var_script)
    }

    const script = document.createElement("script");
    script.id = "_etLoader"
    script.type = 'text/javascript'
    script.src = "//code.etracker.com/code/e.js"
    script.setAttribute("data-secure-code", this.props.code)
    script.setAttribute("data-respect-dnt", true)
    script.setAttribute("data-block-cookies", true)
    script.async = true

    document.body.appendChild(script)
  }

  render() {
    return(
      <></>
    )
  }
}
