import React, { Component } from "react";
import t from "../../../helpers/t9n";

import { getJackpotRankings, getAppTexts } from "../../../data/api";
import { APP_JACKPOT } from "../../../data/constants"
import { bodyForLang } from "../../../lib/lib";

export class PraemienjackpotRanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const rankings = await getJackpotRankings();
    const texts = await getAppTexts(APP_JACKPOT)

    this.setState({
      team_rankings: rankings.team_rankings,
      user_rankings: rankings.user_rankings,
      labels: rankings.labels,
      pageText: texts,
      loading: false
    })
  }


  renderNewPerformances() {
    return (
      <>
        <div className="heros-ranking-new-state">
          <div className="heros-ranking__board">
            <div className="heros-ranking__board--wrapper">
              <div className="heros-ranking__row--4">
              <div className="heros-ranking__top-bar">
                <div className="heros-ranking__item">
                  <span>Rang</span>
                </div>

                { this.state.user_rankings?.company === '-' &&
                  <div className="heros-ranking__item">
                    <span>Vorname</span>
                  </div>
                }
                { this.state.user_rankings?.company !== '-' &&
                  <div className="heros-ranking__item">
                    <span>Händler</span>
                  </div>
                }

                { this.state.user_rankings?.company === '-' &&
                  <div className="heros-ranking__item">
                    <span>Name</span>
                  </div>
                }

                { this.state.labels && this.state.labels.map((label, index) => {
                  return <div className="heros-ranking__item" key={index}>
                    <span>{label}</span>
                  </div>
                })}

              </div>
              </div>
                { Object.keys(this.state.user_rankings).length !== 0 &&
                  <div className={`heros-ranking__row heros-ranking__row--4 heros-ranking__row-active`} key={0}>
                    <div className="heros-ranking__item">
                      <span>{this.state.user_rankings?.rank}</span>
                    </div>

                    <div className="heros-ranking__item">
                      {this.state.user_rankings?.company !== '-' &&
                        <span>{this.state.user_rankings?.company}</span>
                      }
                      { this.state.user_rankings?.company === '-' &&
                        <span>{this.state.user_rankings?.first_name}</span>
                      }
                    </div>

                    { this.state.user_rankings?.company === '-' &&
                      <div className="heros-ranking__item">
                        <span>{this.state.user_rankings?.last_name}</span>
                      </div>
                    }

                    <div className="heros-ranking__item">
                      <span>{Number(this.state.user_rankings?.value_1 || 0 ).toLocaleString()}</span>
                    </div>
                    { this.state.user_rankings?.value_2 &&
                      <div className="heros-ranking__item">
                        <span>{Number(this.state.user_rankings?.value_2 || 0).toLocaleString()}</span>
                      </div>
                    }
                    { this.state.user_rankings?.value_3 &&
                      <div className="heros-ranking__item">
                        <span>{Number(this.state.user_rankings?.value_3 || 0).toLocaleString()}</span>
                      </div>
                    }
                  </div>
                }
                {this.renderPerformanceTable()}
            </div>
          </div>
        </div>
      </>
    )
  }

  renderPerformanceTable() {
    const out = []

    this.state.team_rankings.map((ranking, item_index) => {
      out.push(
        <div className='heros-ranking__row  heros-ranking__row--4  heros-ranking__row' key={item_index}>
          <div className="heros-ranking__item">
            <span>{ranking?.rank} </span>
          </div>

          <div className="heros-ranking__item">
            <span>{ranking?.first_name}</span>
          </div>

          { this.state.user_rankings?.company === '-' &&
            <div className="heros-ranking__item">
              <span>{ranking?.last_name}</span>
            </div>
          }


          <div className="heros-ranking__item">
            <span>{Number( ranking?.value_1 ).toLocaleString()}</span>
          </div>
          { ranking?.value_2 &&
            <div className="heros-ranking__item">
              <span>{Number(ranking?.value_2 ).toLocaleString()}</span>
            </div>
          }
          { ranking?.value_3 &&
            <div className="heros-ranking__item">
              <span>{Number(ranking?.value_3 ).toLocaleString()}</span>
            </div>
          }
        </div>
      );
    })
    return out;
  }

  render() {
    if (this.state.loading) return t("global.loading");

    const texts = this.state.pageText

    let body = ""

    texts.forEach(text => {
      if (text.key === 'p33_upper_content_text') {
        body = text
      }
    })



    return (
      <div className="heros-ranking__section heros-ranking__section--open">
        <span className="heros-ranking__header">
          <h2 className="heros-ranking__label" id="ranking">
            Ranking
          </h2>
        </span>

        <div className="heros-ranking__content" style={{paddingBottom: 0, fontSize: "16px"}}>
          <p dangerouslySetInnerHTML={{ __html: bodyForLang(body, window.currentLang) }}></p>
        </div>

        <div className="heros-ranking__content">
          <div className="heros-ranking__wrapper">
            { this.renderNewPerformances() }
          </div>
        </div>
      </div>
    );
  }
}

export default PraemienjackpotRanking;
