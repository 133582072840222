Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChildrenEqual = exports.ObjectsEqual = void 0;

/**
 * Compare two objects using stringify
 * @returns {Boolean}
 */
var ObjectsEqual = function ObjectsEqual(val1, val2) {
  return (
    JSON.stringify({
      a: val1
    }) ===
    JSON.stringify({
      a: val2
    })
  );
};
/**
 * Compare two children by keys
 * @returns {Boolean}
 */

exports.ObjectsEqual = ObjectsEqual;

var ChildrenEqual = function ChildrenEqual(val1, val2) {
  var keys1 = val1.map(function(child) {
    return child.key;
  });
  var keys2 = val2.map(function(child) {
    return child.key;
  }); // diferent size

  if (keys1.length !== keys2.length) return false; // check each key

  for (var i = 0; i < keys1.length; i++) {
    if (keys1[i] !== keys2[i]) return false;
  }

  return true;
};

exports.ChildrenEqual = ChildrenEqual;
