import React from 'react'
import PropTypes from 'prop-types'

const defaultImage = process.env.PUBLIC_URL + "/images/img--placeholder-product.jpg"
const Image = props => (
  <div className={`image image--${props.type}`} onClick={props.onClick}>
    <img src={`${props.image ? props.image : defaultImage}`} alt={`${props.alt}`} />
  </div>
)

Image.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
  type: PropTypes.oneOf(['default', 'fullwidth'])
};
Image.defaultProps = {
  type: 'default',
  image: process.env.PUBLIC_URL + "/images/img--placeholder-product.jpg"
};

export default Image
