import React, { Component } from "react";

import { AppContext } from "../../data/ContextProvider";

import OrderBreadcrumb from "../../components/element/order-breadcrumb/OrderBreadcrumb";
import Shipping from "./Steps/Shipping";
import Overview from "./Steps/Overview";
import Confirmation from "./Steps/Confirmation";

class Checkout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      deliveryAddress: {},
      error: ""
    }
    this.handleStep = this.handleStep.bind(this)
  }

  handleStep(step, error = null) {
    this.setState({
      currentStep: step,
      error: error
    })
  }

  handleDeliveryAddress = (addr) => {
    this.setState({
      deliveryAddress: addr
    })
  }

  getCurrentStep(step) {
    switch (step) {
      case 1:
        return <Shipping
                  stepFunc={this.handleStep}
                  handleAddress={this.handleDeliveryAddress}
                  selectedAddress={this.state.deliveryAddress}
               />
      case 2:
        return <Overview
                  stepFunc={this.handleStep}
                  address={this.state.deliveryAddress}
                />
      case 3:
        return <Confirmation
                  stepFunc={this.handleStep}
                  error={this.state.error}
              />
      default :
        return <Shipping
                  stepFunc={this.handleStep}
                />
    }
  }

  render() {
    return (
      <>
        <div className={"content cart checkout"}>
          <OrderBreadcrumb step={this.state.currentStep} confirmationError={this.state.error} />

          <div className={`checkout__container checkout__container--step-${this.state.currentStep}`}>
            {this.getCurrentStep(this.state.currentStep)}
          </div>
        </div>
      </>
    );
  }
}

Checkout.contextType = AppContext;

export default Checkout;
