import React from "react";
import Icon from "../../atom/icons/Icon";
import Image from "../../atom/image/Image";

const Lightbox = props => (
  <>
  <div className={"lightbox"}>
    <div className={"lightbox__container"}>
      <span className={"lightbox__actions"} onClick={props.handleLightbox}>
        <Icon icon={"close"} />
      </span>
      <Image image={props.image} alt={"lightbox_image"} />
    </div>
  </div>

  <div className={`main-menu__overlay main-menu__overlay--visible`}>
  </div>
  </>
);

export default Lightbox;
