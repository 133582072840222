import React, { Component } from "react";
import { AppContext } from "../../../data/ContextProvider";
import { getCasino, spinCasinoBandit, getAppTexts, getAppPrefs } from "../../../data/api";
import { APP_CASINO } from "../../../data/constants";
import PraemiencasinoBanner from "./PraemiencasinoBanner";
import PraemiencasinoRanking from "./PraemiencasinoRanking";
import Button from "../../atom/button/Button";
import t from "../../../helpers/t9n";


export class Praemiencasino extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      slots: 0,
      isSpinning: false,
      pageText: null,
    }

    this.timerHandle = null
  }

  loadData = async () => {
    const casino = await getCasino()
    const appPrefs = await getAppPrefs(APP_CASINO)
    const texts  = await getAppTexts(APP_CASINO)

    this.setState({
      slots: casino.slots,
      points_safe: casino.points_safe,
      loading: false,
      pageText: texts,
      appPrefs: appPrefs
    })
  }

  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {
    this.timerHandle && clearTimeout(this.timerHandle)
  }

  handleBanditSpin = () => {
    if (this.state.slots > 0) {
      this.setState({
        isSpinning: !this.state.isSpinning
      }, () => {
        spinCasinoBandit().then(spin => {
          this.timerHandle = setTimeout(() =>
            this.setState({
              isSpinning: !this.state.isSpinning,
              booking_amount: spin.booking_amount,
              slots: spin.slots
            }, () => {
              this.context.setPointsOfUser(spin.points)
              this.loadData()
            }), 1000)
        })
      })
    }
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <div className="banner-section" id={this.props.id}>
          <div className={this.props.isHeaderOpen ? "banner-section-inner banner-section-inner--open" : "banner-section-inner"}>
            <PraemiencasinoBanner
              text={this.state.pageText}
              headline={this.state.appPrefs.name}
              slots={this.state.slots}
              appID={APP_CASINO}
            />

            <PraemiencasinoRanking
              isSpinning={this.state.isSpinning}
              slots={this.state.slots}
              banditValue={this.state.booking_amount}
              safe={this.state.points_safe}
              handleSpinBandit={this.handleBanditSpin}
            />
          </div>

          {this.props.isHeaderOpen ? (
            <Button
              type="secondary"
              text={this.props.buttonOpen}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_CASINO)}
            />
          ) : (
            <Button
              type="secondary"
              text={this.props.buttonClose}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_CASINO)}
            />
          )}
        </div>
      </>
    );
  }
}

Praemiencasino.contextType = AppContext;
export default Praemiencasino;
