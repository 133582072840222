import React, { Component } from 'react'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'

class ControlledLottie extends Component {
  
  constructor(props) {
    super(props);
    this.state = {}
  }
  
  render() {
    
    const defaultOptions = {
      loop: this.props.loop,
      autoplay: true,
      animationData: this.props.animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    
    return (
      <span className={"lottie__controlled"}>
        <Lottie options={defaultOptions}
                isStopped={this.props.isStopped}
                isPaused={this.props.isPaused}
                isClickToPauseDisabled={true}
        />
      </span>
    )
  }
}

ControlledLottie.propTypes = {
  animationData: PropTypes.object,
  isStopped: PropTypes.bool,
  isPaused: PropTypes.bool,
  loop: PropTypes.bool
};

ControlledLottie.defaultProps = {
  isStopped: true,
  isPaused: true,
  loop: true
}
export default ControlledLottie