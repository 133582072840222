import React, { Component } from "react";

import Button from "../../../components/atom/button/Button";
import Header from "../Components/Header";
import RegisterForm from "../Components/RegisterForm";
import RegisterEdit from "../Components/RegisterEdit";
import Stepper from "../Components/Stepper";
import TableSeller from "../Components/TableSeller";
import UserImport from "../Components/UserImport";

import { getCtUsers, getHiddenCtUsers, completeOnBoarding, getTextForKey, hideCtUser } from "../../../data/ct-api";

import t from "../../../helpers/t9n";
import { dangerousHTMLText } from "../../../lib/lib";
import { AppContext } from "../../../data/ContextProvider";


class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: true,
      isOpenRegister: false,
      isOpenUpload: false,
      isOpenRegisterEdit: false,
      isOpenCEORegister: false,
      currentPage: 1,
      activeUser: null,
      isCEOAlreadyRegistered: false,
    };
  }

  handleCEORegister = () => {
    this.setState({
      isOpenCEORegister: !this.state.isOpenCEORegister
    });
  }

  closeRegister = () => {
    this.setState({
      isOpenRegister: false,
      isOpenCEORegister: false,
    });
  }

  handleRegister = () => {
    this.setState({
      isOpenRegister: !this.state.isOpenRegister
    });
  }

  toggleEditFormVisibility = () => {
    this.setState({
      isOpenRegisterEdit: !this.state.isOpenRegisterEdit
    });
  }

  handleEditUser = (user) => {
    this.setState({
      activeUser: user,
      isOpenRegisterEdit: true
    });
  }

  handleHideUser = async (user) => {
    await hideCtUser(user);
    this.loadData();
  }

  handleUpload = () => {
    this.setState({
      isOpenUpload: !this.state.isOpenUpload
    });
  }

  paginate = (pageNumber) => {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this.loadData()
    })
  }

  onImportSuccess = (messages) => {
    console.log(".....", messages)
  }

  onCreateSuccess = () => {
    this.loadData()
  }

  loadData = async () => {
    const page  = this.state.currentPage || 1
    const users = await getCtUsers(page)
    const hiddenUsers = await getHiddenCtUsers(page)
    const pageText = await getTextForKey("registration")



    this.setState({
      users: users.users,
      hiddenUsers: hiddenUsers.users,
      isCEOAlreadyRegistered: (users.users.find((user) => (user.is_ceo))),
      pager: users.meta,
      pageText: pageText,
      loading: false
    })
  }

  async componentDidMount() {
    if (!this.context.user.onboarding_completed) {
      completeOnBoarding()
    }
    this.loadData()
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <div className="compl-tool">

          <Header headline="" />

          {!this.context.user.onboarding_completed &&
            <Stepper activeStep="6" />
          }

          <div className="content content--small">
            <h4>{this.state.pageText.headline}</h4>
            <p dangerouslySetInnerHTML={{ __html: dangerousHTMLText(this.state.pageText.body) }} ></p>
          </div>

          <div className="compl__upload">
            <div className="content">
              <span className="compl-btn">
                <Button
                  type="primary"
                  text="Einzelne Mitarbeitende registrieren"
                  textColor="white"
                  onClick={this.handleRegister}
                  icon="single-seller"
                  additionalClass={this.state.isOpenRegister ? "button--card button--card-active" : "button--card"}
                />
              </span>

              <span className="compl-btn">
                <Button
                  type="primary"
                  text="Mehrere Mitarbeitende registrieren"
                  textColor="white"
                  onClick={this.handleUpload}
                  icon="several-sellers"
                  additionalClass={this.state.isOpenUpload ? "button--card button--card-active" : "button--card"}
                />
              </span>
              <span className="compl-btn">
                <Button
                  type="primary"
                  text="Geschäftsführung registrieren"
                  textColor="white"
                  disabled={this.state.isCEOAlreadyRegistered}
                  onClick={this.handleCEORegister}
                  icon="manager"
                  additionalClass={this.state.isOpenCEORegister ? "button--card button--card-active" : "button--card"}
                />
              </span>
            </div>
          </div>

          {this.state.isOpenRegister &&
            <div className="compl__user__container compl__user__container--open">
              <h2>Mitarbeitende registrieren</h2>
              <RegisterForm handleRegister={this.handleRegister} onSuccess={this.onCreateSuccess} isCEO={false} onClose={this.closeRegister}/>
            </div>
          }

          {this.state.isOpenCEORegister &&
            <div className="compl__user__container compl__user__container--open">
              <h2>Geschäftsführer registrieren</h2>
              <RegisterForm handleRegister={this.handleRegister} onSuccess={this.onCreateSuccess} isCEO={true} onClose={this.closeRegister}/>
            </div>
          }

          {this.state.isOpenUpload &&
            <UserImport onClose={this.handleUpload} onSuccess={this.onImportSuccess}/>
          }

          {!this.state.isOpenRegisterEdit &&
            <div className="compl__info">
              <div className="content">
                <h3>Aktuell angemeldete Mitarbeitende</h3>
                <TableSeller
                  users={this.state.users}
                  pager={this.state.pager}
                  paginate={this.paginate}
                  handleEditUser={this.handleEditUser}
                  handleHideUser={this.handleHideUser}
                  actionsEnabled={true}
                  />
              </div>
            </div>
          }

          {!this.state.isOpenRegisterEdit &&
            <div className="compl__info">
              <div className="content">
                <h3>Abgemeldete / Inaktive Mitarbeitende</h3>
                <TableSeller
                  users={this.state.hiddenUsers}
                  pager={this.state.pager}
                  paginate={this.paginate}
                  handleEditUser={this.handleEditUser}
                  handleHideUser={this.handleHideUser}
                  actionsEnabled={false}
                  />
              </div>
            </div>
          }

          {this.state.isOpenRegisterEdit &&
            <div className="compl__user__container compl__user__container--open">
              <h2>Stammdaten bearbeiten</h2>
              <RegisterEdit
                toggleEditFormVisibility={this.toggleEditFormVisibility}
                onSuccess={this.onCreateSuccess}
                user={this.state.activeUser}
              />
            </div>
          }

        </div>
      </>
    );
  }
}

Registration.contextType = AppContext;
export default Registration;
