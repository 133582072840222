import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/element/header-section/HeaderSection";
import Wishlist from "./Wishlist/Wishlist";
import { AppContext } from "../data/ContextProvider";
import t from "../helpers/t9n";

const WishlistPage = () => {
  let { lang } = useParams();

  const context = useContext(AppContext)

  return (
    <>
      <Header heading={t("menu.wishlist")} hasImage={true} isRed={true} />
      <Wishlist lang={lang} hasWishlistUpdated={context.hasWishlistUpdated} />
    </>
  )
}

export default WishlistPage;
