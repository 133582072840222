import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { Link } from "react-router-dom";
import RadioButton from "../../atom/radio-button/RadioButton";
import t from "../../../helpers/t9n";


class ShippingBox extends Component {

  handleSelectAddress = (address) => {
    this.props.handleSelectAddress(address)
  }

  renderAddress = (address, key) => {
    return (<div className="shipping-box__item" key={key}>
              <span className="shipping-box__check">
                <RadioButton
                  selected={key === 0}
                  name="selected_address"
                  onChange={() => { this.handleSelectAddress(address) }} />
                  </span>
                  <span className="shipping-box__row">
                    <span className="shipping-box__details">
                      <span className="shipping-box__info">
                        {address.salutation}&nbsp;{address.first_name}&nbsp;{address.last_name}
                      </span>
                      <span className="shipping-box__info">{address.company}</span>
                      <span className="shipping-box__info">{address.street} {address.house_number}</span>
                      <span className="shipping-box__info">
                        <span>{address.zip} {address.city}</span>
                        { /*
                        <Button
                          type="noOutline"
                          text="Entfernen"
                          icon="garbage"
                          onClick={() => this.handleDeleteAddress(address.id)}
                        />
                        */ }
                      </span>
                    </span>
                  </span>
                </div>
        )
  }

  render() {
    return (
      <div className={`shipping-box shipping-box--${this.props.type}`}>
        <div className="shipping-box__content">
          <span className="shipping-box__heading">{t("checkout.choose.adress")}</span>
            <div className="shipping-box__container">
              {
                this.props.addresses.map((address, index) => {
                  return this.renderAddress(address, index)
                })
              }
            </div>
        </div>
        <div className="shipping-box__actions">
            <Button
              text={t("checkout.choose.adress.button.send")}
              textColor="white"
              type="primary"
              onClick={() => this.props.handleAddress()}
            />
            <br/>
            <br/>
            {!this.props.user.order_delivery_method &&
              <Link to={`/${window.currentLang}/profile`}>
                <Button
                  text={t("checkout.choose.adress.button.new")}
                  type="secondary"
                />
              </Link>
            }
          </div>
      </div>
    );
  }
}

export default ShippingBox;
