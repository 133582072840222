import React, { Component } from "react";
import Button from "../../../components/atom/button/Button";
import Icon from "../../../components/atom/icons/Icon";
import SimplePagination from "../../../components/atom/pagination/SimplePagination";
import { getContracts } from "../../../data/api/contracts";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";
import { formatDate } from "../../../lib/lib";
import { withRouter } from "react-router-dom";

class ArchiveContractsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      query: "",
      data: null,
      pagination: {
        currentPage: 1,
        prevPage: 1,
        nextPage: 1,
        itemsPerPage: 10
      },
      view_mode: "archived"
    };
  }

  doSearch = () => {
    this.setState({
      currentPage: 1
    }, async () => {
      await this.loadData()
    })
  }

  handleSearch = (e) => {
    this.setState({
      query: e.target.value
    })
  }

  paginate = (pageNumber) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        currentPage: pageNumber
      },
      data: null
    },
    async () => {
      await this.loadData()
    })
  }

  loadData = async () => {
    try {
      const data = await getContracts(this.state.pagination.currentPage, this.state.view_mode, this.state.status, this.state.query)

      this.setState({
        ...this.state,
        loading: false,
        data: data.contracts,
        pagination: {
          ...this.state.pagination,
          prevPage: data.meta.prev_page,
          currentPage: data.meta.current_page,
          nextPage: data.meta.next_page,
          totalPages: data.meta.total_pages
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  async componentDidMount() {
    if (this.context.getUserData().contracts_enabled) {
      await this.loadData()
    }
  }

  render() {
    if (this.state.data === null || this.state.loading) return t("global.loading");

    // const contracts = this.state.data;

    return (
      <div className="contracts">
        <div className="contracts__content">
          <h2>
            <Button
              type="primary"
              onClick={() => {
                this.props.history.push(`/${window.currentLang}/contracts/new`);
              }}
              icon="arrow"
            />

            Abgeschlossene Verträge
          </h2>

          <div className="contracts__left">
            {/* Table Start */}
            <div className="contracts__table contracts__table--closed">
              {this.state.data.length > 0 ? (
                <>
                  <div className="contracts__head">
                    <div className="contracts__row">
                      <div className="contracts__cell"></div>
                      <div className="contracts__cell">Zuordnung</div>
                      <div className="contracts__cell">Buchungstext</div>
                      <div className="contracts__cell">Punkte</div>
                      <div className="contracts__cell">Vorname</div>
                      <div className="contracts__cell">Nachname</div>
                    </div>
                  </div>

                  <div className="contracts__body">
                    {this.state.data.map(item => (
                      <div
                        className="contracts__row"
                        key={item.id}
                      >
                        <div className="contracts__cell">
                          <span className="item-index">
                            <Icon icon="check" additionalClass="active" />
                          </span>
                        </div>
                        <div className="contracts__cell">{item.assigned_at ? formatDate(item.assigned_at, false) : ''}</div>
                          <div className="contracts__cell"><span className="shorten-txt">{item.description}</span></div>
                          <div className="contracts__cell">{Number(item.points).toLocaleString()} PKT</div>
                          <div className="contracts__cell">{(item.user) ? item.user.first_name : ''}</div>
                          <div className="contracts__cell">{(item.user) ? item.user.last_name : ''}</div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="contracts__empty">
                  <h3>Keine Einträge vorhanden</h3>
                </div>
              )}
            </div>
            {/* Table End */}

            {this.state.data.length > 0 ? (
              <div className="contracts__pagination">
                <SimplePagination
                  currentPage={this.state.pagination.currentPage}
                  prevPage={this.state.pagination.prevPage}
                  nextPage={this.state.pagination.nextPage}
                  paginate={this.paginate}
                  totalPages={this.state.pagination.totalPages}
                />
              </div>
            ) : (
              null
            )}
          </div>

          {this.state.data.length > 0 ? (
            <div className="contracts__right">
              <div className="contracts__box">
                <input
                  type="search"
                  placeholder="Suchbegriff..."
                  onChange={this.handleSearch}
                  value={this.state.query}
                />
                <Button
                  type="secondary"
                  text="Suchen"
                  textColor="white"
                  icon="search"
                  onClick={this.doSearch}
                />
              </div>
            </div>
          ) : (
            null
          )}
        </div>
      </div>
    );
  }
}

ArchiveContractsTable.contextType = AppContext;
export default withRouter(ArchiveContractsTable);
