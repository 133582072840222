import React, { Component } from "react"
import { withRouter } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import t from "../../../helpers/t9n";

export class AppsNavigation extends Component {

  onClick = (id) => {
    this.props.toggleHeader(id)
  }

  render() {
    return (
      <>
        {this.props.apps.length ? (
          <div className="content-wrapper">
            <div className="content-wrapper-inner">
              <div className="apps-navigation">
                <ul>
                  {this.props.apps.map(app => (
                    <li key={app.component.id}>
                      <div className="apps-navigation__title">
                        <h3>
                          <span className="apps-navigation__icon">
                            {app.component.icon}
                          </span>
                          <span className="apps-navigation__text">{app.data.name}</span>
                        </h3>
                      </div>
                      <div className="apps-navigation__buttons">
                        <Link to={`/${window.currentLang}/rules/${app.data.app_id}`}>
                          {t("global.rules.short")}
                        </Link>
                        <Link className="button button--primary button--white" to={`/${window.currentLang}/#${app.data.app_id}`} onClick={() => this.onClick(app.data.app_id)}>
                          {t("global.homepage.start")}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default withRouter(AppsNavigation);
