import React, { Component } from "react";
import t from "../../../helpers/t9n";
import { dateDiff } from "../../../helpers/utils";
import Button from "../../atom/button/Button";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import CounterSpawner from "../../element/counter-spawner/CounterSpawner";
import ProgressCircle from "../../element/progress-circle/ProgressCircle";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";

export class NeukundenjaegerRanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startNr: 0,
      amount: 0,
      current_points: 0,
      remaining_target: 0,
      data: {},
      showAllRankings: false,
      dateEndDiff: null,
      dateStartToEndDiff: null
    }
  }

  renderRankings = () => {
    const ret = [];
    let currentIndex = 0;

    if (this.state.data && this.state.data.rankings) {
      this.state.data.rankings.map((rank, index) => {
        index = ret.push(
          <div
            className={`neukunden-performance__item ${
              Number(rank.amount) === Number(this.state.data.current_points)
                ? "neukunden-performance__item--current"
                : ""
            }`}
            key={index}
          >
            <span>
              {Number(rank.from).toLocaleString()} -{" "}
              {Number(rank.to).toLocaleString()} {t("app.costumer")}
            </span>
            <span>
              {Number(rank.amount).toLocaleString()} <sup>{t("app.points")}</sup>
            </span>
          </div>
        );

        if (Number(rank.amount) === Number(this.state.data.current_points)) {
          currentIndex = index;
        }

        return true;
      });
    }

    if (this.state.showAllRankings) {
      return ret;
    } else {
      // too simple?
      return ret.slice(
        currentIndex > 2 ? currentIndex - 2 : 0,
        currentIndex > 1 ? currentIndex + 1 : currentIndex + 2
      );
    }
  }

  componentDidMount() {
    if (this.props.end && this.props.start) {
      this.setState({
        amount: ~~this.props.performance.performance,
        current_points: ~~this.props.performance.current_points,
        remaining_target: ~~this.props.performance.remaining_target < 0 ? 0 : ~~this.props.performance.remaining_target,
        data: this.props.performance,
        showAllRankings: false,
        dateEndDiff: dateDiff(this.props.end),

        // abs days for maxvalue
        dateStartToEndDiff: dateDiff(
          this.props.end,
          new Date(this.props.start)
        )
      })
    }
  }

  render() {

    if (!this.state.dateEndDiff || !this.state.dateStartToEndDiff) {
      return t("global.loading")
    }

    return (
      <>
        <div className="neukunden-performance__section">
          <div className="neukunden-performance__content">
            <div className="neukunden-performance__wrapper">
              <div className="neukunden-performance__box neukunden-performance__box--barometer">
                <h3>{t("app.new.costumer.barometer")}</h3>
                <div className="neukunden-performance__box-container">
                  <CounterSpawner
                    amount={this.state.amount}
                    digits={3}
                    type="highlight"
                  />
                  <div>
                    <span className="neukunden-performance__box-type">
                      <p>
                        <b>{t("app.already.achieved")}</b>
                        <br />
                        {t("app.acquired.new.customers")}
                      </p>
                    </span>
                    {this.state.current_points > 0 && (
                      <span className="neukunden-performance__congrats">
                        <UncontrolledLottie animationData={confetti} />
                      </span>
                    )}
                  </div>
                </div>
                <div className="neukunden-performance__box-container">
                  <CounterSpawner
                    amount={this.state.remaining_target}
                    digits={3}
                    type="standard"
                  />
                  <span className="neukunden-performance__box-type">
                    <p>
                      <b>{t("app.contracts.missing")}</b>
                    </p>
                  </span>
                </div>
              </div>

              <div className= "neukunden-performance__box neukunden-performance__box--list">
                <div className="neukunden-performance__box-container neukunden-performance__box-container--list">
                  <span className="neukunden-performance__box-header">
                    <div>
                      <h4>{t("app.number.new.costumers")}</h4>
                    </div>
                    <div>
                      <h4>{t("app.gain.points")}</h4>
                    </div>
                  </span>

                  <div
                    className={`neukunden-performance__wrapper ${
                      this.state.data && this.state.data.rankings && this.state.data.rankings.length > 3
                        ? "neukunden-performance__wrapper--scroll"
                        : ""
                    }`}
                  >
                    {this.renderRankings()}
                  </div>
                </div>
                <Button
                  type="secondary"
                  text={t("app.all.win.steps")}
                  textColor="white"
                  onClick={() =>
                    this.setState({
                      showAllRankings: !this.state.showAllRankings,
                    })
                  }
                />
              </div>
            </div>

            <span className="card-slider__header">
              <h2 className="card-slider__label">{t("app.remaining.time")}</h2>
            </span>
            <div className="neukunden-performance__box neukunden-performance__box--timer">
              <span>
                <ProgressCircle
                  value={this.state.dateEndDiff.days}
                  maxValue={this.state.dateStartToEndDiff.days}
                  isCountDown={true}
                  text={t("app.days")}
                />
                <ProgressCircle
                  value={this.state.dateEndDiff.hours}
                  maxValue={24}
                  text={t("app.hours")}
                />
                <ProgressCircle
                  value={this.state.dateEndDiff.minutes}
                  maxValue={60}
                  text={t("app.mins")}
                />
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NeukundenjaegerRanking;
