import React, { useContext } from "react";

import "../sass/styles.sass";
import Navbar from "../components/element/navbar/Navbar";
import Footer from "../components/element/footer/Footer";
import { AppContext } from "../data/ContextProvider";

function WishlistLayout({ children }) {
  
  const context = useContext(AppContext);
  
  return (
    <React.Fragment>
      <Navbar bgColor={"black"} isScrolling={true} />
      
      <div className={`container container--orders ${context.getProjectData().global_jackpot_active ? "container--jackpot-counter" : ""}`}>
        {children}
      </div>
      
      <div className={`popup-cart__overlay ${context.isPopupCartVisible ? "popup-cart__overlay--visible" : ""}`}>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default WishlistLayout;
