import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Textfield from "../../../components/atom/textfield/Textfield";
import Button from "../../../components/atom/button/Button";
import UncontrolledLottie from "../../../components/atom/lottie/UncontrolledLottie";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";

import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      passwordResetSuccess: false,
      errors: {
        form: false,
        username: "",
        password: "",
      }
    };
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const errors = this.state.errors;

    switch (name) {
      case 'username':
        errors.username =
          value.length < 5
            ? 'Username must be 5 characters long!'
            : '';
        break;
      case 'password':
        errors.password =
          value.length < 8
            ? 'Password must be 8 characters long!'
            : '';
        break;
      default:
        break;
    }

    this.setState({
      errors,
      [name]: value
    }, () => {
      console.log(errors)
    })
  }

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }

  componentDidMount() {
    if (this.props.location.search) {
      if (this.props.location.search === '?s=1') {
        this.setState({ passwordResetSuccess: true })
      }
    }
  }

  render() {
    return (
      <div className={"login__container--right"}>

        {this.state.passwordResetSuccess &&
          <div style={{ marginTop: 20 }}>
            <span className={"reset-success__icon"}>
                <UncontrolledLottie animationData={confetti} />
            </span>
            <div className="login__row" style={{marginBottom:20}}>
              <p className="login__error-text">{t("login.password_reset.success")}</p>
            </div>
          </div>
        }

        <h2>{t("login.h2.login")}</h2>
        <form name={"login-form"} className={"login-form"} onSubmit={(e) => this.props.submitFunc(e, 1)}>
          <Textfield label={t("login.label.username")} name="email" placeholder={t("login.input.username")}
            // errorMsg={errors.username}
                    errorMsg={this.props.error}
                    isRequired={true}
                    handleChange={(e) => this.props.handleChange(e)} />
          <Textfield label={t("login.label.password")} name="password" placeholder={t("login.input.password")}
                    inputType={"password"}
                    isRequired={true}
                    errorMsg={this.props.error}
                    handleChange={(e) => this.props.handleChange(e)}
          />
          <span className="login__action">
            <Button type={"primary"} width={"fullwidth"} textColor={"white"} text={t("login.button")} />
          </span>
        </form>


        {this.props.error ?
          <>
            <p className={"login__error-text"}>
              {t("login.error.info")}
            </p>
          </>
          : ""
        }

        <p className={"login__help-text"}>
          {t("login.forgotten.password")}
          <br />
          <Button type={"link"}
                  text={t("login.new.password")}
                  onClick={(e) => this.props.handlePasswordReset(e)}
                  textColor={"black"} />
          <br />
          <br />
          {t("login.contact.support")}
          {/* <a href={`mailto:${this.context.projectSupportMail}`}>{this.context.projectSupportMail}</a> */}
        </p>

        <span className={"login__links"}>
          <a href={`/${window.currentLang}/compliance-tool`}>{t("menu.home")}</a>
          <a href={`/${window.currentLang}/compliance-tool/pub/conditions`}>{t("menu.conditions.participation")}</a>
          <a href={`/${window.currentLang}/compliance-tool/pub/privacy`}>{t("login.data.protection")}</a>
          <a href={`/${window.currentLang}/compliance-tool/imprint`}>{t("login.imprint")}</a>
        </span>
      </div>
    );
  }
}

Step1.contextType = AppContext;
export default withRouter(Step1);
