import React, { Component } from "react";
import { Link } from "react-router-dom";
import t from "../../../helpers/t9n";
import { dateDiff, dateExpired } from "../../../helpers/utils";
import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import CounterSpawner from "../../element/counter-spawner/CounterSpawner";
import ProgressCircle from "../../element/progress-circle/ProgressCircle";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";

export class SalesAttackRanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startNr: 0,
      dateEndDiff: null,
      dateStartToEndDiff: null,
      dateExpired: null,
      reachedGoal: false,
      headerSlider: [],
    };

    this.timerHandle = null;
    this.loaderHandle = null;

    this.loaderIndex = 0;
    this.endingNumber = 0;
  }

  componentDidMount() {
    let loadingBarValues = [];

    if (this.props.end && this.props.start) {
      this.setState({
        dateEndDiff: dateDiff(this.props.end),
        dateStartToEndDiff: dateDiff(this.props.end, new Date(this.props.start)),
        dateExpired: dateExpired(this.props.end),
      })
    }


    if (this.percent) {
      if (Math.ceil(Math.log10(this.props.transactions.percent + 1)) >= 3) {

        //3 digits = over 100
        const hundrets = this.props.transactions.percent / 100;
        for (let i = 1; i <= hundrets; i++) {
          loadingBarValues.push(100);
        }
        loadingBarValues.push(
          Math.floor(
            this.props.transactions.percent - Math.floor(hundrets) * 100
          )
        );

        this.loadUp(loadingBarValues, 0);
      } else {
        this.loadUp([this.props.transactions.percent], 0);
      }
    }
  }

  loadUp = (values, count = 0) => {
    this.loader.style.width = count + "%";

    if (this.loaderIndex > 0) {
      this.percent.innerHTML = count + this.loaderIndex * 100 + "%";
    } else {
      this.percent.innerHTML = count + "%";
    }

    this.endingNumber = values[this.loaderIndex];

    if (count < this.endingNumber) {
      this.loaderHandle = setTimeout(() => this.loadUp(values, count + 1), 20);
    } else {
      if (typeof values[this.loaderIndex + 1] !== "undefined") {
        this.loader.style.width = 0 + "%";
        this.setState({
          reachedGoal: true,
        });
        this.loaderIndex += 1;
        this.loadUp(values, 0);
      }
    }
  };

  componentWillUnmount() {
    this.timerHandle && clearTimeout(this.timerHandle);
    this.loaderHandle && clearTimeout(this.loaderHandle);
  }

  countUp = (count) => {
    this.percent.innerHTML = count + "%";

    if (count < this.props.transactions.percent) {
      this.timerHandle = setTimeout(() => this.countUp(count + 1), 10);
    }
  };

  render() {
    const { currencyMode } = this.props;
    return (
      <>
        <div className="salespush-performance__section">
          <div className="salespush-performance__content">
            <div className="salespush-performance__box salespush-performance__box--percent">
              <h3>{t("app.your.performance")}</h3>
              <div className="salespush-performance__box-container">
                <div>
                  <div className={`salespush-performance__loader ${ this.state.reachedGoal ? "salespush-performance__loader--second" : "" }`}>
                    <span ref={(loader) => (this.loader = loader)} className="salespush-performance__loader-indicator" />
                  </div>
                  <span className="salespush-performance__box-percent" ref={(percent) => (this.percent = percent)}> 0% </span>
                  {this.state.endNr >= 100 && (
                    <span className="neukunden-performance__congrats">
                      <UncontrolledLottie animationData={confetti} />
                    </span>
                  )}
                </div>
                {this.props.remaining_target > 0 ? (
                  <p>
                    {t("app.lets.go")}
                    <b>
                      {Number(this.props.remaining_target).toLocaleString()}{" "}
                      {currencyMode === "contracts" ? t("app.contracts") : t("app.currency_points")}
                    </b>
                    {t("app.next.win")}
                  </p>
                ) : (
                  <p>
                    <b>{t("app.your.target.reached")}</b>
                  </p>
                )}
              </div>
            </div>

            <div className="salespush-performance__box">
              <h3>{t("app.attack")}</h3>
              <div className="salespush-performance__box-container">
                <span className="salespush-performance__box-wrapper">
                  <CounterSpawner
                    amount={ this.props.transactions.target ? this.props.transactions.target : 0 }
                    type="standard"
                    digits={currencyMode === "contracts" ? 4 : 7}
                  />
                  <span className="salespush-performance__box-type">
                    <p>
                      <b>{t("app.goals.target")}</b>
                      <br />
                      {currencyMode === "contracts" ? t("app.contracts.number") : t("app.sales")}
                    </p>
                  </span>
                </span>
                <span className="salespush-performance__box-wrapper">
                  <CounterSpawner
                    amount={Number(this.props.transactions.transactions)}
                    type={"highlight"}
                    digits={currencyMode === "contracts" ? 4 : 7}
                  />
                  <span className="salespush-performance__box-type">
                    <p>
                      <b>{t("app.you.achieved")}</b>
                      <br />
                      {currencyMode === "contracts" ? t("app.contracts.number") : t("app.sales")}
                    </p>
                  </span>
                </span>
              </div>
            </div>

            <div className="salespush-performance__box salespush-performance__box--timer">
              <h3>{t("app.remaining.time")}</h3>
              {this.state.dateEndDiff &&
              <span>
                <ProgressCircle
                  value={this.state.dateEndDiff.days}
                  maxValue={this.state.dateStartToEndDiff.days}
                  isCountDown={true}
                  text={t("app.days")}
                />
                <ProgressCircle
                  value={this.state.dateEndDiff.hours}
                  maxValue={24}
                  text={t("app.hours")}
                />
                <ProgressCircle
                  value={this.state.dateEndDiff.minutes}
                  maxValue={60}
                  text={t("app.mins")}
                />
              </span>
              }
            </div>

            <div className="salespush-performance__box salespush-performance__box--list">
              <div className="salespush-performance__box-container salespush-performance__box-container--list">
                <h3>{t("app.win.levels")}</h3>
                <span className="salespush-performance__box-header">
                  <div>
                    <span>
                      <Icon icon={"star"} />
                    </span>
                    <h3>{t("app.goals")}</h3>
                  </div>
                  <div>
                    <span>
                      <Icon icon={"star"} />
                    </span>
                    <h3>{currencyMode === "contracts" ? t("app.contracts") : t("app.sales")}</h3>
                  </div>
                  <div>
                    <span>
                      <Icon icon={"star"} />
                    </span>
                    <h3>{t("app.profit")}</h3>
                  </div>
                </span>
                <div className={`salespush-performance__wrapper ${this.props.ranking_levels.length > 3 ? "salespush-performance__wrapper--scroll" : ""}`}>
                  {this.props.ranking_levels.map((rank) => (
                    <div
                      key={rank.from}
                      className={`salespush-performance__item ${
                        Number(rank.amount) ===
                        Number(this.props.current_points) ? "salespush-performance__item--current" : ""}
                      `}
                    >
                      <span>{rank.from} %</span>
                      <span>{Number(rank.to).toLocaleString()}</span>
                      <span>
                        {Number(rank.amount).toLocaleString()} {t("app.points")}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <span className={"salespush-performance__actions"}>
              <Link to={`/${window.currentLang}/account`}>
                <Button
                  type={"secondary"}
                  text={t("global.points.account")}
                  textColor={"white"}
                />
              </Link>
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default SalesAttackRanking;
