import { Switch } from 'react-router-dom';
import React, { Fragment } from 'react';

export default function SwitchFragmentSupport({ children }) {
  const childrenWithoutFragment = [];
  removeFragments(childrenWithoutFragment, children);
  return React.createElement.apply(React, [Switch, null].concat(childrenWithoutFragment));
}

function removeFragments(result, children) {
  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      if (child.type === Fragment) {
        removeFragments(result, child.props.children);
      } else {
        result.push(child);
      }
    }
  });
}