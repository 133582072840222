import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../data/ContextProvider';
import t from '../../../helpers/t9n';
import Button from '../../atom/button/Button';
import Icon from '../../atom/icons/Icon';
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import salestriathlon from "../../../assets/lotties/salestriathlon/anim-salestriathlon";

import { bodyForLang } from "../../../lib/lib";

export class SalesTriathlonBanner extends Component {
  render() {
    const texts = this.props.text

    let subline = ""
    let body = ""
    let headline = ""

    texts.forEach(text => {
      if (text.key === 'p33_header_content_headline') {
        headline = text
      }
      if (text.key === 'p33_header_content_subline') {
        subline = text
      }
      if (text.key === 'p33_upper_content_text') {
        body = text
      }
    })

    return (
      <>
        <div className="app-header__section salestriathlon-header__section app-header__section--open">
          <video loop muted autoPlay playsInline>
            <source
              src={`${process.env.PUBLIC_URL}/videos/SalesAttack/anim--red-dessert-bg.mp4`}
              type="video/mp4"
            />
            {t("app.video.no.support")}
          </video>

          <div className="app-header__content">
            <div className="app-header__left">
              <span className="app-header__heading">
                <div>
                  <span>
                    <Icon icon="app-salestriathlon" />
                  </span>
                  <h1>{bodyForLang(headline, window.currentLang)}</h1>
                </div>
                <h2>{bodyForLang(subline, window.currentLang)}</h2>
              </span>
              <p dangerouslySetInnerHTML={{ __html: bodyForLang(body, window.currentLang) }}></p>
              <span className="app-header__actions">
                <Button
                  type="secondary"
                  text={t("global.rules")}
                  textColor="black"
                  backgroundColor="bg-white"
                  onClick={() => {
                    this.props.history.push(`/${window.currentLang}/rules/${this.props.appID}`);
                  }}
                />
              </span>
            </div>
          </div>

          <span className="app-header__box app-header__box--triathlon">
            <UncontrolledLottie animationData={salestriathlon} />
          </span>
        </div>
      </>
    )
  }
}

SalesTriathlonBanner.contextType = AppContext;
export default withRouter(SalesTriathlonBanner);
