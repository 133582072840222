import React, { Component } from "react";
import PerformancedataTable from "./PerformancedataTable";

class Performancedata extends Component {
  render() {
    return (
      <>
        <div className={"content private"}>
          <PerformancedataTable />
        </div>
      </>
    );
  }
}

export default Performancedata;
