import React, { Component } from "react";

import { AppContext } from "../../../data/ContextProvider";
import {
  getAppPrefs,
  getAppTexts,
  getTransactionsForSalesMarathon,
} from "../../../data/api";

import { SalesMarathonBanner } from "./SalesMarathonBanner";
import SalesMarathonRanking from "./SalesMarathonRanking";
import { APP_SALESMARATHON } from "../../../data/constants";

import Button from "../../atom/button/Button";
import t from "../../../helpers/t9n";

export class SalesMarathon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  async componentDidMount() {
    const appPrefs = await getAppPrefs(APP_SALESMARATHON)
    const texts    = await getAppTexts(APP_SALESMARATHON)

    const transactions = await getTransactionsForSalesMarathon()

    this.setState({
      transactions: transactions.transactions,
      appPrefs: appPrefs,
      pageText: texts,
      loading: false
    })
  }

  render() {
    if (this.state.loading) {
      return t("global.loading");
    }

    return (
      <>
        <div className="banner-section" id={this.props.id}>
          <div className={this.props.isHeaderOpen ? "banner-section-inner banner-section-inner--open" : "banner-section-inner"}>
            <SalesMarathonBanner
              text={this.state.pageText}
              headline={this.state.appPrefs.name}
              appID={APP_SALESMARATHON}
            />


            <SalesMarathonRanking
              transactions={this.state.transactions}
              currencyMode={this.state.appPrefs.currency_mode}
              start={this.state.appPrefs.start}
              end={this.state.appPrefs.end}
            />
          </div>

          {this.props.isHeaderOpen ? (
            <Button
              type="secondary"
              text={this.props.buttonOpen}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_SALESMARATHON)}
            />
          ) : (
            <Button
              type="secondary"
              text={this.props.buttonClose}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_SALESMARATHON)}
            />
          )}
        </div>
      </>
    );
  }
}

SalesMarathon.contextType = AppContext;
export default SalesMarathon;
