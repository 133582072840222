import React, { Component } from "react"
import { AppContext } from "../../data/ContextProvider"
import { CONTENT_LIEFERBEDINGUNGEN } from "../../data/constants";

import { getContentPage } from "../../data/api"
import { bodyForLang } from "../../lib/lib"
import t from "../../helpers/t9n"

class Shipping extends Component {

  constructor(props) {
    super(props);
    this.state = {
      headline: "",
      body: "",
      loading: true
    }
  }

  async componentDidMount() {

    let headline = ""
    let body = ""

    const page = await getContentPage(CONTENT_LIEFERBEDINGUNGEN, this.context.getProjectData().id, this.context.getProjectData().app_id)

    page.text.forEach(text => {
      if (text.key === 'p23_header_content_headline') {
        headline = text
      }
      if (text.key === 'p23_upper_content_text') {
        body = text
      }
    })

    this.setState({
      headline: headline,
      body: body,
      loading: false
    })
  }

  render() {
    if (this.loading) {
      return t("global.loading")
    }

    return (
      <>
        <div className={"content privacy tos"}>
          <h1>{bodyForLang(this.state.headline, window.currentLang)}</h1>

          <p
            dangerouslySetInnerHTML={{
              __html: bodyForLang(this.state.body, window.currentLang)
            }}>
          </p>

        </div>
      </>
    )
  }
}

Shipping.contextType = AppContext
export default Shipping;
