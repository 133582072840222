import React, { Component } from "react";
import TextLink from "../../components/atom/textlink/textlink";
import Image from "../../components/atom/image/Image";
import Icon from "../../components/atom/icons/Icon";
import Selectfield from "../../components/atom/selectfield/Selectfield";
import Button from "../../components/atom/button/Button";
import CardSlider from "../../components/module/card-slider/CardSlider";
import { AppContext } from "../../data/ContextProvider";
import ButtonWishlist from "../../components/atom/button-wishlist/ButtonWishlist";
import { getProductById } from "../../data/api";
import { getToken } from "../../lib/lib";
import { withRouter } from "react-router-dom";
import TinySlider from "../../vendor/tiny-slider-react/build";
import Lightbox from "../../components/element/lightbox/Lightbox";
import { getProductPrice, renderTitle, renderGroup, renderDescription, getDeliveryDate} from "../../helpers/utils";
import t from "../../helpers/t9n";

class ProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      product: {},
      currentIndex: 1,
      selectedVariant: {},
      selectedGalleryImage: 1,
      selectedQuantity: 1,
      isReadMoreOpen: false,
      isLightBoxVisible: false,
      settings: {
        loop: false,
        lazyload: false,
        nav: false,
        mouseDrag: true,
        controls: false,
        slideBy: 1,
        arrowKeys: false,
        startIndex: 0,
        items: 1,
        responsive: {}
      },
    }
  }

  componentDidUpdate(prevProps, prevState, snap) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.location.reload();
    }
  }

  handleVariant = (variant) => {
    //Kill Slider before overwriting images
    this.ps.slider = null

    this.setState({
      selectedVariant: variant,
      selectedGalleryImage: variant.images[0].id,
      selectedGalleryImageUrl: variant.images[0].url
    })
  }

  handleGallery(id, url) {
    this.setState({
      selectedGalleryImage: id,
      selectedGalleryImageUrl: url
    })
  }

  handleQuantity = (e) => {
    const quantity = e.target.value
    this.setState({
      selectedQuantity: quantity
    })
  };

  handleReadMore() {
    this.setState({
      isReadMoreOpen: !this.state.isReadMoreOpen
    })
  }

  async componentDidMount() {

    if (!this.props.id)
      this.props.history.push("/shop")

    const token = getToken();
    const productRecord = await getProductById(token, this.props.id);

    const product = productRecord.product

    if (product) {
      // product.options && product.options.forEach((option, i) => {
      //   option.images = option.images.filter(function (el) {
      //     return el.style === "thumb_huge"
      //   });
      // })
    } else {
      this.props.history.push(`/${window.currentLang}/shop`)
      return
    }


    const images = product.options[0] && product.options[0].images;

    this.setState({
      product: product,
      selectedGalleryImage: images && images[0].id,
      selectedGalleryImageUrl: images && images[0].url,
      selectedVariant: product.options[0],
      loading: false
    })
  }

  getProductSlider = () => {
    const images = this.state.selectedVariant ? [...this.state.selectedVariant.images] : [{
      id: 1,
      url: process.env.PUBLIC_URL + "/images/img--placeholder-product.jpg"
    }]
    return (
      <TinySlider settings={this.state.settings}
                  ref={ps => (this.ps = ps)}
                  onIndexChanged={this.setCurrentIndex}>
                      {images.map((image) => {
                        return <span key={"slider_" + image.id} className={"product-view__image-big--single"}>
                            <Image type={"default"} image={image.url} alt={`Variant ${image.id}`} />
                          </span>
                      })}
      </TinySlider>
    );
  }

  setCurrentIndex = (info, eventName) => {
    this.setState({
      currentIndex: info.index + 1
    })
  }

  handleLightbox = () => {
    this.setState({
      isLightBoxVisible: !this.state.isLightBoxVisible
    })
  }

  render() {

    if (this.state.loading) {
      return t("global.loading")
    }

    const variant = { ...this.state.selectedVariant }
    const images = this.state.selectedVariant ? [...this.state.selectedVariant.images] : [{
      id: 1,
      url: process.env.PUBLIC_URL + "/images/img--placeholder-product.jpg"
    }]
    const image_count = images && images.length
    const isWished = this.state.product.wish

    return (
      <>
        <div className="content product-view">
          <div className="shop__top">
            <span className="shop__breadcrumb">
              <TextLink url={`/${window.currentLang}/shop`} text={t("app.back.to.overview")} />
            </span>
          </div>
          <div className={"product-view__container"}>
            <div className={"product-view__upper"}>
              <div className={"product-view__left"}>
                <div className={"product-view__gallery"}>
                  <span className="product-view__image-row">
                    <span className={"product-view__image-row-wrapper"}>
                      {
                        images.map((image) => {
                          return <span
                            key={image.id}
                            className={`product-view__image
                            ${this.state.selectedGalleryImage === image.id ? "product-view__image--selected" : ""}`}
                            onClick={() => this.handleGallery(image.id, image.url)}>
                            <Image type={"default"} image={image.url} alt={`Variant ${image.id}`} />
                          </span>
                        })
                      }
                    </span>
                    {images.length > 4 &&
                    <span className={"product-view__more-below"}>
                      <Icon icon={"arrow"} />
                    </span>
                    }
                  </span>

                  <div className={"product-view__image-big product-view__image-big--mobile"}>

                    {this.getProductSlider()}

                    <span className={"product-view__slider-count"}>
                      {this.state.currentIndex}
                      <span>&nbsp;/&nbsp;</span>
                      {image_count ? image_count : 0}
                    </span>
                  </div>

                  <span className="product-view__image-big" onClick={() => this.handleLightbox()}>
                    <Image type={"default"}
                           image={this.state.selectedGalleryImageUrl}
                           alt={`Variant ${this.state.selectedGalleryImage}`} />
                  </span>
                </div>
              </div>

              <div className="product-view__right">
                <span className="details__category">{t("app.category")}
                  {renderGroup(this.state.product)}
                </span>
                <h2 className="product-headline">{renderTitle(variant)}</h2>
                <span className={"details__id"}>{variant.article_number}</span>
                <span
                  className={"details__price"}>{Number(getProductPrice(variant, this.context.getUserData().project_code)).toLocaleString()} {t("app.points")}</span>
                <div className={"details__variants"}>
                  <span className={"details__variant-label"}><b>{t("app.award.variant")}</b></span>
                  <span className={"details__variant-container"}>
                    {
                      this.state.product.options.map((opt, index) => {
                        return <span
                          key={index}
                          className={`details__variant-selector ${variant.id === opt.id ? "variant--selected" : ""}`}
                          onClick={() => this.handleVariant(opt)}>
                            <Image type={"default"} alt={`Variant ${opt.id}`} image={opt.images[0].url} />
                          </span>
                      })
                    }
                  </span>
                  <span className={"product__availability"}>
                    <Icon icon={"cart-packages"} />
                    <span className={"product__availability-label"}>
                      {getDeliveryDate(variant.availability_state)}
                    </span>
                  </span>
                </div>
                <div className={"details__actions"}>
                  <span className={"details__actions--left"}>
                    <Selectfield isQuantity={true} placeholder={"1"} onSelect={this.handleQuantity} />
                  </span>
                  <span className={"details__actions--right"}>
                    <Button type={"primary"}
                            text={t("app.put.in.cart")}
                            icon={"shopping-cart-full"}
                            textColor={"white"}
                      onClick={() => this.context.handleAddToCart(variant.id, this.state.selectedQuantity)} />
                  </span>
                  <ButtonWishlist isWished={isWished} productId={this.state.product.id} variantId={variant.id} />
                </div>
                <span className={"details__actions--right--mobile"}>
                    <Button type={"primary"}
                            text={t("app.put.in.cart")}
                            icon={"shopping-cart-full"}
                            textColor={"white"}
                    onClick={() => this.context.handleAddToCart(variant.id, this.state.selectedQuantity)} />
                </span>
                <div
                  className={`details__description ${this.state.isReadMoreOpen ? "details__description--open" : ""}`}>
                  <h3>{t("app.award.despcription")}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderDescription(this.state.product, variant)
                    }}>
                  </p>

                  <span className="read-more" />
                </div>
                <span className={"read-more__action"} onClick={() => this.handleReadMore()}>
                  <Icon icon={"arrow"} />
                </span>
              </div>
            </div>
            <div className="details__description--mobile__wrapper">
              <div
                className={`details__description details__description--mobile ${this.state.isReadMoreOpen ? "details__description--open" : ""}`}>
                    <h3>{t("app.award.despcription")}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                    __html: renderDescription(this.state.product, variant)
                      }}>
                    </p>

                <span className="read-more" />
              </div>
              <span className={"read-more__action read-more__action--mobile"} onClick={() => this.handleReadMore()}>
                <Icon icon={"arrow"} />
              </span>
            </div>
            <div className={"product-view__lower"}>
              <CardSlider sliderLabel={t("app.award.news")} />
            </div>
          </div>
          {this.state.isLightBoxVisible &&
          <Lightbox image={this.state.selectedGalleryImageUrl} handleLightbox={this.handleLightbox} />
          }
        </div>
      </>
    );
  }
}

ProductView.contextType = AppContext;

export default withRouter(ProductView);
