import React, { Component } from "react";

// import Pagination from "../../components/atom/pagination/Pagination";
import { AppContext } from "../../data/ContextProvider";
import OrderItem from "../../components/element/order-item/OrderItem";
import ShippingOrderOverviewBox from "../../components/element/shipping-box/ShippingOrderOverviewBox";
import CartProduct from "../../components/element/cart-product/CartProduct";
import TextLink from "../../components/atom/textlink/textlink";
import { formatDate, formatDateEn, getToken } from "../../lib/lib";
import { getAddressById, getOrderById, getOrderPosition, loadCurrentUser } from "../../data/api";
import { getProductImage, getProductPrice } from "../../helpers/utils";
import t from "../../helpers/t9n";

class OrdersDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      order: {},
      order_meta: {}
    }

    this.deliveryProcessing = ["pending", "assumed", "dispatch_preparation", "unknown_delivery_date"]
    this.deliveryCancelled = ["cancelled", "credited"]
    this.deliveryDispatched = ["dispatched", "invoiced", "processed"]
  }

  async componentDidMount() {
    const token = getToken()
    const orderRecord = await getOrderById(token, this.props.orderid)
    const user = await loadCurrentUser()
    const order = orderRecord.order

    let address = {}

    const position_numbers = []
    const order_meta = {
      sum: 0,
      article_quantity: {},
      article_points: {},
      article_status: {}
    }

    order.position_attributes.map(pos => {
      position_numbers.push(pos.article_number)
      order_meta.article_status[pos.article_number] = pos.state
      order_meta.article_quantity[pos.article_number] = pos.quantity
      return true
    })
    
    //dedicated delivery address
    if (order.delivery_address_attributes_id !== 0) {
      address = await getAddressById(token, order.delivery_address_attributes_id)
    } else {
      address = user.user
    }

    const positions = await getOrderPosition(token, position_numbers)

    order_meta.sum = positions.forEach(position => {
      return order_meta.sum + (position.points * order_meta.article_quantity[position.article_number])
    })

    this.setState({
      order: order,
      address: address,
      order_meta: order_meta,
      positions: positions,
      loading: false
    })
  }

  determineDeliveryState = (article_number) => {
    const state = this.state.order_meta.article_status[article_number]

    if (this.deliveryProcessing.includes(state)) {
      return 1
    }
    if (this.deliveryCancelled.includes(state)) {
      return 2
    }
    if (this.deliveryDispatched.includes(state)) {
      return 3
    }
    return 0
  }

  render() {

    if (this.state.loading) {
      return "loading..."
    }

    const order = { ...this.state.order };
    const order_meta = { ...this.state.order_meta };
    // const positions = this.state.positions;
    const address = this.state.address;

    return (
      <>
        <div className={"content orders orders--detail"}>
          <div className={"shop__top"}>
            <span className={"shop__breadcrumb"}><TextLink url={`/${window.currentLang}/orders`} text={t("app.back.to.overview")} /></span>
          </div>
          <div className={"orders__container orders__container--detail"}>
            {order &&
            <>
              <div className={`orders__left ${this.state.isOneUnavailable ? "orders__left--unavailable" : ""}`}>
                {window.currentLang === "en" ? (
                  <OrderItem 
                    orderNr={order.number}
                    orderPrice={order_meta.sum}
                    orderDate={formatDateEn(order.created_at)}
                    orderId={order.id}
                    isDetailView={true}
                  />
                ) : ( 
                  <OrderItem 
                    orderNr={order.number}
                    orderPrice={order_meta.sum}
                    orderDate={formatDate(order.created_at)}
                    orderId={order.id}
                    isDetailView={true}
                  />
                )}
                <div className={"orders__product-list"}>
                  {order && order.position_attributes.map((elem, i) => {
                    return <CartProduct
                                        product={elem}
                                        title={elem.option.title}
                                        price={getProductPrice(elem.option, this.context.getUserData().project_code)}
                                        variant={elem.option.variation}
                                        image={getProductImage(elem.option.images)}
                                        pid={elem.option.product_id}
                                        id={elem.option.article_number}
                                        quantity={elem.quantity}
                                        isAvailable={elem.available}
                                        availability_state={elem.option.availability_state}
                                        shippingDate={"missing"}
                                        shippingDays={"missing"}
                                        isOrderDetailProduct={true}
                                        key={elem.option.article_number + "_" + i}
                                        deliveryStatus={this.determineDeliveryState(elem.option.article_number)}
                    />
                  })}
                </div>
                {/*<Pagination totalItems={27} itemsPerPage={9} currentPage={1} />*/}
              </div>
              <div className={"orders__right"}>
                <ShippingOrderOverviewBox data={address} />
              </div>
            </>
            }
          </div>
        </div>
      </>
    );
  }
}

OrdersDetail.contextType = AppContext;
export default OrdersDetail;
