import React, { useContext } from "react";

import "../sass/styles.sass";
import Navbar from "../components/element/navbar/Navbar";
import Footer from "../components/element/footer/Footer";
import { AppContext } from "../data/ContextProvider";
import ModalInformation from "../components/module/modal-information/ModalInformation";
import ModalNewContracts from "../pages/Contracts/components/ModalNewContracts";

function AccountLayout({ children }) {

  const context = useContext(AppContext);

  return (
    <React.Fragment>
      <Navbar bgColor={"black"} isScrolling={true} />

      <div className={`container container--account ${context.getProjectData().global_jackpot_active ? "container--jackpot-counter" : ""}`}>
        {context.isModalInfoVisible &&
          <ModalInformation />
        }

        {context.isModalContractInfoVisible &&
          <ModalNewContracts />
        }

        {children}
      </div>

      <Footer />
      <div className={`popup-cart__overlay ${context.isPopupCartVisible ? "popup-cart__overlay--visible" : ""}`}>
      </div>
    </React.Fragment>
  );
}

export default AccountLayout;
