import React from "react";
import { useParams } from "react-router-dom";
import Login from "./Login";

const LoginPage = (props) => {
  const { lang, step, reset_token } = useParams();

  return <Login lang={lang} step={step} resetPassword={props.resetPassword} resetToken={reset_token} />
}

export default LoginPage;
