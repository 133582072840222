import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";
import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";

import { bodyForLang } from "../../../lib/lib";

export class PraemiencasinoBanner extends Component {
  render() {
    const texts = this.props.text

    let subline = ""
    let body = ""
    let headline = ""

    texts.forEach(text => {
      if (text.key === 'p33_header_content_headline') {
        headline = text
      }
      if (text.key === 'p33_header_content_subline') {
        subline = text
      }
      if (text.key === 'p33_upper_content_text') {
        body = text
      }
    })

    return (
      <>
        <div className="app-header__section casino-header__section app-header__section--open">
          <video loop muted autoPlay playsInline>
            <source
              src={`${process.env.PUBLIC_URL}/videos/Casino/vid--casino.mp4`}
              type="video/mp4"
            />
            {t("app.video.no.support")}
          </video>
          <div className="app-header__content">
            <div className="app-header__left">
              <span className="app-header__heading">
                <div>
                  <span>
                    <Icon icon="app-praemiencasino" />
                  </span>
                  <h1>{bodyForLang(headline, window.currentLang)}</h1>
                </div>
                <h2>{bodyForLang(subline, window.currentLang)}</h2>
              </span>
              <p dangerouslySetInnerHTML={{ __html: bodyForLang(body, window.currentLang) }}></p>
              <span className="app-header__actions">
                <Button
                  type="secondary"
                  text={t("global.rules")}
                  textColor="black"
                  backgroundColor="bg-white"
                  onClick={() => {
                    this.props.history.push(`/${window.currentLang}/rules/${this.props.appID}`);
                  }}
                />
              </span>

              <div className="casino-room__slots-wrapper">
                <div className="casino-room__slots-available">
                  <svg
                    className="casino-room__dotted-circle"
                    viewBox="0 0 100 100"
                  >
                    <circle
                      cx="50"
                      cy="50"
                      strokeDasharray=".001, 8"
                      strokeWidth="4"
                      fill="none"
                      strokeLinecap="round"
                      stroke="white"
                      r="48"
                    />
                  </svg>
                  <div
                    className={
                      navigator.userAgent.indexOf("Mac") > 0
                        ? "casino-room__slots casino-room__slots--mac"
                        : "casino-room__slots"
                    }
                  >
                    <h1>{this.props.slots}</h1>
                  </div>
                </div>
                <div className="casino-room__slots-lower">
                  <div className="casino-room__slots-headline">
                    <h2>{t("app.available.games")}</h2>
                  </div>
                  <div className="casino-room__slots-subline">
                    <p>{t("app.equals.closed.contracts")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

PraemiencasinoBanner.contextType = AppContext;
export default withRouter(PraemiencasinoBanner);
