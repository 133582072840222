import React from "react";
import { withRouter } from "react-router-dom";

import { AppContext } from "../../../data/ContextProvider";
import Button from "../../atom/button/Button";

import { roundTo } from "../../../lib/lib";
import t from "../../../helpers/t9n";

class CreditBox extends React.Component {

  handleLink = (path) => {
    this.props.history.push(path)
  }

  renderMsg = (deltaPositive, deltaInCurrency) => {
    return <span className="credit-box__notification">
      {t("cart.credit.box.text1")} <b>{(deltaPositive).toLocaleString()}</b> {t("cart.credit.box.text2")} <b>{(deltaInCurrency).toLocaleString()} </b> {t("cart.credit.box.text3")}.
          </span>
  }

  render() {
    const delta = this.props.balance - this.props.sum
    const isInsufficient = (delta < 0)
    const deltaPositive   = Math.abs(delta);
    const projectData = this.context.getProjectData();
    const calculation_factor =
        projectData && projectData.calculation_factor
          ? parseInt(projectData.calculation_factor)
          : 1;

    let factor = 10

    if (calculation_factor === 2) {
      factor = 1
    } else if (calculation_factor === 3) {
      factor = 100
    }

    const deltaInCurrency = roundTo(
      (deltaPositive / factor) * 1.0249 + 0.35,
      2
    );

    return (
      <>
      <div
        className={`credit-box ${isInsufficient ? "credit-box--insufficient" : "credit-box--standard"}`}>
        <div className="credit-box__content">
        <span className="credit-box__row">
          <span className="credit-box__sum-label">{t("cart.popup.sum")}</span>
          <span className="credit-box__sum">{Number(this.props.sum).toLocaleString()} {t("app.points")}</span>
        </span>
          <span className="credit-box__row">
          <span className="credit-box__balance-label">{t("cart.bank.recent")}</span>
          <span className="credit-box__balance">{Number(this.props.balance).toLocaleString()} {t("app.points")}</span>
        </span>
          <span className="credit-box__row">
            {isInsufficient ?
                 this.renderMsg(deltaPositive, deltaInCurrency)
              :
              <>
              <span className="credit-box__delivery-label">
                {/*<span className={"credit-box__estimated-delivery"}>{props.estimatedDelivery}</span>*/}
                <span className="credit-box__estimated-delivery">{t("cart.credit.box.shipping")}</span>
              </span>
                {/*<span className={"credit-box__delivery"}>{props.deliveryPrice}</span>*/}
            </>
            }
        </span>
        </div>
        <div className="credit-box__actions">
          <Button text={t("cart.credit.box.buttun.send")} textColor="white" type="primary" onClick={() => this.handleLink(`/${window.currentLang}/checkout`)} />
          <Button text={t("cart.credit.box.button.more")} textColor="black" type="secondary"
                  onClick={() => this.handleLink(`/${window.currentLang}/shop`)} />
        </div>
      </div>


      </>
    );
  }
}

CreditBox.contextType = AppContext;
export default withRouter(CreditBox);
