import {getToken} from "../../lib/lib";
import axios from "axios";
import {API_HOST} from "../config";

/**
 * get contracts
 * @param page      current page
 * @param view_mode (active|archived) archived contracts > 1 month old
 * @param status    (assigned|unassigned) contract has user?
 * @param query     search query parameters
 * @returns {Promise<any>}
 */
export async function getContracts(page = 1, view_mode = "active", status = '', query = '') {
  const response = await axios.get(
    `${API_HOST}/contracts?page=${page}&view_mode=${view_mode}&status=${status}&q=${query}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}

/**
 * assign user to contracts
 * @param contracts: array with contract ids
 * @returns {Promise<any>}
 */
export async function assignUser(contracts) {
  const response = await axios.put(
    `${API_HOST}/contracts/assignUser`,
    {contracts: contracts},
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}
