import React, { Component } from "react";
import Selectfield from "../../components/atom/selectfield/Selectfield";
import Textfield from "../../components/atom/textfield/Textfield";
import TextfieldGroup from "../../components/atom/textfield-group/TextfieldGroup";
import Button from "../../components/atom/button/Button";
import Product from "../../components/element/product/Product";
import Checkbox from "../../components/atom/checkbox/Checkbox";
import Accordion from "../../components/element/accordion/Accordion";
import { getToken } from "../../lib/lib";
import {
  getCategories,
  getProductsByCategories,
  getProducts,
} from "../../data/api";
import { withRouter } from "react-router-dom";
import PaginationFixed from "../../components/atom/pagination-fixed/PaginationFixed";
// import Pagination from "../../components/atom/pagination/Pagination";
import {
  getProductImage,
  getMinProductPriceForProject,
  renderTitle,
} from "../../helpers/utils";
import { AppContext } from "../../data/ContextProvider";
import t from "../../helpers/t9n";

class Shop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      accordionOpen: 0,
      categories: [],
      products: [],
      currentPage: 0,
      itemsPerPage: 30,
      checkedMainItems: {},
      checkedSubItems: {},
      initialCategory: "",
      initialSubCategory: "",
      additional_params: "",
      search_term: "",
      p_from: "",
      p_to: "",
      highlights: false,
      order_by: "",
    };
  }

  handleChange(e, main = false, children) {
    const catId = e.target.name;
    const isChecked = e.target.checked;

    const checkedMainItems = this.state.checkedMainItems;
    const checkedSubItems = this.state.checkedSubItems;

    if (main) {
      checkedMainItems[catId] = { checked: isChecked, children: children };

      if (!isChecked) {
        //Check if one of the children was selected and uncheck it
        children.forEach((subCat) => {
          const child = checkedSubItems[subCat.id];
          if (child) {
            child.checked = false;
            checkedSubItems[subCat.id] = child;
          }
        });
        this.setState(
          {
            checkedSubItems: checkedSubItems,
            checkedMainItems: checkedMainItems,
          },
          () => {
            this.getProducts();
          }
        );
      } else {
        this.setState(
          {
            checkedMainItems: checkedMainItems,
          },
          () => {
            this.getProducts();
          }
        );
      }
    } else {
      checkedSubItems[catId] = { checked: isChecked };
      this.setState(
        {
          checkedSubItems: checkedSubItems,
        },
        () => {
          this.getProducts();
        }
      );
    }
  }

  async componentDidMount() {
    const categories = await getCategories();
    let selectedCategory = null;
    let selectedSubCategory = null;
    let accordionOpen = 0;

    const additional_params = {
      search_term: "",
      p_from: "",
      p_to: "",
      highlights: false,
    };

    if (this.props.location.search) {
      // supoort of queryparam: shop?highlights=1 or 0
      additional_params.highlights = Boolean(
        Number(
          new URLSearchParams(this.props.location.search).get("highlights")
        )
      );
    }

    if (this.props.location.data) {
      additional_params.search_term = this.props.location.data.search_term;
      additional_params.p_from = this.props.location.data.p_from;
      additional_params.p_to = this.props.location.data.p_to;
    }

    if (this.props.category) {
      selectedCategory = categories.find(cat => parseInt(cat.id) === parseInt(this.props.category));
      accordionOpen = 1

      if (selectedCategory && this.props.subcategory) {
        accordionOpen = 2
        selectedSubCategory = selectedCategory.children.find(cat => parseInt(cat.id) === parseInt(this.props.subcategory));
      }
    }

    this.setState(
      {
        accordionOpen: accordionOpen,
        currentPage: 0,
        categories: categories,
        selectedCategory: selectedCategory,
        selectedSubCategory: selectedSubCategory,
        search_term: additional_params.search_term,
        p_from: additional_params.p_from,
        p_to: additional_params.p_to,
        highlights: additional_params.highlights,
      },
      () => {
        if (selectedCategory) {
          let catHash = {
            target: {
              name: selectedCategory.id,
              checked: true,
            },
          };
          this.handleChange(catHash, true, selectedCategory.children);
        }

        if (selectedSubCategory) {
          let subCatHash = {
            target: {
              name: selectedSubCategory.id,
              checked: true,
            },
          };
          this.handleChange(subCatHash, false);
        }

        this.getProducts();
      }
    );
  }

  getProducts = async () => {
    const mainCatIds = [];
    const catIds = [];

    const checkedMainItems = this.state.checkedMainItems;
    const checkedSubItems = this.state.checkedSubItems;

    let products;

    const token = getToken();
    // auth-guard
    if (!token) {
      this.props.history.push("/login");
    }

    Object.keys(checkedMainItems).forEach((catId) => {
      if (checkedMainItems[catId].checked) {
        return mainCatIds.push(catId.toString());
      }
    });

    Object.keys(checkedSubItems).forEach((catId) => {
      if (checkedSubItems[catId].checked) {
        return catIds.push(catId.toString());
      }
    });

    if (mainCatIds.length > 0) {
      products = await getProductsByCategories(
        token,
        mainCatIds,
        catIds,
        this.state.search_term,
        this.state.order_by,
        this.state.currentPage,
        this.state.p_from,
        this.state.p_to,
        this.state.highlights
      );
    } else {
      products = await getProducts(
        token,
        this.state.currentPage,
        this.state.search_term,
        this.state.order_by,
        this.state.p_from,
        this.state.p_to,
        this.state.highlights
      );
    }

    this.setState({
      products: products,
      count: products.count,
      pages: Math.ceil(products.count / this.state.itemsPerPage),
      loading: false,
    });
  };

  getSubCategories() {
    const subCats = [];

    const checkedMainItems = this.state.checkedMainItems;

    Object.keys(checkedMainItems).forEach((catId) => {
      if (checkedMainItems[catId].checked) {
        checkedMainItems[catId].children &&
          checkedMainItems[catId].children.forEach((subcat) => {
            subCats.push(
              <Checkbox
                key={subcat.id}
                label={renderTitle(subcat)}
                id={subcat.id}
                name={subcat.id}
                selected={
                  this.state.checkedSubItems[subcat.id] &&
                  this.state.checkedSubItems[subcat.id].checked
                }
                onChange={(e) => this.handleCategoryClick(e, false)}
              />
            );
          });
      }
    });

    return subCats;
  }

  doSearch = () => {
    this.setState(
      {
        currentPage: 0,
      },
      () => {
        this.getProducts();
      }
    );
  };

  handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      this.doSearch()
    }
  }

  handleCategoryClick = (e, main, children) => {
    e.persist();
    this.setState(
      {
        currentPage: 0,
      },
      () => {
        this.handleChange(e, main, children);
      }
    );
  };

  handlePagination = (pageNumber) => {
    window.scrollTo(0, 0)
    this.setState(
      {
        currentPage: pageNumber,
      },
      () => {
        this.getProducts();
      }
    );
  };

  handleAccordion = (num) => {
    this.setState({
      accordionOpen: num,
    });
  };

  handleSearch = (term) => {
    this.setState({
      search_term: term.target.value,
    });
  };

  handleOrderBy = (orderBy) => {
    const oldOrder = this.state.order_by;
    this.setState(
      {
        order_by: orderBy.target.value,
      },
      () => {
        if (oldOrder !== orderBy.target.value) {
          this.getProducts();
        }
      }
    );
  };

  getFilterBar() {
    return (
      <div className="shop__filterbar filterbar">
        <span className="filterbar__heading">{t("app.award.search")}</span>
        <Textfield
          placeholder={t("app.award.search.keyword")}
          handleChange={this.handleSearch}
          value={this.state.search_term}
          onKeyDown={this.handleEnterSearch}
        />

        <TextfieldGroup>
          <Textfield
            placeholder={t("app.award.search.points.from")}
            value={this.state.p_from}
            handleChange={(e) => this.setState({ p_from: e.target.value })}
          />
          -
          <Textfield
            placeholder={t("app.award.search.points.to")}
            value={this.state.p_to}
            handleChange={(e) => this.setState({ p_to: e.target.value })}
          />
        </TextfieldGroup>

        {this.context.getAppIdOfUser() === "17" ? null : (
          <div className="filterbar__categories">
            <Accordion
              heading={t("app.main.category")}
              isOpen={this.state.accordionOpen === 1}
              handleClick={() => this.handleAccordion(1)}
            >
              {this.state.categories &&
                this.state.categories.map((c) => {
                  return (
                    <Checkbox
                      label={renderTitle(c)}
                      id={c.id}
                      key={c.id}
                      name={c.id}
                      selected={
                        this.state.checkedMainItems[c.id] &&
                        this.state.checkedMainItems[c.id].checked
                      }
                      onChange={(e) =>
                        this.handleCategoryClick(e, true, c.children)
                      }
                    />
                  );
                })}
            </Accordion>
            <Accordion
              heading={t("app.sub.category")}
              isOpen={this.state.accordionOpen === 2}
              handleClick={() => this.handleAccordion(2)}
            >
              {this.getSubCategories()}
              <span className={"filterbar__please-select"}>
                {t("app.choose.main.category")}
              </span>
            </Accordion>
          </div>
        )}

        <Button
          type={"primary"}
          textColor={"white"}
          icon={"search"}
          text={t("app.shop.search")}
          onClick={this.doSearch}
        />
      </div>
    );
  }

  renderProduct = (p) => {
    if (p.options && p.options.length > 0) {
      return (
        <Product
          type={"shop"}
          title={renderTitle(p)}
          key={p.id}
          variantId={p.options[0].id}
          hasMultipleVariants={p.options.length > 0}
          price={getMinProductPriceForProject(
            p.options,
            this.context.getUserData().project_code
          )}
          image={getProductImage(p.images)}
          id={p.id}
          isWished={p.wish}
          pid={p.number}
          hasWishAction={true}
        />
      );
    } else {
      return "";
    }
  };

  render() {
    if (this.state.loading) {
      return t("global.loading");
    } else {

      const showPagination = this.state.pages > 1 && this.state.pages > this.state.currentPage + 1;

      return (
        <>
          <div className="content shop">
            <div className="shop__top--mobile">
              <Accordion heading={t("app.award.search")}>
                {this.getFilterBar()}
              </Accordion>
            </div>
            <div className="shop__top">
              <span className="shop__breadcrumb linklist">
                <a href={`/${window.currentLang}/shop`}>
                  {t("menu.award.shop")}
                </a>
                {this.state.selectedCategory && (
                  <>
                    <span className="linklist__seperator">/</span>
                    <span>{renderTitle(this.state.selectedCategory)}</span>
                  </>
                )}
                {this.state.selectedSubCategory && (
                  <>
                    <span className="linklist__seperator">/</span>
                    <span>{renderTitle(this.state.selectedSubCategory)}</span>
                  </>
                )}
              </span>

              <span className="shop__sort">
                <Selectfield
                  onSelect={this.handleOrderBy}
                  value={this.state.order_by}
                  placeholder={t("app.shop.sort.for")}
                  // defaultValue={'Sortieren nach'}
                  options={{
                    newest: t("app.shop.sort.for.newest"),
                    alpha_asc: t("app.shop.sort.for.az"),
                    alpha_desc: t("app.shop.sort.for.za"),
                  }}
                />
              </span>
            </div>
            <div className="shop__container">
              <div className="shop__left">{this.getFilterBar()}</div>
              <div className="shop__right">
                <div className="shop__product-grid">
                  {this.state.products &&
                    this.state.products.products.map((p) => {
                      return this.renderProduct(p);
                    })}
                </div>

                <PaginationFixed
                  paginate={this.handlePagination}
                  nextPage={showPagination}
                  currentPage={this.state.currentPage}
                />

              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

Shop.contextType = AppContext;
export default withRouter(Shop);
