import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";
import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import trophy from "../../../assets/lotties/team-trophy/anim--trophy";
import { formatShortDate } from "../../../helpers/utils";
import CounterSpawner from "../../element/counter-spawner/CounterSpawner";

import { bodyForLang } from "../../../lib/lib";

export class TeamTrophyBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamTrophy: 0
    }
  }

  render() {
    const texts = this.props.text

    let subline = ""
    let body = ""
    let headline = ""

    texts.forEach(text => {
      if (text.key === 'p33_header_content_headline') {
        headline = text
      }
      if (text.key === 'p33_header_content_subline') {
        subline = text
      }
      if (text.key === 'p33_upper_content_text') {
        body = text
      }
    })

    return (
      <>
        <div className="app-header__section teamtrophy-header__section app-header__section--open">
          <video loop muted autoPlay playsInline>
            <source
              src={`${process.env.PUBLIC_URL}/videos/Neukunden/anim--bg-blue-mountains.mp4`}
              type="video/mp4"
            />
            {t("app.video.no.support")}
          </video>
          <div className="app-header__content">
            <div className="app-header__left">
              <span className="app-header__heading">
                <div>
                  <span>
                    <Icon icon="app-teamtrophy" />
                  </span>
                  <h1>{bodyForLang(headline, window.currentLang)}</h1>
                </div>
                <h2>{bodyForLang(subline, window.currentLang)}</h2>
              </span>
              <p dangerouslySetInnerHTML={{ __html: bodyForLang(body, window.currentLang) }}></p>
              <span className="app-header__actions">
                <Button
                  type="secondary"
                  text={t("global.rules")}
                  textColor="black"
                  backgroundColor="bg-white"
                  onClick={() => {
                    this.props.history.push(`/${window.currentLang}/rules/${this.props.appID}`);
                  }}
                />
              </span>
            </div>
            <span className="teamTrophy-counter__icon">
              <UncontrolledLottie animationData={trophy} />
            </span>
            <div className="app-header__right">
              <div className="teamTrophy-counter">
                <h3>{t("app.our.sales.target")}</h3>
                <h3>
                  {t("app.until.the")}
                  {formatShortDate(this.props.appPrefs.end)}
                </h3>
                <span className="teamTrophy-counter__wrapper">
                  <CounterSpawner
                    amount={this.props.appPrefs.target}
                    type="light"
                  />
                  <h5>{t("app.currency")}</h5>
                </span>

                <h5>{t("app.current.sales")}</h5>
                <div className="teamTrophy-counter__bank">
                  <h2>
                    <b>
                      {Number(this.props.appPrefs.amount).toLocaleString()}{" "}
                      {t("app.currency.icon")}
                    </b>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

TeamTrophyBanner.contextType = AppContext;
export default withRouter(TeamTrophyBanner);
