import React from 'react'
import PropTypes from 'prop-types'

const Logo = props => (
  <span className="navbar__logo">
      <a href={`/${window.currentLang}/compliance-tool`} className="logo">
        <img src={`${props.image}`}
             alt={`${props.alt}`} />
      </a>
    </span>
)

Logo.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
  link: PropTypes.string,
};

Logo.defaultProps = {
  type: 'default',
  image: process.env.PUBLIC_URL + "/bum-logo.jpg",
  alt: '',
  link: "/"
};

export default Logo
