import React, { Component } from "react";
import SectionProfileHeader from "../../../components/module/section-profile-header/SectionProfileHeader";


import Content from "../Components/Content";
import { checkPasswordPolicy } from "../../../lib/lib";

import SectionProfileLogin from "../../../components/module/section-profile-login/SectionProfileLogin";
import Header from "../Components/Header";
import Stepper from "../Components/Stepper";

import { AppContext } from "../../../data/ContextProvider";

import t from "../../../helpers/t9n";

import { loadCurrentManager, updateManager, updateManagerPassword } from "../../../data/ct-api";


class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      passwordError: "",
      passwordSuccess: ""
    }
  }

  handleUserChange = (e) => {
    const user = this.state.user
    if (e.target) {
      user[e.target.name] = e.target.value
      this.setState({ user });
    }
  }

  handlePasswordChange = (e) => {
    const state = this.state
    if (e.target) {
      state[e.target.name] = e.target.value
      this.setState({ state });
    }
  }

  handleSubscribeInfos = () => {
    const user = { ...this.state.user }
    user.subscribed_infos = !this.state.user.subscribed_infos
    this.setState({ user })
  }

  handleUpdateProfile = async (e) => {
    if (e) {
      e.preventDefault()
    }

    try {
      await updateManager(this.state.user)

      this.setState({
        messagesSuccess: "Das Profil wurde erfolgreich aktualisiert!"
      });

      this.loadData()
    } catch (error) {
      this.setState({
        messagesError: "Bitte prüfen Sie Ihre Eingaben"
      });
    }
  }


  handleUpdatePassword = async (e) => {
    e.preventDefault()

    const data = {
      current_password: this.state.current_password,
      password: this.state.password,
      password_repeat: this.state.password_repeat
    };

    if (!this.state.current_password || this.state.current_password.length === 0 ) {
      this.setState({ passwordError: "Geben Sie bitte Ihr aktuelles Passwort ein" })
      return
    }

    if ( !this.state.password ||
         !this.state.password_repeat ||
         this.state.password.length === 0 ||
         this.state.password_repeat.length === 0
    ) {
      this.setState({passwordError: "Das Passwort darf nicht leer sein"})
      return
    }

    if (this.state.password !== this.state.password_repeat) {
      this.setState({ passwordError: "Passwörter stimmen nicht überein" })
      return
    }

    if (!checkPasswordPolicy(this.state.password)) {
      this.setState({ passwordError: "Ihr gewähltes Passwort entspricht nicht den Sicherheitsbestimmungen. Ihr Passwort muss aus mindestens 8 Zeichen bestehen und Groß- und Kleinschreibung und mindestens eine Zahl beinhalten." })
      return
    }

    try {
      await updateManagerPassword(data)
      this.setState({ passwordSuccess: "", passwordError: "" })
    } catch (error) {
      this.context.toggleModalInfo(true)
      this.setState({ passwordError: error.data.error })
    }
  }

  loadData = async () => {
    const user = await loadCurrentManager()

    this.setState({
      user: user.user,
      loading: false
    })
  }

  async componentDidMount() {
    this.loadData()
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <div className="compl-tool">

          <Header headline="" />

          <Stepper activeStep="5" />

          <div className="profile__update__container">
            <SectionProfileHeader
              user={this.state.user}
              handleChange={this.handleUserChange}
              handleSubscribeInfos={this.handleSubscribeInfos}
              handleUpdateProfile={this.handleUpdateProfile}
              onAvatarChange={this.onAvatarChange}
              deleteAvatar={this.deleteAvatar}
              showAvatarUpload={false}
              showSubscriberInfos={false}
              messageSuccess={this.state.messagesSuccess}
              messageError={this.state.messagesError}
              subHeadline={"Bitte überprüfen Sie ob alle Ihre Daten stimmen."}
            />
          </div>

          <SectionProfileLogin
            handleChange={this.handlePasswordChange}
            error={this.state.passwordError}
            success={this.state.passwordSuccess}
            handleUpdatePassword={this.handleUpdatePassword}
          />

          <Content
            nextButton="Weiter zur Registrierung"
            nextButtonPath={`/${window.currentLang}/compliance-tool/registration`}
            prevButton="Zurück"
            prevButtonPath={`/${window.currentLang}/compliance-tool/privacy`}
          />
        </div>
      </>
    );
  }
}

Profile.contextType = AppContext;
export default Profile;
