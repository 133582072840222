import React, { Component } from "react";
import { AppContext } from "../../data/ContextProvider";
import { getPerformancedata } from "../../data/api";
import { formatDate } from "../../lib/lib";
import PaginationFixed from "../../components/atom/pagination-fixed/PaginationFixed";
import t from "../../helpers/t9n";

class PerformancedataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentPage: 1,
      page: 1,
    }
  }

  async componentDidMount() {
    await this.loadData()
  }

  loadData = async () => {
    const performancedata = await getPerformancedata({ page: this.state.currentPage })

    this.setState({
      performancedata: performancedata.transactions,
      meta: performancedata.meta
    }, () => {
      this.setState({
        loading: false,
      })
    })
  }

  handlePagination = async (pageNumber) => {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this.loadData()
    })
  }

  render() {

    if (this.state.loading)
      return "Loading..."

    const dataMeta = this.state.meta

    return (
      <div className={"account-header__section"}>
         <h2>{t("app.contracts")}</h2>
        <div className={"account-header__content"}>
          <div className={"account__left"}>
            <span className={"account__transaction-labels"}>
              <h3>{t("global.account.date")}</h3>
              <h3>{t("global.account.desc")}</h3>
              <h3>{t("global.account.points")}</h3>
            </span>
            <div className={"account__transaction-container"}>

            {this.state.performancedata.length > 0 ?

              this.state.performancedata.map((item, i) => {
                return (
                  <div className={"account__transaction"} key={item.updated_at + "_" + i}>
                    <span>{formatDate(item.updated_at, false)}</span>
                    <span>{item.description}</span>
                    <b>{Number(item.amount).toLocaleString()}</b>
                  </div>
                )
              })
              :
              <div className={"account__transaction"}>
                <h3>{t("global.empty.table")}</h3>
              </div>
            }
          </div>
          <PaginationFixed paginate={this.handlePagination}
                           prevPage={dataMeta.prev_page}
                           nextPage={dataMeta.next_page}
                           color={"white"}
                           currentPage={this.state.currentPage}
                           totalPages={dataMeta.total_pages} />
        </div>
      </div>
    </div>
    );
  }
}

PerformancedataTable.contextType = AppContext

export default PerformancedataTable;
