import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/element/header-section/HeaderSection";
import t from "../helpers/t9n";
import Performancedata from "./Performancedata/Performancedata";

const PerformancedataPage = () => {
  let { lang } = useParams();

  return (
    <>
      <Header heading={t("menu.my.contracts")} hasImage={true} isRed={true} />
      <Performancedata lang={lang} />
    </>
  )
}

export default PerformancedataPage;
