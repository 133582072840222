import React from "react";
import PropTypes from "prop-types";
import Icon from "../icons/Icon";

const SimplePagination = (props) => {

  const pageNumbers = Array.from(Array(props.totalPages).keys())

  // Quick&Dirty to reduce the page-buttons
  const size = 7;
  const start = (props.currentPage - size < 0 ) ? 0 : props.currentPage - size;
  const end = (props.currentPage + size > props.totalPages) ? props.totalPages  : props.currentPage + size;
  const showPages = pageNumbers.slice(start, end);

  return (
    <nav>
      <ul className="pagination mb-0">

      <li className="page-item">
          <button className="page-link" aria-label="Previous" disabled={props.currentPage === 1}  onClick={() => props.paginate(1)}>
            <span aria-hidden="true">
              <Icon icon="arrow-double" additionalClass="arrow--left" />
            </span>
            <span className="sr-only">First</span>
          </button>
        </li>

        <li className="page-item">
          <button className="page-link" aria-label="Previous" disabled={props.prevPage === null}  onClick={() => props.paginate(props.prevPage)}>
          <span aria-hidden="true">
              <Icon icon="arrow"  additionalClass="arrow--left" />
            </span>
            <span className="sr-only">Previous</span>
          </button>
        </li>

        {showPages.map((number) => {
          number++;
          return (<li key={number} className={`page-item ${props.currentPage === number ? "active" : ""}`}>
            <button className={"page-link"} onClick={() => props.paginate(number)}>{number}</button>
          </li>)
        })}

        <li className="page-item">
          <button className="page-link" aria-label="Next"  disabled={props.nextPage === null}   onClick={() => props.paginate(props.nextPage)}>
            <span aria-hidden="true">
              <Icon icon="arrow"  additionalClass="arrow--right" />
            </span>
            <span className="sr-only">Next</span>
          </button>
        </li>

        <li className="page-item">
          <button className="page-link" aria-label="Next"  disabled={props.nextPage === null}   onClick={() => props.paginate(props.totalPages)}>
            <span aria-hidden="true">
              <Icon icon="arrow-double"  additionalClass="arrow--right" />
            </span>
            <span className="sr-only">Last</span>
          </button>
        </li>

      </ul>
    </nav>
  );
}

SimplePagination.propTypes = {
  totalPages: PropTypes.number,
  prevPage: PropTypes.number,
  currentPage: PropTypes.number,
  nextPage: PropTypes.number,
  paginate: PropTypes.func
};

export default SimplePagination;
