import React, { Component } from "react";
import Button from "../../atom/button/Button";
import Selectfield from "../../atom/selectfield/Selectfield";
import { AppContext } from "../../../data/ContextProvider";

class LanguageSwitcher extends Component {
  render() {
    return (
      <div className={this.props.menu ? "navbar__language-switch--menu" : "navbar__language-switch"}>
        <div className="navbar__language-switch--select">
          <Selectfield
            placeholder={window.currentLang}
            options={{
              de: <Button onClick={() => this.context.setLanguage("de")}>De</Button>,
              en: <Button onClick={() => this.context.setLanguage("en")}>En</Button>,
              fr: <Button onClick={() => this.context.setLanguage("fr")}>Fr</Button>,
              it: <Button onClick={() => this.context.setLanguage("it")}>It</Button>,
            }}
          />
        </div>
      </div>
    );
  }
}

LanguageSwitcher.contextType = AppContext;
export default LanguageSwitcher;
