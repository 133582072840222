import React, { Component } from "react";
import { AppContext } from "../../../data/ContextProvider";
import {
  getAppPrefs,
  getAppTexts,
  getRankingLevels,
  getTransactionsForSalesPush,
  getUserRankingForSalesPush,
} from "../../../data/api";
import { APP_SALESPUSH } from "../../../data/constants";
import SalesPushBanner from "./SalesPushBanner";
import SalesPushRanking from "./SalesPushRanking";
import Button from "../../atom/button/Button";
import t from "../../../helpers/t9n";

export class SalesPush extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  async componentDidMount() {
    const appPrefs = await getAppPrefs(APP_SALESPUSH)
    const texts    = await getAppTexts(APP_SALESPUSH)

    const rankinglevels = await getRankingLevels(APP_SALESPUSH)
    const transactions  = await getTransactionsForSalesPush()
    const user_rank     = await getUserRankingForSalesPush()

    this.setState({
      transactions: transactions.transactions,
      rankingLevels: rankinglevels.rankinglevels,
      user_rank: user_rank.user_rank,
      appPrefs: appPrefs,
      pageText: texts,
      loading: false
    })
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <div className="banner-section" id={this.props.id}>
          <div className={this.props.isHeaderOpen ? "banner-section-inner banner-section-inner--open" : "banner-section-inner"}>
            <SalesPushBanner
              text={this.state.pageText}
              headline={this.state.appPrefs.name}
              appID={APP_SALESPUSH}
            />

            <SalesPushRanking
              ranking_levels={this.state.rankingLevels}
              transactions={this.state.transactions}
              currencyMode={this.state.appPrefs.currency_mode}
              current_points={this.state.user_rank.current_rank_level_points}
              remaining_target={this.state.user_rank.remaining_transactions_amount}
              start={this.state.appPrefs.start}
              end={this.state.appPrefs.end}
            />
          </div>

          {this.props.isHeaderOpen ? (
            <Button
              type="secondary"
              text={this.props.buttonOpen}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_SALESPUSH)}
            />
          ) : (
            <Button
              type="secondary"
              text={this.props.buttonClose}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_SALESPUSH)}
            />
          )}
        </div>
      </>
    );
  }
}

SalesPush.contextType = AppContext;
export default SalesPush;
