import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "../../../components/atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import Checkbox from "../../../components/atom/checkbox/Checkbox";
import ScrollToTop from "../../../components/basics/ScrollToTop/ScrollToTop";
import { getContentPage } from "../../../data/api";
import { CONTENT_DATENSCHUTZ } from "../../../data/constants";
import { bodyForLang } from "../../../lib/lib";

import t from "../../../helpers/t9n";

class Step4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPrivacyAccepted: false,
      loading: true,
      body: "",
    };
  }

  async componentDidMount() {
    let body = ""
    const page = await getContentPage(CONTENT_DATENSCHUTZ, this.context.getProjectData().id, this.context.getProjectData().app_id)

    page.text.forEach(text => {
      if (text.key === 'p24_upper_content_text') {
        body = text
      }
    })

    this.setState({
      body: body,
      loading: false
    })
  }
  handlePrivacyChange() {
    this.setState({ isPrivacyAccepted: !this.state.isPrivacyAccepted });
  }

  render() {
    if (this.state.loading) {
      return "Loading.."
    }

    return (
      <div className={"login__container--right"}>
          <ScrollToTop />
          <div className={"login__columns"}>
            <form name={"tos-form"} onSubmit={(e) => this.props.submitFunc(e, 7)}>
              <span className={"login__step-indicator"}>{t("app.sign.up.step.two")}</span>
              <h2>{t("app.privacy.policy")}</h2>
              <div className={"login__columns--wrapper login__columns--wrapper--step3"}>
                <div className={"login__row"}>
                  <div className={"login__info-container"}>
                    <p
                    dangerouslySetInnerHTML={{
                      __html: bodyForLang(this.state.body, window.currentLang)
                    }}>
                    </p>
                  </div>
                </div>
                <div className={"login__row"}>
                  <Checkbox
                    name={"accept_gdpr"}
                    onChange={(e) => {
                      this.props.handleChange(e)
                      this.handlePrivacyChange()
                    }}
                    label={[t("app.privacy.disclaimer")]}
                    id={"accept_gdpr"} />
                </div>
                <div className="login__row">
                  <div className="login__col">
                  </div>
                  <div className="login__col login__col--action">
                    <Button
                      type="primary"
                      textColor="white"
                      state={`${this.state.isPrivacyAccepted ? "active" : "inactive"}`}
                      text={t("global.next")}
                    />
                  </div>
                </div>
              </div>

              <span className={"login__links"}>
                <a href={`/${window.currentLang}`}>{t("menu.home")}</a>
                <a href={`/${window.currentLang}/tos`}>{t("menu.conditions.participation")}</a>
                <a href={`/${window.currentLang}/privacy`}>{t("login.data.protection")}</a>
                <a href={`/${window.currentLang}/imprint`}>{t("login.imprint")}</a>
                <a href={`/${window.currentLang}/contact`}>{t("login.contact")}</a>
              </span>
            </form>
          </div>
        </div>
    );
  }
}

Step4.contextType = AppContext;

export default withRouter(Step4);
