import React from "react";
import { withRouter } from "react-router-dom";
import { tryToImpersonateUser } from "../../lib/lib";

class ImpersonatePage extends React.Component {

  async componentDidMount() {

    if (this.props.match.params && this.props.match.params.impersonate_token) {
      await tryToImpersonateUser(this.props.match.params.impersonate_token)
      this.props.history.push('/')
      window.location.reload();
    }
  }

  render() {
    return (
      <></>
    )
  }
}


export default withRouter(ImpersonatePage);

