import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { AppContext } from '../../../data/ContextProvider'
import newContracts from "../../../assets/lotties/order-overview-list/anim--icon-overview-list";
import Button from '../../../components/atom/button/Button'
import Icon from '../../../components/atom/icons/Icon';
import UncontrolledLottie from '../../../components/atom/lottie/UncontrolledLottie';
import { getWithExpiry, setWithExpiry } from '../../../helpers/utils';

class ModalNewContracts extends Component {
  handleClick = () => {
    const openContracts = this.context.hasOpenContracts()
    setWithExpiry("contracts", openContracts, 300000)
    const getExpiration = getWithExpiry("contracts")

    if(getExpiration === openContracts) {
        this.context.toggleModalNewContract(false)
    } else {
      this.context.toggleModalNewContract(true)
    }
  }

  render() {
    return (
      <>
        <div className={`modal-information modal-information--contracts`}>
          <div className={"modal-information__upper"}>
            <span className={"modal-information__close"} onClick={() => this.handleClick()}>
              <Icon icon={"close"} />
            </span>
            <div className={"modal-information__center"}>
              <UncontrolledLottie animationData={newContracts} />
            </div>
            <span className={"modal-information__row"}>
              <h3>Es gibt neue nicht zugeordnete Verträge</h3>
            </span>
            <Button 
              type={"primary"} 
              onClick={() => {
                this.handleClick()
                this.props.history.push(`/${window.currentLang}/contracts/new`)
              }}
              text={"Zu den Vertragszuordnungen"}
              textColor={"white"} 
            />
          </div>
        </div>

        <div className={`main-menu__overlay ${this.context.isModalContractInfoVisible ? "main-menu__overlay--visible" : ""}`}>
        </div>
      </>
    )
  }
}

ModalNewContracts.contextType = AppContext
export default withRouter(ModalNewContracts);
