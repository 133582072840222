import React from "react";

import Navbar from "./Navbar";
import t from "../../../helpers/t9n";

function EmptyLayout(props) {
  return (
    <React.Fragment>
      <Navbar />
      <div className={`container ${props.fullHeight ? "container--full-height" : ""}`}>
        {props.children}
      </div>
      <div className="footer">
        <span className="footer__links">
          <a href={`/${window.currentLang}/compliance-tool/registration`}>{t("menu.home")}</a>
          <a href={`/${window.currentLang}/compliance-tool/footer-rules`}>{t("global.rules.short")}</a>
          <a href={`/${window.currentLang}/compliance-tool/footer-conditions`}>{t("menu.conditions.participation")}</a>
          <a href={`/${window.currentLang}/compliance-tool/footer-privacy`}>{t("login.data.protection")}</a>
          <a href={`/${window.currentLang}/compliance-tool/footer-imprint`}>{t("login.imprint")}</a>
        </span>
      </div>
    </React.Fragment>
  );
}

export default EmptyLayout;
