import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import { getAppTexts } from "../../data/api"
import Header from "../../components/element/header-section/HeaderSection"

import t from "../../helpers/t9n"
import { AppContext } from "../../data/ContextProvider"
import { bodyForLang } from "../../lib/lib";

export class RulesPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      texts:[]
    }
  }

  loadAllTexts = async (apps) => {
    const texts = []

    await Promise.all(
      await apps.map( async (app) =>  {
        let text = await getAppTexts(app.app_id)

        texts.push({
          text: text,
          appId: app.app_id
        });
      })
    )

    return texts
  }

  loadData = async () => {
    const apps = this.context.getProjectData().apps;
    const texts = await this.loadAllTexts(apps);

    const orderedTexts = [];

    apps.forEach(app => {
      texts.forEach(text => {
        if (app.app_id === text.appId) {

          text.text.forEach(tt => {
            if (tt.key === 'p33_header_content_headline') {
              text.headline = tt
            }
            if (tt.key === 'p33_header_content_subline') {
              text.subline = tt
            }
            if (tt.key === 'p33_upper_content_text') {
              text.upper_content_text = tt
            }
            if (tt.key === 'p33_lower_content_text') {
              text.lower_content_text = tt
            }
          })

          orderedTexts.push(text);
        }
      })
    });

    this.setState({
      texts: orderedTexts,
      loading: false
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    if (this.state.loading) {
      return "Loading.."
    }

    return (
      <>
        <Header heading={t("global.rules.short")} hasImage={true} isRed={true} />
        <div className={"content"}>
          <div className={"content--rules"}>
            {this.state.texts.map((text, index) => (
              <div className={`content--rules-${index}`} key={index}>
                <h2>{bodyForLang(text.headline, window.currentLang)}</h2>
                <h3>{bodyForLang(text.subline, window.currentLang)}</h3>
                <p dangerouslySetInnerHTML={{ __html: bodyForLang(text.upper_content_text, window.currentLang) }}></p>
                <p dangerouslySetInnerHTML={{ __html: bodyForLang(text.lower_content_text, window.currentLang) }}></p>
              </div>
            ))}


          </div>
        </div>
      </>
    );
  }
}


RulesPage.contextType = AppContext;
export default withRouter(RulesPage);