import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";
import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import hunter from "../../../assets/lotties/neukunden/anim--neukunden-hunter";

import { bodyForLang } from "../../../lib/lib";

export class NeukundenjaegerBanner extends Component {
  render() {
    const texts = this.props.text

    let subline = ""
    let body = ""
    let headline = ""

    texts.forEach(text => {
      if (text.key === 'p33_header_content_headline') {
        headline = text
      }
      if (text.key === 'p33_header_content_subline') {
        subline = text
      }
      if (text.key === 'p33_upper_content_text') {
        body = text
      }
    })

    return (
      <>
        <div className="app-header__section neukunden-header__section app-header__section--open">
          <video loop muted autoPlay playsInline>
            <source
              src={`${process.env.PUBLIC_URL}/videos/Neukunden/anim--bg-blue-mountains.mp4`}
              type="video/mp4"
            />
            {t("app.video.no.support")}
          </video>
          <div className="app-header__content">
            <div className="app-header__left">
              <span className="app-header__heading">
                <div>
                  <span>
                    <Icon icon="app-neukundenjaeger" />
                  </span>
                  <h1>{bodyForLang(headline, window.currentLang)}</h1>
                </div>
                <h2>{bodyForLang(subline, window.currentLang)}</h2>
              </span>

              <p dangerouslySetInnerHTML={{ __html: bodyForLang(body, window.currentLang) }}></p>

              <span className="app-header__actions">
                <Button
                  type="secondary"
                  text={t("global.rules")}
                  textColor="black"
                  backgroundColor="bg-white"
                  onClick={() => {
                    this.props.history.push(`/${window.currentLang}/rules/${this.props.appID}`);
                  }}
                />
              </span>
            </div>
          </div>
          <span className="app-header__box">
            <UncontrolledLottie animationData={hunter} />
          </span>
        </div>
      </>
    );
  }
}

NeukundenjaegerBanner.contextType = AppContext;
export default withRouter(NeukundenjaegerBanner);
