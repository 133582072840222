import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Logo from "../../../components/atom/logo/Logo";

import Button from "../../../components/atom/button/Button";

import { checkPasswordPolicy } from "../../../lib/lib";
import { getContentPage } from "../../../data/api";
import { managerLogin, resetManagerPassword } from "../../../data/ct-api";

import t from "../../../helpers/t9n";
import { API_HOST } from "../../../data/config";
import { CONTENT_LOGIN } from "../../../data/constants";

import { AppContext } from "../../../data/ContextProvider";
import Step1ComplTool from "../../Login/Steps/Step1ComplTool";
import NewPasswordComplTool from "../../Login/Steps/NewPasswordComplTool";
import ResetSuccessComplTool from "../../Login/Steps/ResetSuccessComplTool";
import PasswordResetComplTool from "../../Login/Steps/PasswordResetComplTool";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: this.props.step ? this.props.step : 1,
      error: "",
      errorVideo: false,
      hasLoginpic: true,
      email: '',
      loading: true,
      password: '',
      resetToken: "",
      pageText: {}
    }
  }

  async componentDidMount() {

    const page = await getContentPage(CONTENT_LOGIN, this.context.getProjectData().id, this.context.getProjectData().app_id)

    this.setState({
      pageText: page ? page.text : {},
      loading: false
    })

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("reset_token")) {
      this.setState({
        step: 4,
        resetToken: urlParams.get("reset_token")
      })
    }
  }


  handlePasswordReset() {
    this.setState({
      step: 3
    })
  }

  handleSubmit = async (e, step) => {
    e.preventDefault();

    let data = null

    try {
      switch (step) {
        case 0:
          //Back to Login
          this.setState({
            step: 1
          });
          break;
        case 1:

          let userData, loginError;
          try {
            userData = await managerLogin(this.state.email, this.state.password)

          } catch (error) {
            loginError = error.data
          }

          this.context.saveManagerData(userData.manager)

          if (userData) {
            if (this.state.error) {
              this.setState({ error: "" })
            }

            if (userData.manager.onboarding_completed) {
              this.props.history.push(`/${window.currentLang}/compliance-tool/registration`)
            } else {
              this.props.history.push(`/${window.currentLang}/compliance-tool/welcome`)
            }

            window.location.reload();

          } else if (loginError) {
            this.setState({
              error: loginError,
              errorVideo: true
            })
          }
          break;
        case 4:
          const password_repeat = this.state.reset_new_password_repeat
          data = {
            new_password: this.state.reset_new_password,
            reset_email: this.state.reset_new_password_email,
            code: this.state.resetToken
          }

          if (!data.new_password || !password_repeat || data.new_password === 0 || password_repeat === 0 ) {
            this.setState({ error: t("login.error.password_is_empty") })
            return
          }

          if (data.new_password !== password_repeat) {
            this.setState({ error: t("login.error.password_mismatch") })
            return
          }

          if (!checkPasswordPolicy(data.new_password)) {
            this.setState({ error: t("login.error.password_policy") })
            return
          }

          try {
            await resetManagerPassword(data)
            this.props.history.push(`/${window.currentLang}/compliance-tool/login?s=1`)
            window.location.reload()
          } catch (error) {
            //const errorMsg = (error && error.data && error.data.error) ? error.data.error : "Ihr Passwort konnte nicht gespeichert werden"
            this.props.history.push(`/${window.currentLang}/compliance-tool/login?s=1`)
            window.location.reload()
            //this.setState({ error: errorMsg })
          }

          break;
        case 5:
          data = {
            email: this.state.reset_email,
          }

          if (!data.email || data.email === 0) {
            this.setState({ error: t("login.error.mail") })
            return
          }

          try {
            await resetManagerPassword(data)
            this.setState({ step: 5 })
          } catch (error) {
            console.log(error)
          }
          break;
        default:
          return
      }
    } catch (error) {

      console.log("fatal", error)
      if (error && error.status === 401) {
        this.setState({
          error: error.data
        })
      } else if (error && error.data) {
        this.setState({
          error: error.data.error
        })
      } else if (error) {
        this.setState({
          error: " "
        })
      }
    }
  }

  handleChange = (e) => {
    //TODO: disable primary Button in Step2 if Checkbox is unchecked
    // console.log(e.target.name, e.target.value)
    if (e.target) {
      const name = e.target.name
      const value = e.target.value
      const state = this.state
      state[name] = value
      this.setState({ ...state });
    }
  }

  handleUserChange = (e) => {
    if (e.target) {
      const userData = this.state.userData
      userData[e.target.name] = e.target.value
      this.setState({ userData });
    }
  }

  handlePasswordChange = (e) => {
    if (e.target) {
      const state = this.state
      state[e.target.name] = e.target.value
      this.setState({ ...state })
    }
  }

  getCurrentStep() {
    switch (this.state.step) {
      case 1:
        return <Step1ComplTool submitFunc={(e, s) => this.handleSubmit(e, s)}
                      handlePasswordReset={() => this.handlePasswordReset()}
                      handleChange={(e) => this.handleChange(e)}
                      error={this.state.error} />;
      case 3:
        return <PasswordResetComplTool submitFunc={(e, s) => this.handleSubmit(e, s)}
                              handleChange={(e) => this.handleChange(e)}
                              error={this.state.error} />;
      case 4:
        return <NewPasswordComplTool submitFunc={(e, s) => this.handleSubmit(e, s)} error={this.state.error}
                            handleChange={(e) => this.handleChange(e)}
                            resetToken={this.props.resetToken} />;
      case 5:
        return <ResetSuccessComplTool submitFunc={(e, s) => this.handleSubmit(e, s)} error={this.state.error} />;
      default:
        return <Step1ComplTool submitFunc={(e, s) => this.handleSubmit(e, s)}
                      handlePasswordReset={() => this.handlePasswordReset()}
                      handleChange={(e) => this.handleChange(e)}
                      error={this.state.error} />;
    }
  }

  getVideo() {
    return (
      <video loop autoPlay key={`${this.state.errorVideo ? "error" : "default"}`}>
        <source
          src={`${this.state.errorVideo ? `${process.env.PUBLIC_URL}/videos/Login/bdm-login-city-error.mp4` : `${process.env.PUBLIC_URL}/videos/Login/bdm-login-city-default.mp4`}`}
          type="video/mp4" />Your browser does not support the video tag. Please upgrade your browser.
      </video>
    )
  }

  getLoginPic = () => {
    const hasLoginpic = this.state.hasLoginpic;
    const project_id = this.context.getUserData().project_id ? this.context.getUserData().project_id : this.context.getProjectData().id
    const onError = () => {
      this.setState({
        hasLoginpic: false
      });
    };
    return hasLoginpic ? <div className="login-pic-compl" style={{ backgroundImage: `url("${API_HOST}/asset/ct_loginpic?pid=${project_id}")` }} onError={onError}></div> : this.getVideo();
  }

  render() {
    if (this.state.loading) {
      return "Loading.."
    }

    const text = this.state.pageText

    return (
      <div className={`splitview step--${this.state.step}`}>
        <div className={"splitview--main"}>
          <div className={"login__container--left"}>
            <div className={"login__background-video login-compl-bg"}>
              {this.getLoginPic()}
            </div>
            <span className={"login__logo"}>
              <Logo image={`${API_HOST}/asset/logo/for-domain?domain=${window.location.host}`} />
            </span>
            <div className={"login__text-container"}>
              <h1>{text.p41_header_content_headline }</h1>
              <h2>{text.p41_header_content_subline }</h2>
            </div>
            <span className="login__action login__action--mobile">
              <Button type={"primary"} width={"fullwidth"} textColor={"white"} text={"Login"} />
            </span>
          </div>
        </div>
        <div className={"splitview--sidebar"}>
          {this.getCurrentStep()}
        </div>
      </div>
    );
  }
}

Login.contextType = AppContext;
export default withRouter(Login);
