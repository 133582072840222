import React from "react";
import PropTypes from "prop-types";

import Icon from "../icons/Icon";
import { generateQuantity } from "../../../helpers/utils";
import t from "../../../helpers/t9n";

class Selectfield extends React.Component {
  constructor(props) {
    super(props);
    this.realSelectRef = React.createRef();
    this.state = {
      isOpen: false,
      isActive: this.props.state === "active",
      selected: this.props.defaultValue ? this.props.defaultValue : "",
      selectId: this.props.id,
      loading: true,
    };
  }

  /**
   * Creates <option> array for the <select> element
   *
   * @param {array} props - available properties, using props.options here
   * @return {HTMLElement[]} - Returns an array of <option> elements
   */
  returnOptions(props) {
    const dropdownOptions = [];
    const options = { ...this.state.options };

    Object.keys(options).forEach((key, i) => {
      dropdownOptions.push(
        <option
          value={key}
          selected={this.state.selectedKey === key ? true : null}
          name={i}
          key={i}
        >
          {options[key]}
        </option>
      );
      return true;
    });

    return dropdownOptions;
  }

  /**
   * Creates <div> elements with the same content as the <select> options.
   * the returned array is used to fill a pseudo select dropdown
   *
   * @param {array} props - available properties, using props.options here
   * @return {HTMLElement[]} - Returns an array of <div> elements
   */

  createPseudoOptions(props) {
    const pseudoOptions = [];
    const options = { ...this.state.options };
    // we may localize/translate option values
    Object.keys(options).forEach((key, i) => {
      pseudoOptions.push(
        <div
          className={`${this.state.selected === key ? "same-as-selected" : ""}`}
          onClick={() => this.handleSelect(key, i)}
          key={i}
        >
          {options[key]}
        </div>
      );
      return true;
    });

    return pseudoOptions;
  }

  componentDidMount() {
    let selectOptions;

    if (this.props.state === "active") {
      //for outside clicks
      document.addEventListener("mousedown", this.handleClick, false);
      this.setState({
        isActive: true,
      });
    }

    if (this.props.isQuantity) {
      selectOptions = generateQuantity();
    } else {
      selectOptions = this.props.options;
    }

    this.setState({
      options: selectOptions,
      loading: false,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.state === "active" && this.props.state === "inactive") {
      document.removeEventListener("mousedown", this.handleClick, false);
      this.setState({
        isActive: false,
      });
    } else if (
      prevProps.state === "inactive" &&
      this.props.state === "active"
    ) {
      //for outside clicks
      document.addEventListener("mousedown", this.handleClick, false);
      this.setState({
        isActive: true,
      });
    }

    const newVal = this.props.defaultValue;
    if (prevProps.defaultValue !== newVal) {
      if (newVal) {
        this.setState({
          selectedKey: newVal,
          selected: newVal,
        });
      } else {
        this.setState({
          selected: "",
          selectedKey: -1,
        });
        this.realSelectRef.current.selectedIndex = -1;
      }
    }
  }

  componentWillUnmount() {
    if (this.state.isActive)
      document.removeEventListener("mousedown", this.handleClick, false);
  }

  toggleSelectField = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleSelect = (key, i) => {
    this.realSelectRef.current.selectedIndex = i;

    this.setState({
      selected: this.state.options[key],
      selectedKey: key,
      selectedIndex: i,
      isOpen: !this.state.isOpen,
    });

    //Pass value up, build fake target object for generic handleChange methods
    if (this.props.onSelect) {
      const obj = {
        target: {
          value: key,
          name: this.props.name,
        },
      };
      this.props.onSelect(obj);
    }
  };

  handleClick = (e) => {
    let contains = this.node.contains(e.target);
    if (this.props.state === "active") {
      if (contains && this.state.isOpen) {
        //nothing, handleSelect is triggered
      } else if (contains && !this.state.isOpen) {
        this.toggleSelectField();
      } else {
        this.handleClickOutside();
      }
    }
  };

  handleClickOutside() {
    if (this.state.isOpen) {
      this.setState({
        isOpen: false,
      });
    }
  }

  render() {
    if (this.state.loading) return "loading...";

    return (
      <div
        className={`selectfield__container ${
          this.props.label ? "" : "selectfield__noLabel"
        } selectfield__container--${this.props.state}`}
        ref={(node) => (this.node = node)}
      >
        <div
          className={`selectfield__wrapper ${
            this.state.isOpen ? "selectfield__arrow_active" : ""
          }`}
        >
          {this.props.label && (
            <label className={`selectfield__label`} htmlFor={`selectfield`}>
              {this.props.label}
              {this.props.isRequired ? "*" : ""}
            </label>
          )}

          <select
            className={`selectfield
                      selectfield--${this.props.state}
                      selectfield--has-icon `}
            required={this.props.isRequired ? true : null}
            name={this.props.name}
            ref={this.realSelectRef}
          >
            {this.returnOptions(this.props)}
          </select>

          <Icon icon="arrow" />

          <div className={`selectfield__selected`}>
            {this.state.selected === ""
              ? this.props.placeholder
              : this.state.selected}
          </div>

          <div
            ref={(pseudoOptions) => (this.pseudoOptions = pseudoOptions)}
            className={`selectfield__items ${
              !this.state.isOpen ? "selectfield__items--hidden" : ""
            }`}
          >
            {this.createPseudoOptions(this.props)}
          </div>
        </div>
      </div>
    );
  }
}

Selectfield.propTypes = {
  /** Wird vom Label über dem Dropdown ausgegeben */
  label: PropTypes.string,
  /** Optional: Translated/Localized Values */
  labels: PropTypes.array,
  /** Der "bitte wählen sie" text */
  state: PropTypes.oneOf(["active", "inactive"]),
  /** Die Icon-Namen können aus der Icon Komponente entnommen werden */
  icon: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.object,
  isQuantity: PropTypes.bool,
  isRequired: PropTypes.bool,
  onSelect: PropTypes.func,
};
Selectfield.defaultProps = {
  state: "active",
  placeholder: t("contact.page.choose"),
  isQuantity: false,
  isRequired: false,
};

export default Selectfield;
