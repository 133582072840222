import React, { Component } from "react";
import CartProduct from "../../components/element/cart-product/CartProduct";
import CreditBox from "../../components/element/credit-box/CreditBox";
import { AppContext } from "../../data/ContextProvider";
import UncontrolledLottie from "../../components/atom/lottie/UncontrolledLottie";
import emptyCart from "../../assets/lotties/empty-cart/anim--icon-empty-cart";
import Button from "../../components/atom/button/Button";
import { Link } from "react-router-dom";
import { addToCart, getCart, getUserStats, removeFromCart } from "../../data/api";
import { getToken } from "../../lib/lib";
import { getDeliveryDate, getProductImage, getProductPrice } from "../../helpers/utils";
import t from "../../helpers/t9n";


class Cart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cart: null,
      loading: true,
      isOneUnavailable: false
    }
  }

  checkForAvailability(available) {
    if (!available) {
      if (!this.state.isOneUnavailable) {
        this.setState({
          isOneUnavailable: true
        })
      }
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    const cart = await getCart()

    this.setState({
      cart: cart,
      loading: false
    })
  }

  handleRemoveFromCart = (id, quantity = 1) => {
    const response = removeFromCart(id, quantity)

    response.then(cart => {
      getUserStats().then((stats) => {
        this.setState({
          cart: cart,
        })
        this.context.setStatsOfUser(stats.stats)
      })
    })
  }

  handleQuantityChange = (oldQuantity, quantity, variantId) => {
    const option = variantId
    const ogQuantity = oldQuantity
    const newQuantity = quantity
    const difference = ogQuantity - newQuantity


    if (difference > 0) {
      this.handleRemoveFromCart(option, difference)
    } else if (difference < 0) {
      const positiveDiff = -difference
      addToCart(getToken(), option, positiveDiff).then(cart => {
        getUserStats().then((stats) => {
          this.setState({
            cart: cart,
          })
          this.context.setStatsOfUser(stats.stats)
        })
      })
    }
  }


  getProducts(cart) {
    const elements = [];

    cart.forEach((elem) => {
      this.checkForAvailability(elem.option.state === "available")
      elements.push(<CartProduct
                                 product={elem.product}
                                 title={elem.product.title}
                                 price={getProductPrice(elem.option, this.context.getUserData().project_code)}
                                 variant={elem.option.article_number}
                                 variantId={elem.option.id}
                                 id={elem.product.number}
                                 pid={elem.product.id}
                                 image={getProductImage(elem.images)}
                                 quantity={elem.item.quantity}
                                 isAvailable={elem.option.state === "available"}
                                 isWished={elem.product.wish}
                                 shippingDays={getDeliveryDate(elem.option.availability_state)}
                                 key={elem.option.id}
                                 handleQuantityChange={this.handleQuantityChange}
                                 removeFromCartCallback={this.handleRemoveFromCart} />)
    });
    // console.log(elements)
    return elements
  }

  render() {

    if (this.state.loading) {
      return "loading..."
    } else {

      const cart = this.state.cart.cart;
      const balance = this.context.getPointsOfUser()

      const user_shop_access_disabled = this.context.getUserData().order_suspension
      const shop_is_closed = (this.context.getUserData().shop_status === 0)

      return (
        <>
        <div className={"content cart"}>
          <div className={"cart__container"}>
            {cart.length > 0 ?
              <>
                <div className={`cart__left ${this.state.isOneUnavailable ? "cart__left--unavailable" : ""}`}>
                  {
                    this.getProducts(cart)
                  }
                </div>
                <div className={"cart__right"}>
                  { (shop_is_closed || user_shop_access_disabled)  ?
                      <div
                        className={`credit-box credit-box--insufficient`}>
                        <div className={"credit-box__content"}>
                          <span className={"credit-box__row"}>
                            <span className={"credit-box__balance-label"}>
                              {user_shop_access_disabled &&
                                <span>{t("cart.no.orders")}</span>
                              }
                              {shop_is_closed &&
                                <span>{t("cart.hint")}</span>
                              }
                            </span>
                          </span>
                        </div>
                        <div className={"credit-box__actions"}>
                          <Link to={`/${window.currentLang}/contact`}>
                            <Button text={t("global.button.go.to.contact")} textColor={"white"} type={"primary"} />
                          </Link>
                        </div>
                      </div>
                    :
                      <CreditBox sum={this.state.cart.total_price} balance={balance} />
                  }
                </div>
              </>
              :
              <div className={`cart__left cart__left--empty`}>
                <div className={"cart__empty"}>
                  <span className={"cart__lottie-circle"}>
                    <UncontrolledLottie animationData={emptyCart} />
                  </span>
                  <h2>{t("global.cart.empty")}</h2>
                  <span className={"cart__empty-actions"}>
                    <Link to={`/${window.currentLang}/shop`}>
                      <Button type={"primary"}
                              textColor={"white"}
                              text={t("globale.change.products")} />
                    </Link>
                    <Link to={`/${window.currentLang}/wishlist`}>
                      <Button type={"secondary"} textColor={"black"} text={t("global.wishlist.button")} />
                    </Link>
                  </span>
                </div>
              </div>
            }
          </div>
        </div>
      </>
      );
    }
  }
}

Cart.contextType = AppContext;

export default Cart;
