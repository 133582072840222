import React, { Component } from "react";
import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import SectionProductSearch from "../../components/module/section-product-search/SectionProductSearch";
import CardSlider from "../../components/module/card-slider/CardSlider";
import SectionHeroSlider from "../../components/module/section-hero-slider/SectionHeroSlider";
import t from "../../helpers/t9n";

class Home extends Component {
  render() {
    return (
      <>
        <div className={"content shop-home"}>

          <SectionHeroSlider />
          <SectionRecommendations />
          <SectionProductSearch />

          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
          </div>
        </div>
      </>
    );
  }
}

export default Home;
