import React from "react";
import Icon from "../../../components/atom/icons/Icon";

const GloriaRewardsShopHeader = props => (
  
  <div className="gloria-rewards__shop-header">
    <div className="app-header__icon-logo">
      <div className="app-header__icon-logo--inner">
        <Icon icon="app-gloria-rewards" />
      </div>
    </div>
    {props.title && (
      <h1>{props.title}</h1>
    )}
    {props.subtitle && <h2>{props.subtitle}</h2>}
  </div>

);

export default GloriaRewardsShopHeader;
