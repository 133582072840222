import React, { Component } from "react";
import { AppContext } from "../../../data/ContextProvider";

import Button from "../../atom/button/Button";

import SalesTriathlonBanner from "./SalesTriathlonBanner";
import SalesTriathlonGoals from "./SalesTriathlonGoals";

import {
  getAppPrefs,
  getAppTexts,
  getRankingLevels,
  getTransactionsForSalesTriathlon
} from "../../../data/api";

import { APP_SALESTRIATHLON } from "../../../data/constants";
import t from "../../../helpers/t9n";


export class SalesTriathlon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const appPrefs = await getAppPrefs(APP_SALESTRIATHLON);
    const texts    = await getAppTexts(APP_SALESTRIATHLON);

    const rankinglevels = await getRankingLevels(APP_SALESTRIATHLON)
    const transactions  = await getTransactionsForSalesTriathlon()

    this.setState({
      transactions: transactions.transactions,
      rankingLevels: rankinglevels.rankinglevels,
      appPrefs: appPrefs,
      pageText: texts,
      loading: false,
    });
  }

  render() {
    if (this.state.loading) {
      return t("global.loading");
    }

    return (
      <>
        <div className="banner-section" id={this.props.id}>
          <div className={this.props.isHeaderOpen ? "banner-section-inner banner-section-inner--open" : "banner-section-inner"}>
            <SalesTriathlonBanner
              text={this.state.pageText}
              headline={this.state.appPrefs.name}
              appID={APP_SALESTRIATHLON}
            />

            <SalesTriathlonGoals
              ranking_levels={this.state.rankingLevels}
              transactions={this.state.transactions}
              currencyMode={this.state.appPrefs.currency_mode}
              start={this.state.appPrefs.start}
              end={this.state.appPrefs.end}
            />
          </div>

          {this.props.isHeaderOpen ? (
            <Button
              type="secondary"
              text={this.props.buttonOpen}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_SALESTRIATHLON)}
            />
          ) : (
            <Button
              type="secondary"
              text={this.props.buttonClose}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
                onClick={() => this.props.toggleHeader(APP_SALESTRIATHLON)}
            />
          )}
        </div>
      </>
    );
  }
}

SalesTriathlon.contextType = AppContext;
export default SalesTriathlon;
