import React from 'react';
import { Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import './sass/styles.sass';
import ShopLayout from "./layout/ShopLayout";
import ShopPage from "./pages/ShopPage";
import LoginLayout from "./layout/LoginLayout";
import LoginPage from "./pages/Login/LoginPage";
import ImpersonatePage from "./pages/Login/ImpersonatePage";
import Icons from "./components/atom/icons/Icons";
import MainMenu from "./components/module/main-menu/MainMenu";
import ProductPage from "./pages/ProductPage";
import CartPage from "./pages/CartPage";
import CartLayout from "./layout/CartLayout";
import CheckoutPage from "./pages/CheckoutPage";
import CheckoutLayout from "./layout/CheckoutLayout";
import ShopHomePage from "./pages/ShopHomePage";

import WishlistPage from "./pages/WishlistPage";
import WishlistLayout from "./layout/WishlistLayout";
import OrdersPage from "./pages/OrdersPage";
import OrdersLayout from "./layout/OrdersLayout";
import OrdersDetailPage from "./pages/OrdersDetailPage";

// import Private from "./pages/Private/Private";
import ProductLayout from "./layout/ProductLayout";
import EmptyLayout from "./layout/EmptyLayout";
import Imprint from "./pages/Imprint/Imprint";
import AccountLayout from "./layout/AccountLayout";
import AccountPage from "./pages/AccountPage";
import PerformancedataPage from "./pages/PerformancedataPage";
import ProfilePage from "./pages/ProfilePage";
import NewsPage from "./pages/News/NewsPage";
import ContactPage from "./pages/ContactPage";
import ContactPagePublic from "./pages/ContactPagePublic";
import Privacy from "./pages/Privacy/Privacy";


import Tos from "./pages/Tos/Tos";
import Shipping from "./pages/Shipping/Shipping";



import ScrollToTop from "./components/basics/ScrollToTop/ScrollToTop";

// import SchaffeIchPage from "./pages/SchaffeIchPage";
import SwitchFragmentSupport from "./components/wrapper/SwitchFragmentSupport";
import RulesPage from "./pages/Rules/RulesPage";
import AllRulesPage from "./pages/Rules/AllRulesPage";


import NotFoundPage from "./pages/NotFoundPage";

import ETracker from "./components/module/eTracker";

import { AppContext } from "./data/ContextProvider";
import { getProjectByDomain } from "./data/api";

import { API_HOST } from "./data/config";
import { checkToken } from "./lib/lib";

// BASICSHOP // Achtung die Page für den Prämienshop heisst Private, wieso auch immer
import {
  APP_SALESPUSH,
  APP_SALESATTACK,
  APP_TEAMTROPHY,
  APP_SCHAFFEICH,
  APP_NEUKUNDENJAEGER,
  APP_HEROSPLACE,
  APP_CASINO,
  APP_GLORIAREWARDS,
  APP_SALESMARATHON
} from "./data/constants";

import ComplTool from './pages/ComplTool/ComplTool';
import ContractsNew from './pages/Contracts/pages/ContractsNew';
import ContractsArchived from './pages/Contracts/pages/ContractsArchived';
import FooterLayout from './layout/FooterLayout';

import Homepage from './pages/Homepage/Homepage';
import t from './helpers/t9n';


import PrivacyComplToolPUblic from './pages/ComplTool/Pages/PrivacyPublic';
import ConditionsComplToolPUblic from './pages/ComplTool/Pages/ConditionsPublic';

import PrivacyComplTool from './pages/ComplTool/Pages/Privacy';
import ConditionsComplTool from './pages/ComplTool/Pages/Conditions';

import ImprintComplTool from './pages/ComplTool/Pages/Imprint';
import ProfileComp from './pages/ComplTool/Pages/Profile';

import EmptyLayoutComplTool from './layout/EmptyLayoutComplTool';

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      app_id: null,
    }

    this.notAllowedForShop = []
    this.clubApps = []
    this.appsWithRankingPage = []
    this.appsWithWertungPage = []
    this.appsWithPerformancedataPage = [APP_HEROSPLACE, APP_SALESATTACK, APP_SALESPUSH, APP_NEUKUNDENJAEGER, APP_SCHAFFEICH, APP_TEAMTROPHY, APP_CASINO, APP_SALESMARATHON]
  }

  async componentDidMount() {

    try {

      const userData = await checkToken();

      if (userData) {
        const appId = parseInt(this.context.getAppIdOfUser(), 10)
        const hasShopAccess = !this.notAllowedForShop.includes(appId)

        const projectData = await getProjectByDomain(document.domain)

        if (projectData) {
          this.context.saveProjectData(projectData)
        }


        this.context.setHasShopAccess(hasShopAccess)
        this.context.saveUserData(userData);

        await this.context.getUserStats();

        if (hasShopAccess && userData.order_suspension) {
          this.context.toggleModalInfo(true, `${t("cart.no.orders")}`)
        }

        this.context.setIsClubApp(this.clubApps.includes(appId))

        this.setState({
          app_id: appId,
          loading: false,
        })

      } else {

        const projectData = await getProjectByDomain(document.domain)

        if (projectData) {
          this.context.saveProjectData(projectData)

          const appId = parseInt(projectData.project[0].app_id, 10)
          this.context.setIsClubApp(this.clubApps.includes(appId))
          this.context.setHasShopAccess(!this.notAllowedForShop.includes(appId))

          this.setState({
            app_id: appId,
            app_name: projectData.project[0].name,
            isDomainUnconfigured: false,
            loading: false,
          })

        } else {
          console.log("PLEASE REGISTER DOMAIN IN DB")
        }
      }

      this.changeTabTitle();


    } catch (e) {
      if (e && e.status === 401) {
        this.setState({
          app_id: null,
          loading: false
        })
      } else {
        //TODO: SHOW ERROR PAGE by state, see before render Login
        this.setState({
          app_id: null,
          isDomainUnconfigured: true,
          loading: false
        })
      }
    }
  }

  changeTabTitle() {
    const title = document.getElementById("pageTitle");
    const favicon = document.getElementById("favicon");

    title.textContent = this.context.project.page_title;
    favicon.href = `${API_HOST}/asset/favicon/for-domain?domain=${document.domain}`;
  }

  getRoutesForShop() {
    const isShopActive =  this.context.getProjectData().is_shop_active;

    if (isShopActive && !this.notAllowedForShop.includes(this.state.app_id)) {
      return (
        <>
          <Route exact path="/:lang?/product/:id?">
            <ProductLayout>
              <ProductPage />
            </ProductLayout>
          </Route>
          <Route exact path="/:lang?/shop/:category?/:subcategory?">
            <ShopLayout>
              <ShopPage />
            </ShopLayout>
          </Route>
          <Route exact path="/:lang?/cart">
            <CartLayout>
              <CartPage />
            </CartLayout>
          </Route>
          <Route exact path="/:lang?/wishlist">
            <WishlistLayout>
              <WishlistPage />
            </WishlistLayout>
          </Route>
          <Route exact path="/:lang?/orders/:orderid">
            <OrdersLayout>
              <OrdersDetailPage />
            </OrdersLayout>
          </Route>
          <Route exact path="/:lang?/orders">
            <OrdersLayout>
              <OrdersPage />
            </OrdersLayout>
          </Route>
          <Route exact path="/:lang?/checkout">
            <CheckoutLayout>
              <CheckoutPage />
            </CheckoutLayout>
          </Route>
          <Route exact path="/:lang?/home">
            <ShopLayout>
              <ShopHomePage />
            </ShopLayout>
          </Route>
          <Route exact path="/:lang?/shipping">
            <EmptyLayout>
              <Shipping />
            </EmptyLayout>
          </Route>
        </>
      )
    }
  }

  getLoginPage() {
    if (this.state.isDomainUnconfigured) {
      return (
        <EmptyLayout fullHeight={true}>
          <NotFoundPage isSubDomainError={true} />
        </EmptyLayout>
      )
    }

    return (
      <>
        <Route exact path={"/:lang?/new_password/:reset_token"}>
          <LoginLayout>
            <LoginPage resetPassword={true} />
          </LoginLayout>
        </Route>
        <Route exact path={"/:lang?/impersonate/:impersonate_token"}>
          <LoginLayout>
            <ImpersonatePage />
          </LoginLayout>
        </Route>
        <Route path={"/:lang?" || "/:lang/login/:step?"}>
          <LoginLayout>
            <LoginPage />
          </LoginLayout>
        </Route>
        <Route>
          <Redirect to={`${window.currentLang}/404`} />
        </Route>
      </>
    )
  }

  getBasicRoutes() {
    return (
      <>
        <Route exact path="/:lang?/imprint">
          <EmptyLayout>
            <Imprint />
          </EmptyLayout>
        </Route>

        <Route exact path="/:lang?/privacy">
          <EmptyLayout>
            <Privacy />
          </EmptyLayout>
        </Route>

        <Route exact path="/:lang?/compliance-tool/pub/conditions">
          <EmptyLayoutComplTool>
            <ConditionsComplToolPUblic />
          </EmptyLayoutComplTool>
        </Route>

        <Route exact path="/:lang?/compliance-tool/pub/privacy">
          <EmptyLayoutComplTool>
            <PrivacyComplToolPUblic />
          </EmptyLayoutComplTool>
        </Route>

        <Route exact path="/:lang?/compliance-tool/conditions">
          <EmptyLayoutComplTool>
            <ConditionsComplTool />
          </EmptyLayoutComplTool>
        </Route>

        <Route exact path="/:lang?/compliance-tool/privacy">
          <EmptyLayoutComplTool>
            <PrivacyComplTool />
          </EmptyLayoutComplTool>
        </Route>

        <Route exact path="/:lang?/compliance-tool/imprint">
          <EmptyLayoutComplTool>
            <ImprintComplTool />
          </EmptyLayoutComplTool>
        </Route>

        <Route exact path="/:lang?/compliance-tool/profile">
          <EmptyLayoutComplTool>
            <ProfileComp />
          </EmptyLayoutComplTool>
        </Route>

        <Route exact path="/:lang?/404">
          <EmptyLayout fullHeight={true}>
            <NotFoundPage isSubDomainError={false} />
          </EmptyLayout>
        </Route>

        <Route path="/:lang?/compliance-tool">
          <ComplTool />
        </Route>

        <Route exact path="/:lang?/contact">
          <EmptyLayout fullHeight={true}>
            <ContactPagePublic />
          </EmptyLayout>
        </Route>

        <Route exact path="/:lang?/tos">
          <EmptyLayout>
            <Tos />
          </EmptyLayout>
        </Route>
      </>
    )
  }

  getLoginFooterRoutes() {
    return (
      <>
        <Route exact path="/:lang?/imprint">
          <FooterLayout>
            <Imprint />
          </FooterLayout>
        </Route>

        <Route exact path="/:lang?/privacy">
          <FooterLayout>
            <Privacy />
          </FooterLayout>
        </Route>
      </>
    )
  }

  render() {

    if (this.state.loading) {
      return "Loading"
    }

    //FIXME darüber nachdenken
    // Bei langsamer DB-Verbindung sieht man beim Page-Wechsel kurz den Login
    if (!this.context.getUserData().hasAcceptedGdpr) {
      return (
        <Router>
          <div
            className={`mainContainer mainContainer--center ${this.context.isNavbarScrolling ? "container--scroll" : ""}`}>
            <ScrollToTop />
            <Icons />
            <SwitchFragmentSupport>
              {this.getBasicRoutes()}
              {this.getLoginPage()}
            </SwitchFragmentSupport>
          </div>
         </Router>
      )
    }

    //If logged in :
    return (
      <>
      <Router>
        {this.context.user.tracking_code &&
            <ETracker code={this.context.user.tracking_code} vars={this.context.user.tracking_vars} />
        }
        <div
          className={`mainContainer
            ${this.context.isClubApp ? "club" : ""}
            ${this.context.hasShopAccess ? "shop" : ""}
            ${this.context.isNavbarScrolling ? "container--scroll" : ""}
            ${parseInt(this.context.getAppIdOfUser()) === APP_GLORIAREWARDS ? "gloria-rewards-app" : ""}
            ${this.context.getProjectData().darkmode_enabled ? "homepage dark-mode" : "homepage light-mode"}
          `}>
          <ScrollToTop />
          <MainMenu noShopAccess={this.notAllowedForShop.includes(this.state.app_id)}
                    isClub={this.clubApps.includes(this.state.app_id)}
                    hasRanking={this.appsWithRankingPage.includes(this.state.app_id)}
                    hasWertung={this.appsWithWertungPage.includes(this.state.app_id)}
                     // noRules={this.state.app_id === APP_SHOP}
                    hasPerformancedataPage={this.appsWithPerformancedataPage.includes(this.state.app_id)}
                    hasContractsPage={this.context.user.contracts_enabled}
          />
          <Icons />
          <SwitchFragmentSupport>

            {this.getLoginFooterRoutes()}

            {this.getRoutesForShop()}

            <Route exact path="/:lang?/rules">
              <FooterLayout>
                <AllRulesPage />
              </FooterLayout>
            </Route>

            <Route exact path="/:lang?/rules/:appId?">
              <FooterLayout>
                <RulesPage />
              </FooterLayout>
            </Route>

            <Route exact path="/:lang?/">
                <AccountLayout>
                  <Homepage />
                </AccountLayout>
            </Route>

            <Route exact path="/:lang?/performancedata">
              <AccountLayout>
                <PerformancedataPage />
              </AccountLayout>
            </Route>

            <Route exact path="/:lang?/contracts/new">
              <AccountLayout>
                <ContractsNew />
              </AccountLayout>
            </Route>

            <Route exact path="/:lang?/contracts/archived">
              <AccountLayout>
                <ContractsArchived />
              </AccountLayout>
            </Route>

            <Route exact path="/:lang?/contact">
            <AccountLayout>
              <ContactPage />
            </AccountLayout>
          </Route>
          <Route exact path="/:lang?/tos">
            <AccountLayout>
              <Tos />
            </AccountLayout>
          </Route>
          <Route exact path="/:lang?/profile">
            <AccountLayout>
              <ProfilePage />
            </AccountLayout>
          </Route>
          <Route exact path="/:lang?/news">
            <AccountLayout>
              <NewsPage />
            </AccountLayout>
          </Route>
          <Route exact path="/:lang?/account">
            <AccountLayout>
              <AccountPage />
            </AccountLayout>
          </Route>

            {this.getLoginPage()}
          </SwitchFragmentSupport>
        </div>
      </Router>
      </>
    );
  }
}

App.contextType = AppContext

export default App;
