import React, { Component } from 'react'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'

class UncontrolledLottie extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      settings: {
        loop: true,
        autoplay: true,
        animationData: props.animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }
    };
  }
  
  render() {
    const { settings } = this.state;
    
    return (
      <span className={"lottie__uncontrolled"}>
        <Lottie options={settings} isClickToPauseDisabled={true} />
      </span>
    )
  }
}

UncontrolledLottie.propTypes = {
  animationData: PropTypes.object
};

export default UncontrolledLottie