import React, { Component } from 'react'

export default class Stepper extends Component {
  render() {
    return (
      <div className={`stepper stepper--${this.props.activeStep}`}>
        <div className="step"><div className="step__inner"><span>1</span></div></div>
        <div className="step"><div className="step__inner"><span>2</span></div></div>
        <div className="step"><div className="step__inner"><span>3</span></div></div>
        <div className="step"><div className="step__inner"><span>4</span></div></div>
        <div className="step"><div className="step__inner"><span>5</span></div></div>
        <div className="step"><div className="step__inner"><span>6</span></div></div>
      </div>
    )
  }
}
