import React from "react";
import PropTypes from "prop-types";

class Textarea extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value ? this.props.value : "" };
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  
  render() {
    return (
      <div
        className={`textarea ${this.props.validationFunction && this.props.validationFunction(this.state.value) ? "textarea--error" : ""}
        ${this.props.isDisabled ? "textarea--disabled" : ""}`}>
        {this.props.label.length > 0 && (
          <label className="textarea__label">{this.props.label}{this.props.isRequired ? "*" : ""}</label>
        )}
        
        
        {this.props.isRequired ?
          <textarea
            className="textarea__input"
            name={this.props.name ? this.props.name : this.props.placeholder}
            required
            placeholder={this.props.placeholder}
            defaultValue={this.props.value ? this.props.value : null}
            onChange={this.props.handleChange ? (e) => this.props.handleChange(e) : this.handleChange}
            rows="4"
            cols="50">
          </textarea>
          :
          <textarea
            className="textarea__input"
            name={this.props.name ? this.props.name : this.props.placeholder}
            placeholder={this.props.placeholder}
            defaultValue={this.props.value ? this.props.value : null}
            onChange={this.props.handleChange ? (e) => this.props.handleChange(e) : this.handleChange}
            rows={this.props.rows}
            cols={this.props.cols}>
          </textarea>
        }
        
        {this.props.errorMsg && this.props.errorMsg.length > 0 && (
          <p className="textarea__errormsg">
            {this.props.errorMsg}
          </p>
        )}
      </div>
    );
  }
}

Textarea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  validationFunction: PropTypes.func,
  handleChange: PropTypes.func,
  errorMsg: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Textarea.defaultProps = {
  label: "",
  placeholder: "",
  validationFunction: null,
  errorMsg: null,
  isRequired: false,
  isDisabled: false,
};

export default Textarea;
